import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  getLabelTipo,
  getValorFormatado,
} from '../../../Components/Dialogos/DialogoFornecedor/utils';
import { getListAllAPI } from '../../../services';
import {
  formatTelefone,
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  SECONDARY_COLOR,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  POR_PESO_FIXO,
  TIPO_IMPRESSAO_UN,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
} from '../../../utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DEFAULT_ID = -1;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const totaisInicial = {
  custo_operacional: 0,
  custo_cartao: 0,
  total_venda: 0,
  icms_compra: 0,
  icms_venda: 0,
  pis_compra: 0,
  pis_venda: 0,
  cofins_compra: 0,
  cofins_venda: 0,
  lucro_bruto: 0,
  lucro_liquido: 0,
  icms_antecipacao: 0,
  icms_fronteira2: 0,
  repasse_ipi2: 0,
  repasse_st2: 0,
  repasse_seguro2: 0,
  repasse_frete2: 0,
  repasse_outras_despesas2: 0,
  irpj: 0,
  csll: 0,
  valor_total: 0,
};

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [data, setData] = useState('-');
  const [numeroCompra, setNumeroCompra] = useState(0);
  const [fornecedor, setFornecedor] = useState({
    nome: '-',
    razaoSocial: '-',
    nomeFantasia: '-',
  });
  const [dataFaturamento, setDataFaturamento] = useState('-');
  const [valorTotal, setValorTotal] = useState(0);
  const [icmsFronteira, setIcmsFronteira] = useState('');
  const [loja, setLoja] = useState('');
  const [numeroNotaFiscal, setNumeroNotaFiscal] = useState('');
  const [itens, setItens] = useState([]);
  const [parcelas, setParcelas] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = React.useState(0);
  const [totais, setTotais] = useState(totaisInicial);
  const [tipo, setTipo] = useState('');
  const [lotesSimplificados, setLotesSimplificados] = useState([]);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function formatData(data) {
    return data ? moment(new Date(data)).format('DD/MM/YYYY') : '-';
  }

  function formatNumeroCompra(data) {
    return data || '-';
  }

  function formatFornecedor(data) {
    let nome = '-';
    let razaoSocial = '-';
    let nomeFantasia = '-';

    if (data) {
      nome = data.nome ? data.nome : '-';
      razaoSocial = data.razao_social ? data.razao_social : '-';
      nomeFantasia = data.nome_fantasia ? data.nome_fantasia : '-';
    }

    return {
      nome,
      razaoSocial,
      nomeFantasia,
    };
  }

  function formatDataFaturamento(data) {
    return data ? moment(new Date(data)).format('DD/MM/YYYY') : '-';
  }

  function formatValorTotal(data) {
    return formatMoeda(data);
  }

  function formatIcmsFronteira(data) {
    return data || '-';
  }

  function formatLoja(data) {
    return data || '-';
  }

  function formatNumeroNotaFiscal(data) {
    return data || '-';
  }

  function getUnidade(produto) {
    if (produto.unidade.tipo === POR_UNIDADE) return 'Un';
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 'Kg';
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) return 'Un';
      return 'Kg';
    }
    return '';
  }

  function getFator(produto) {
    if (produto.unidade.tipo === POR_UNIDADE) return 1;
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 1;
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.tipo_impressao === TIPO_IMPRESSAO_UN)
        return produto.peso_medio;
      return 1;
    }
    return '';
  }

  function formatItens(data) {
    const vetor = [];
    for (let i = 0; i < data.length; i++) {
      const resource = data[i];
      const tipo = resource.produto.unidade.tipo;

      const qtde = tipo === POR_UNIDADE ? resource.unidades : resource.peso;

      const total = qtde * resource.preco_compra2;

      const fator = getFator(resource.produto);
      const label_unidade = getUnidade(resource.produto);

      vetor.push({
        id: resource.id,
        produto: resource.produto.nome,
        unidades:
          tipo === POR_PESO_VARIAVEL_SEM_UNIDADE ? '-' : resource.unidades,
        peso: tipo === POR_UNIDADE ? '-' : formatPeso(resource.peso),
        precoCompra: `${formatMoeda(
          resource.preco_compra2 * fator,
        )} / ${label_unidade}`,
        total: formatMoeda(total),
        cg: `${formatMoeda(resource.cg * fator)} / ${label_unidade}`,
      });
    }
    return vetor;
  }

  function getDiasOriginalProvavel(despesa) {
    let value = '-';
    if (despesa.data_negociada) {
      const dataOriginal = new Date(
        moment(new Date(despesa.data_pagamento_original)).endOf('day').format(),
      );

      const dataProvavel = new Date(
        moment(new Date(despesa.data_negociada)).endOf('day').format(),
      );

      const delta = dataProvavel.getTime() - dataOriginal.getTime();

      value = parseInt(delta / (24 * 60 * 60 * 1000), 10);
    }
    return value;
  }

  function getDiasRealOriginal(despesa) {
    const dataReal = new Date(
      moment(new Date(despesa.data_pagamento)).endOf('day').format(),
    );

    const dataOriginal = new Date(
      moment(new Date(despesa.data_pagamento_original)).endOf('day').format(),
    );

    const delta = dataReal.getTime() - dataOriginal.getTime();

    const value = parseInt(delta / (24 * 60 * 60 * 1000), 10);

    return value;
  }

  function makeObjFatura(diaPagamento, mes, ano) {
    const dataPagamentoFatura = new Date();
    dataPagamentoFatura.setDate(diaPagamento);
    dataPagamentoFatura.setMonth(mes);
    dataPagamentoFatura.setFullYear(ano);
    const descricaoFatura = `${format(
      dataPagamentoFatura,
      "dd 'de' MMM 'de' yyyy",
      {
        locale: ptBR,
      },
    )}`;
    return descricaoFatura;
  }

  function formatParcelas(data) {
    const vetor = [];
    for (let i = 0; i < data.length; i++) {
      const parcela = data[i];
      const despesa = data[i].despesa;

      vetor.push({
        id: parcela.id,
        dataOriginal: moment(new Date(despesa.data_pagamento_original)).format(
          'DD/MM/YYYY',
        ),
        dataReal: moment(new Date(despesa.data_pagamento)).format('DD/MM/YYYY'),
        dataProvavel: despesa.data_negociada
          ? moment(new Date(despesa.data_negociada)).format('DD/MM/YYYY')
          : '-',
        conta: despesa.conta.nome,
        valor: formatMoeda(despesa.valor),
        status: despesa.status,
        valorOriginal: formatMoeda(despesa.valor_original),
        codigoBoleto: despesa.codigo_boleto ? despesa.codigo_boleto : '-',
        diferencaProvavelOriginal: getDiasOriginalProvavel(despesa),
        diferencaRealOriginal: getDiasRealOriginal(despesa),
        cartao: parcela.is_cartao ? despesa.fatura.cartao.nome : '-',
        fatura: despesa.fatura
          ? makeObjFatura(
              despesa.fatura.cartao.dia_pagamento,
              despesa.fatura.mes,
              despesa.fatura.ano,
            )
          : '-',
        categoriaProblema: despesa.problema ? despesa.problema.nome : '-',
        juros: formatMoeda(despesa.juros),
        multa: formatMoeda(despesa.multa),
        mora: formatMoeda(despesa.mora),
        encargos: formatMoeda(despesa.total_juros),
        desconto: formatMoeda(despesa.desconto),
      });
    }
    return vetor;
  }

  function formatItensSimplificados(data) {
    const vetor = [];
    for (let i = 0; i < data.length; i++) {
      const resource = data[i];

      const total = resource.peso * resource.preco_imposto;

      vetor.push({
        id: resource.id,
        produto: resource.nome,
        unidades: '-',
        peso: formatPeso(resource.peso),
        precoCompra: `${formatMoeda(resource.preco_imposto)}`,
        total: formatMoeda(total),
        cg: '-',
      });
    }
    return vetor;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI('compras', ['id', 'asc'], { id: [id] }, [
        'fornecedor.cidade.estado',
        'fornecedor.prazosFornecedores',
        'lotes.produto.unidade',
        'parcelas.despesa.conta',
        'parcelas.despesa.fatura.cartao',
        'parcelas.despesa.problema',
        'parcelas.despesa.agendador',
        'parcelas.despesa.categoria',
        'lotesSimplificados',
      ]);
      if (data.data.length > 0) {
        setId(data.data[0].id);
        //
        setData(formatData(data.data[0].data));
        setNumeroCompra(formatNumeroCompra(data.data[0].numero));
        setFornecedor(formatFornecedor(data.data[0].fornecedor));
        setDataFaturamento(
          formatDataFaturamento(data.data[0].data_faturamento),
        );
        setValorTotal(formatValorTotal(data.data[0].valor));
        setIcmsFronteira(formatIcmsFronteira(data.data[0].icms_fronteira));
        setLoja(formatLoja(data.data[0].loja));
        setNumeroNotaFiscal(
          formatNumeroNotaFiscal(data.data[0].numero_nota_fiscal),
        );
        setItens(
          data.data[0].tipo === TIPO_COMPRA_MERCADORIAS_REVENDA ||
            data.data[0].tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
            ? formatItens(data.data[0].lotes)
            : formatItensSimplificados(data.data[0].lotesSimplificados),
        );
        setParcelas(formatParcelas(data.data[0].parcelas));
        //
        setValue(0);
        setTotais({
          custo_operacional: data.data[0].custo_operacional,
          custo_cartao: data.data[0].custo_cartao,
          total_venda: data.data[0].total_venda,
          icms_compra: data.data[0].icms_compra,
          icms_venda: data.data[0].icms_venda,
          pis_compra: data.data[0].pis_compra,
          pis_venda: data.data[0].pis_venda,
          cofins_compra: data.data[0].cofins_compra,
          cofins_venda: data.data[0].cofins_venda,
          lucro_bruto: data.data[0].lucro_bruto,
          lucro_liquido: data.data[0].lucro_liquido,
          icms_antecipacao: data.data[0].icms_antecipacao,
          icms_fronteira2: data.data[0].icms_fronteira2,
          repasse_ipi2: data.data[0].repasse_ipi2,
          repasse_st2: data.data[0].repasse_st2,
          repasse_seguro2: data.data[0].repasse_seguro2,
          repasse_frete2: data.data[0].repasse_frete2,
          repasse_outras_despesas2: data.data[0].repasse_outras_despesas2,
          irpj: data.data[0].irpj,
          csll: data.data[0].csll,
          valor_total: data.data[0].valor,
        });
        setTipo(data.data[0].tipo);
        setCarregando(false);
      } else {
        throw 'Esta compra não existe!';
      }
    } catch (erros) {
      setTotais({});
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      getDataResource(id);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const totalProdutos =
    totais.valor_total -
    (totais.repasse_ipi2 +
      totais.repasse_frete2 +
      totais.repasse_st2 +
      totais.repasse_seguro2 +
      totais.repasse_outras_despesas2);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">{`Compra nº ${numeroCompra}`}</DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Fornecedor" {...a11yProps(1)} />
                  <Tab label="Itens" {...a11yProps(2)} />
                  <Tab label="Parcelas" {...a11yProps(3)} />
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Data:
                          </span>
                          {data}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Número Compra:
                          </span>
                          {numeroCompra}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Data Faturamento:
                          </span>
                          {dataFaturamento}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Valor:
                          </span>
                          {valorTotal}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            ICMS Fronteira:
                          </span>
                          {icmsFronteira}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Loja:
                          </span>
                          {loja}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Número Nota Fiscal:
                          </span>
                          {numeroNotaFiscal}
                        </Box>
                      </Box>

                      <Box>
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            margin: '5px 0 15px',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          Geral
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left">
                                  Imposto
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  SubTotal
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow key="1">
                                <StyledTableCell align="left">
                                  Valor da Nota
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(valorTotal)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="2">
                                <StyledTableCell align="left">
                                  Total dos Produtos
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totalProdutos)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="3">
                                <StyledTableCell align="left">
                                  Venda
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.total_venda)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="4">
                                <StyledTableCell align="left">
                                  Lucro Bruto
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.lucro_bruto)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="7">
                                <StyledTableCell align="left">
                                  Lucro Líquido
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.lucro_liquido)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="5">
                                <StyledTableCell align="left">
                                  Custo Operacional
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.custo_operacional)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="6">
                                <StyledTableCell align="left">
                                  Custo com Cartão
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.custo_cartao)}
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            margin: '25px 0 15px',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          Repasses
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left">
                                  Imposto
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  SubTotal
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow key="1">
                                <StyledTableCell align="left">
                                  IPI
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.repasse_ipi2)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="2">
                                <StyledTableCell align="left">
                                  Frete
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.repasse_frete2)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="3">
                                <StyledTableCell align="left">
                                  ST
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.repasse_st2)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="4">
                                <StyledTableCell align="left">
                                  Seguro
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.repasse_seguro2)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="5">
                                <StyledTableCell align="left">
                                  Outras Despesas
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.repasse_outras_despesas2)}
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            margin: '25px 0 15px',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          Impostos
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left">
                                  Imposto
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  SubTotal
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow key="1">
                                <StyledTableCell align="left">
                                  PIS Compra
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.pis_compra)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="2">
                                <StyledTableCell align="left">
                                  COFINS Compra
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.cofins_compra)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="3">
                                <StyledTableCell align="left">
                                  ICMS Compra
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.icms_compra)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="4">
                                <StyledTableCell align="left">
                                  ICMS Antecipação
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.icms_antecipacao)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="5">
                                <StyledTableCell align="left">
                                  CSLL
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.csll)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="6">
                                <StyledTableCell align="left">
                                  PIS Venda
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.pis_venda)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="7">
                                <StyledTableCell align="left">
                                  COFINS Venda
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.cofins_venda)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="8">
                                <StyledTableCell align="left">
                                  ICMS Venda
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.icms_venda)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="9">
                                <StyledTableCell align="left">
                                  ICMS de Fronteira
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.icms_fronteira2)}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="10">
                                <StyledTableCell align="left">
                                  IRPJ
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {formatMoeda(totais.irpj)}
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Responsável:
                          </span>
                          {fornecedor.nome}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Razão Social:
                          </span>
                          {fornecedor.razaoSocial}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Nome Fantasia:
                          </span>
                          {fornecedor.nomeFantasia}
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Produto</StyledTableCell>
                              <StyledTableCell>Qtde</StyledTableCell>
                              <StyledTableCell>Peso (Kg)</StyledTableCell>
                              <StyledTableCell>Preço de Compra</StyledTableCell>
                              <StyledTableCell>CG</StyledTableCell>
                              <StyledTableCell>Total</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {itens.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.produto}</StyledTableCell>
                                <StyledTableCell>
                                  {row.unidades}
                                </StyledTableCell>
                                <StyledTableCell>{row.peso}</StyledTableCell>
                                <StyledTableCell>
                                  {row.precoCompra}
                                </StyledTableCell>
                                <StyledTableCell>{row.cg}</StyledTableCell>
                                <StyledTableCell>{row.total}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Data Original</StyledTableCell>
                              <StyledTableCell>Data Real</StyledTableCell>
                              <StyledTableCell>Conta</StyledTableCell>
                              <StyledTableCell>Cartão</StyledTableCell>
                              <StyledTableCell>Fatura</StyledTableCell>
                              <StyledTableCell>Valor Original</StyledTableCell>
                              <StyledTableCell>Valor</StyledTableCell>
                              <StyledTableCell>Status</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {parcelas.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {row.dataOriginal}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.dataReal}
                                </StyledTableCell>
                                <StyledTableCell>{row.conta}</StyledTableCell>
                                <StyledTableCell>{row.cartao}</StyledTableCell>
                                <StyledTableCell>{row.fatura}</StyledTableCell>
                                <StyledTableCell>
                                  {row.valorOriginal}
                                </StyledTableCell>
                                <StyledTableCell>{row.valor}</StyledTableCell>
                                <StyledTableCell>
                                  {row.status ? (
                                    <Avatar className={classes.confirmado}>
                                      <CheckIcon style={{ fontSize: 15 }} />
                                    </Avatar>
                                  ) : (
                                    <Avatar className={classes.pendente}>
                                      <PriorityHighIcon
                                        style={{ fontSize: 15 }}
                                      />
                                    </Avatar>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Fechar
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
