import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  DateField,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  FunctionField,
  RecordContext,
  useRefresh,
  useListContext,
  ListContextProvider,
} from 'react-admin';

import { Divider, Tabs, Tab, Card } from '@material-ui/core';

import Datagrid from '../../Components/Datagrid';
import DialogoCarregarNota from '../../layout/Pages/TesteLeituraXml/DialogoCarregarNota';
import Actions from '../Compras/ActionsList';
import Dialogo from '../Compras/ContainerDialogoCompra';
import DialogoDataFaturameto from '../Compras/DialogoDataFaturameto';
import DialogoDeletar from '../Compras/DialogoDeletar';
import DialogoShow from '../Compras/DialogoShow';
import DialogoPdf from '../NfeCompras/DialogoPdf';
import ActionsField from './ActionsField';
import Filter from './Filter';

const tabs = [
  { id: 'confirmado', name: 'Disponível' },
  { id: 'cancelado', name: 'Usado' },
];

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDeleteResource = useRef(null);
  const refDialogoIcms = useRef(null);
  const refDialogoDataFaturamento = useRef(null);
  const refDialogoShowCompra = useRef(null);
  const refDialogoCarregarNota = useRef(null);
  const refDialogoPdf = useRef(null);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);

  const refresh = useRefresh();

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  function deleteHundle({ id }) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(id);
  }

  function handleImprimir(record) {}

  function handleShow(record) {
    if (refDialogoShowCompra.current) {
      refDialogoShowCompra.current.handleOpen(record.id);
    }
  }

  function handleCarregarXml() {
    if (refDialogoCarregarNota.current) {
      refDialogoCarregarNota.current.handleOpen();
    }
  }

  function handleClose() {
    refresh();
  }

  function handlePDF(dados) {
    if (dados.chave_acesso_nota_fiscal) {
      if (refDialogoPdf.current) {
        refDialogoPdf.current.handleOpen(dados.chave_acesso_nota_fiscal);
      }
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.valor_bonificacao_usado_totalmente) {
      switch (filterValues.valor_bonificacao_usado_totalmente) {
        case false:
          setConfirmados(ids);
          break;
        case true:
          setCancelados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.valor_bonificacao_usado_totalmente]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            valor_bonificacao_usado_totalmente: value === 'cancelado',
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={
          <Actions
            handleCriar={handleCriar}
            handleCarregarXml={handleCarregarXml}
            {...props}
          />
        }
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={
            filterValues.valor_bonificacao_usado_totalmente
              ? tabs[1].id
              : tabs[0].id
          }
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <div>
          {filterValues.valor_bonificacao_usado_totalmente === false && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid {...props}>
                <TextField source="id" label="Nº compra" />
                <ReferenceField
                  label="Fornecedor"
                  source="fornecedor_id"
                  reference="fornecedores"
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />

                <NumberField
                  source="valor_presumido"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total Presumido"
                />

                <DateField source="data" label="Data da Compra" />
                <TextField
                  source="numero_nota_fiscal"
                  label="Número nota fiscal"
                />

                <FunctionField
                  label="Faturamento"
                  render={(record) => {
                    if (record.data_faturamento) {
                      const data = new Date(record.data_faturamento);
                      return `${
                        data.getDate() < 10
                          ? `0${data.getDate()}`
                          : data.getDate()
                      }/${
                        data.getMonth() + 1 < 10
                          ? `0${data.getMonth() + 1}`
                          : data.getMonth() + 1
                      }/${data.getFullYear()}`;
                    }
                    return '-';
                  }}
                />

                <ActionsField
                  source="lalala"
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDelete={deleteHundle}
                  handleImprimir={handleImprimir}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handlePdf={handlePDF}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.valor_bonificacao_usado_totalmente === true && (
            <ListContextProvider value={{ ...listContext, ids: cancelados }}>
              <Datagrid {...props}>
                <TextField source="id" label="Nº compra" />
                <ReferenceField
                  label="Fornecedor"
                  source="fornecedor_id"
                  reference="fornecedores"
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />

                <NumberField
                  source="valor_presumido"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total Presumido"
                />

                <DateField source="data" label="Data da Compra" />
                <TextField
                  source="numero_nota_fiscal"
                  label="Número nota fiscal"
                />

                <FunctionField
                  label="Faturamento"
                  render={(record) => {
                    if (record.data_faturamento) {
                      const data = new Date(record.data_faturamento);
                      return `${
                        data.getDate() < 10
                          ? `0${data.getDate()}`
                          : data.getDate()
                      }/${
                        data.getMonth() + 1 < 10
                          ? `0${data.getMonth() + 1}`
                          : data.getMonth() + 1
                      }/${data.getFullYear()}`;
                    }
                    return '-';
                  }}
                />

                <ActionsField
                  source="lalala"
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDelete={deleteHundle}
                  handleImprimir={handleImprimir}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handlePdf={handlePDF}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} />
      <DialogoDeletar
        ref={refDialogoDeleteResource}
        handleClose={handleClose}
      />
      <DialogoDataFaturameto
        ref={refDialogoDataFaturamento}
        handleClose={handleClose}
      />
      <DialogoShow ref={refDialogoShowCompra} handleClose={() => {}} />
      <DialogoCarregarNota
        ref={refDialogoCarregarNota}
        handleClose={() => {}}
      />
      <DialogoPdf ref={refDialogoPdf} handleClose={() => {}} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Bonificações"
      filterDefaultValues={{
        /*  periodo: {
          start: new Date(),
          end: new Date(),
        }, */
        valor_bonificacao_usado_totalmente: false,
      }}
    />
  </>
);

export default List2;
