import React, {
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Switch, ThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';
import xml2js from 'xml2js';

import imagem from '../../../assets/background.jpg';
import { getListAPI, getListAllAPI, api } from '../../../services';
import {
  FORNECEDOR_DEFAULT,
  formatLotes,
  formatParcelasCompra,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  formatLotesSimplificados,
  formatMoeda,
  formatCPF,
  formatCEP,
  formatDateDDMMYYYY,
  formatDateDDMMYYYYHHmm,
  formatCNPJ,
  formatIE,
} from '../../../utils';
import theme from '../../DialogoEditarVenda/themes/default';
import {
  reducerCompra,
  CompraContext,
  InicioFrenteCaixaCompra,
  FinalizarFrenteCaixaCompra,
  PreInicioFrenteCaixaCompra,
  TipoCompra,
  ImportacaoXML,
  ImportacaoXMLSemArquivo,
} from './Pages';

////////////////////////////////

const codigosTipoOperacao = {
  0: 'Entrada',
  1: 'Saída',
};

const codigosIdentificacaoDoAmbiente = {
  1: 'Produção',
  2: 'Homologação',
};

const codigosFinalidadeDaEmissaoDaNfe = {
  1: 'NF-e normal;',
  2: 'NF-e complementar;',
  3: 'NF-e de ajuste;',
  4: 'Devolução de mercadoria.',
};

const codigosIndicadorFormaDePagamento = {
  1: 'Pagamento à Vista',
  2: 'Pagamento à Prazo',
};

const codigosMeiosDePagamento = {
  '01': 'Dinheiro',
  '02': 'Cheque',
  '03': 'Cartão de Crédito',
  '04': 'Cartão de Débito',
  '05': 'Crédito Loja',
  10: 'Vale Alimentação',
  11: 'Vale Refeição',
  12: 'Vale Presente',
  13: 'Vale Combustível',
  15: 'Boleto Bancário',
  16: 'Depósito Bancário',
  17: 'Pagamento Instantâneo (PIX)',
  18: 'Transferência bancária, Carteira Digital',
  19: 'Programa de fidelidade, Cashback, Crédito Virtual',
  90: 'Sem pagamento',
  99: 'Outros',
};

function preencherComZeros(value, tam) {
  let valueFormatado = `${value}`;

  for (let i = 0; i < tam - value.length; i++) {
    valueFormatado = `0${valueFormatado}`;
  }

  return valueFormatado;
}

////////////////////////////////

const TELA_PRE_INICIO_FRENTE_CAIXA = -1;
const TELA_INICIO_FRENTE_CAIXA = 0;
const TELA_FINALIZAR_FRENTE_CAIXA = 1;

const TELA_TIPO_COMPRA = 2;

const TELA_IMPORTACAO_XML = 3;

const TELA_IMPORTACAO_XML_SEM_ARQUIVO = 4;

function SwitchElements({ elementos, value }) {
  const selecionado = elementos[value];
  return <selecionado.elemento {...selecionado.props} />;
}

const DialogoCreateCompra = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const [tela, setTela] = useState(TELA_TIPO_COMPRA);
  const { enqueueSnackbar } = useSnackbar();
  const [chaveDeAcesso, setChaveDeAcesso] = useState('');

  const [compra, dispatch] = useReducer(reducerCompra, {
    fornecedor: null,
    lotes: [],
    parcelas: [],
    idEdit: -1,
    numeroNotaFiscal: '',
    data_faturamento: '',
    data_criacao: new Date(),
    loja: {
      nome: 'CARUARU FRIOS',
      value: 'CF',
    },
    repasse_ipi: 0,
    repasse_frete: 0,
    repasse_st: 0,
    repasse_seguros: 0,
    repasse_outras_despesas: 0,
    carregando: true,
    config: null,
    numeroCompra: '-',
    isProdutosSomenteFornecedor: true,
    isIncluiInativos: false,
    serieNotaFiscal: '',
    chaveAcessoNotaFiscal: '',
    isImportacaoXml: false,
    tipo: TIPO_COMPRA_MERCADORIAS_REVENDA,
    uf: '',
    dadosXml: null,
    subTotalPresumido: 0,
    total_desconto_nfe: 0,
    total_desconto_descarrego: 0,
    total_desconto_bonificacao: 0,
    bonificacao_usada_id: null,
  });

  function resetar() {
    dispatch({ type: 'RESETAR_TUDO' });
  }

  async function getConfig() {
    const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, [
      'cidade.estado',
      'contaPadraoParcelaCompra',
    ]);
    if (config.data.length <= 0) {
      throw 'Configurações inacessíveis!';
    }
    return config.data[0];
  }

  async function reiniciar() {
    /*const dataFornecedores = await getListAllAPI(
      'fornecedores',
      ['id', 'asc'],
      { nome: FORNECEDOR_DEFAULT },
      ['cidade.estado'],
    );*/

    const config = await getConfig();

    dispatch({
      type: 'PREENCHER_TUDO',
      idEdit: -1,
      lotes: [],
      parcelas: [],
      //fornecedor: dataFornecedores.data[0],
      fornecedor: null,
      numeroNotaFiscal: '',
      data_faturamento: '',
      data_criacao: new Date(),
      loja: {
        nome: 'CARUARU FRIOS',
        value: 'CF',
      },
      repasse_ipi: 0,
      repasse_frete: 0,
      repasse_st: 0,
      repasse_seguros: 0,
      repasse_outras_despesas: 0,
      carregando: false,
      config,
      numeroCompra: '-',
      isProdutosSomenteFornecedor: true,
      isIncluiInativos: false,
      serieNotaFiscal: '',
      chaveAcessoNotaFiscal: '',
      isImportacaoXml: false,
      tipo: TIPO_COMPRA_MERCADORIAS_REVENDA,
      uf: '',
      dadosXml: null,
      subTotalPresumido: 0,
      total_desconto_nfe: 0,
      total_desconto_descarrego: 0,
      total_desconto_bonificacao: 0,
      bonificacao_usada_id: null,
    });
  }

  const handleCloseDialog = () => {
    setOpen(false);
    resetar();
    setTela(TELA_PRE_INICIO_FRENTE_CAIXA);
    handleClose();
    dispatch({
      type: 'PREENCHER_TUDO',
      idEdit: -1,
      lotes: [],
      parcelas: [],
      //fornecedor: dataFornecedores.data[0],
      fornecedor: null,
      numeroNotaFiscal: '',
      data_faturamento: '',
      data_criacao: new Date(),
      loja: {
        nome: 'CARUARU FRIOS',
        value: 'CF',
      },
      repasse_ipi: 0,
      repasse_frete: 0,
      repasse_st: 0,
      repasse_seguros: 0,
      repasse_outras_despesas: 0,
      carregando: true,
      config: null,
      numeroCompra: '-',
      isProdutosSomenteFornecedor: true,
      isIncluiInativos: false,
      serieNotaFiscal: '',
      chaveAcessoNotaFiscal: '',
      isImportacaoXml: false,
      tipo: TIPO_COMPRA_MERCADORIAS_REVENDA,
      uf: '',
      dadosXml: null,
      subTotalPresumido: 0,
      total_desconto_nfe: 0,
      total_desconto_descarrego: 0,
      total_desconto_bonificacao: 0,
      bonificacao_usada_id: null,
    });
  };

  function irParaTelaInit() {
    handleCloseDialog();
  }

  function irParaTelaFinalizarCompra() {
    setTela(TELA_FINALIZAR_FRENTE_CAIXA);
  }

  function irParaTelaFrentedeCaixa() {
    setTela(TELA_INICIO_FRENTE_CAIXA);
  }

  function irParaTelaPreFrentedeCaixa() {
    setTela(TELA_PRE_INICIO_FRENTE_CAIXA);
  }

  function irParaTelaCarregamentoXml() {
    setTela(TELA_IMPORTACAO_XML);
  }

  function irParaTelaTipoCompra() {
    setTela(TELA_TIPO_COMPRA);
  }

  function format10(value) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  function formatDate(date) {
    if (date) {
      const dateReal = new Date(date);
      const ano = dateReal.getFullYear();
      const mes = format10(dateReal.getMonth() + 1);
      const dia = format10(dateReal.getDate());
      return `${ano}-${mes}-${dia}`;
    }
    return '';
  }

  function getDadosUteisXml(dados) {
    const dadosFormatados = {
      remetente: {},
      destinatario: {},
      itens: [],
      parcelas: [],
      pagamentos: [],
      totalRepasseFreteCalculado: 0,
      totalRepasseSeguroCalculado: 0,
      totalRepasseOutrasDespesasCalculado: 0,
      totalRepasseIpiCalculado: 0,
      totalRepasseSTCalculado: 0,
      fat: null,
      transp: null,
    };
    if (dados) {
      const nfeProc = dados.nfeProc;
      if (nfeProc) {
        if (nfeProc.NFe) {
          if (nfeProc.NFe.length > 0) {
            const NFe = nfeProc.NFe[0];
            if (NFe.infNFe) {
              if (NFe.infNFe.length > 0) {
                const infNFe = NFe.infNFe[0];

                // Id
                if (infNFe.Id) {
                  if (infNFe.Id.length > 0) {
                    dadosFormatados.Id = {
                      descricao: `Chave de Acesso`,
                      value: infNFe.Id[0].replace('NFe', ''),
                      valorFormatado: infNFe.Id[0].replace('NFe', ''),
                    };
                  }
                }

                // ide
                if (infNFe.ide) {
                  if (infNFe.ide.length > 0) {
                    const ide = infNFe.ide[0];

                    // serie
                    if (ide.serie) {
                      if (ide.serie.length > 0) {
                        dadosFormatados.serie = {
                          descricao: 'Série do Documento Fiscal',
                          value: preencherComZeros(ide.serie[0], 3),
                          valorFormatado: preencherComZeros(ide.serie[0], 3),
                        };
                      }
                    }

                    // nNF
                    if (ide.nNF) {
                      if (ide.nNF.length > 0) {
                        dadosFormatados.nNF = {
                          descricao: 'Número do Documento Fiscal',
                          value: preencherComZeros(ide.nNF[0], 9),
                          valorFormatado: preencherComZeros(ide.nNF[0], 9),
                        };
                      }
                    }

                    // dhEmi
                    if (ide.dhEmi) {
                      if (ide.dhEmi.length > 0) {
                        dadosFormatados.dhEmi = {
                          descricao:
                            'Data e hora de emissão do Documento Fiscal',
                          value: new Date(ide.dhEmi[0]),
                          valorFormatado: formatDateDDMMYYYYHHmm(
                            new Date(ide.dhEmi[0]),
                          ),
                        };
                      }
                    }

                    // tpNF
                    if (ide.tpNF) {
                      if (ide.tpNF.length > 0) {
                        dadosFormatados.tpNF = {
                          descricao: 'Tipo de Operação',
                          value: ide.tpNF[0],
                          valorFormatado: codigosTipoOperacao[ide.tpNF[0]],
                        };
                      }
                    }

                    // tpAmb
                    if (ide.tpAmb) {
                      if (ide.tpAmb.length > 0) {
                        dadosFormatados.tpAmb = {
                          descricao: 'Identificação do Ambiente',
                          value: ide.tpAmb[0],
                          valorFormatado:
                            codigosIdentificacaoDoAmbiente[ide.tpAmb[0]],
                        };
                      }
                    }

                    // finNFe
                    if (ide.finNFe) {
                      if (ide.finNFe.length > 0) {
                        dadosFormatados.finNFe = {
                          descricao: 'Finalidade de emissão da NF-e',
                          value: ide.finNFe[0],
                          valorFormatado:
                            codigosFinalidadeDaEmissaoDaNfe[ide.finNFe[0]],
                        };
                      }
                    }
                  }
                }

                //

                if (infNFe.emit) {
                  if (infNFe.emit.length > 0) {
                    const emit = infNFe.emit[0];

                    // CNPJ
                    if (emit.CNPJ) {
                      if (emit.CNPJ.length > 0) {
                        dadosFormatados.remetente.CNPJ = {
                          descricao: 'CNPJ do emitente',
                          value: emit.CNPJ[0],
                          valorFormatado: formatCNPJ(emit.CNPJ[0]),
                        };
                      }
                    }

                    // CPF
                    if (emit.CPF) {
                      if (emit.CPF.length > 0) {
                        dadosFormatados.remetente.CPF = {
                          descricao: 'CPF do remetente',
                          value: emit.CPF[0],
                          valorFormatado: formatCPF(emit.CPF[0]),
                        };
                      }
                    }

                    // xNome
                    if (emit.xNome) {
                      if (emit.xNome.length > 0) {
                        dadosFormatados.remetente.xNome = {
                          descricao: 'Razão Social ou Nome do emitente',
                          value: emit.xNome[0],
                          valorFormatado: emit.xNome[0],
                        };
                      }
                    }

                    // xFant
                    if (emit.xFant) {
                      if (emit.xFant.length > 0) {
                        dadosFormatados.remetente.xFant = {
                          descricao: 'Nome fantasia',
                          value: emit.xFant[0],
                          valorFormatado: emit.xFant[0],
                        };
                      }
                    }

                    // IE
                    if (emit.IE) {
                      if (emit.IE.length > 0) {
                        dadosFormatados.remetente.IE = {
                          descricao: 'Inscrição Estadual do Emitente',
                          value: emit.IE[0],
                          valorFormatado: formatIE(emit.IE[0]),
                        };
                      }
                    }

                    // CRT
                    if (emit.CRT) {
                      if (emit.CRT.length > 0) {
                        dadosFormatados.remetente.CRT = {
                          descricao: 'Código de Regime Tributário',
                          value: emit.CRT[0],
                          valorFormatado: emit.CRT[0],
                        };
                      }
                    }

                    // enderEmit
                    if (emit.enderEmit) {
                      if (emit.enderEmit.length > 0) {
                        const enderEmit = emit.enderEmit[0];

                        // xLgr
                        if (enderEmit.xLgr) {
                          if (enderEmit.xLgr.length > 0) {
                            dadosFormatados.remetente.xLgr = {
                              descricao: 'Logradouro',
                              value: enderEmit.xLgr[0],
                              valorFormatado: enderEmit.xLgr[0],
                            };
                          }
                        }

                        // nro
                        if (enderEmit.nro) {
                          if (enderEmit.nro.length > 0) {
                            dadosFormatados.remetente.nro = {
                              descricao: 'Número',
                              value: enderEmit.nro[0],
                              valorFormatado: enderEmit.nro[0],
                            };
                          }
                        }

                        // xCpl
                        if (enderEmit.xCpl) {
                          if (enderEmit.xCpl.length > 0) {
                            dadosFormatados.remetente.xCpl = {
                              descricao: 'Complemento',
                              value: enderEmit.xCpl[0],
                              valorFormatado: enderEmit.xCpl[0],
                            };
                          }
                        }

                        // xBairro
                        if (enderEmit.xBairro) {
                          if (enderEmit.xBairro.length > 0) {
                            dadosFormatados.remetente.xBairro = {
                              descricao: 'Bairro',
                              value: enderEmit.xBairro[0],
                              valorFormatado: enderEmit.xBairro[0],
                            };
                          }
                        }

                        // cMun
                        if (enderEmit.cMun) {
                          if (enderEmit.cMun.length > 0) {
                            dadosFormatados.remetente.cMun = {
                              descricao: 'Código do município',
                              value: enderEmit.cMun[0],
                              valorFormatado: enderEmit.cMun[0],
                            };
                          }
                        }

                        // xMun
                        if (enderEmit.xMun) {
                          if (enderEmit.xMun.length > 0) {
                            dadosFormatados.remetente.xMun = {
                              descricao: 'Nome do município',
                              value: enderEmit.xMun[0],
                              valorFormatado: enderEmit.xMun[0],
                            };
                          }
                        }

                        // UF
                        if (enderEmit.UF) {
                          if (enderEmit.UF.length > 0) {
                            dadosFormatados.remetente.UF = {
                              descricao: 'Sigla da UF',
                              value: enderEmit.UF[0],
                              valorFormatado: enderEmit.UF[0],
                            };
                          }
                        }

                        // CEP
                        if (enderEmit.CEP) {
                          if (enderEmit.CEP.length > 0) {
                            dadosFormatados.remetente.CEP = {
                              descricao: 'Código do CEP',
                              value: enderEmit.CEP[0],
                              valorFormatado: enderEmit.CEP[0],
                            };
                          }
                        }

                        // fone
                        if (enderEmit.fone) {
                          if (enderEmit.fone.length > 0) {
                            dadosFormatados.remetente.fone = {
                              descricao: 'Telefone',
                              value: enderEmit.fone[0],
                              valorFormatado: enderEmit.fone[0],
                            };
                          }
                        }
                      }
                    }
                  }
                }

                ///

                if (infNFe.dest) {
                  if (infNFe.dest.length > 0) {
                    const dest = infNFe.dest[0];

                    // CNPJ
                    if (dest.CNPJ) {
                      if (dest.CNPJ.length > 0) {
                        dadosFormatados.destinatario.CNPJ = {
                          descricao: 'CNPJ do destinatário',
                          value: dest.CNPJ[0],
                          valorFormatado: formatCNPJ(dest.CNPJ[0]),
                        };
                      }
                    }

                    // CPF
                    if (dest.CPF) {
                      if (dest.CPF.length > 0) {
                        dadosFormatados.destinatario.CPF = {
                          descricao: 'CNPJ do destinatário',
                          value: dest.CPF[0],
                          valorFormatado: formatCPF(dest.CPF[0]),
                        };
                      }
                    }

                    // xNome
                    if (dest.xNome) {
                      if (dest.xNome.length > 0) {
                        dadosFormatados.destinatario.xNome = {
                          descricao: 'Razão Social ou nome do destinatário',
                          value: dest.xNome[0],
                          valorFormatado: dest.xNome[0],
                        };
                      }
                    }

                    // IE
                    if (dest.IE) {
                      if (dest.IE.length > 0) {
                        dadosFormatados.destinatario.IE = {
                          descricao: 'Inscrição Estadual do Destinatário',
                          value: dest.IE[0],
                          valorFormatado: dest.IE[0],
                        };
                      }
                    }

                    // email
                    if (dest.email) {
                      if (dest.email.length > 0) {
                        dadosFormatados.destinatario.email = {
                          descricao: 'email',
                          value: dest.email[0],
                          valorFormatado: dest.email[0],
                        };
                      }
                    }

                    // enderEmit
                    if (dest.enderDest) {
                      if (dest.enderDest.length > 0) {
                        const enderDest = dest.enderDest[0];

                        // xLgr
                        if (enderDest.xLgr) {
                          if (enderDest.xLgr.length > 0) {
                            dadosFormatados.destinatario.xLgr = {
                              descricao: 'Logradouro',
                              value: enderDest.xLgr[0],
                              valorFormatado: enderDest.xLgr[0],
                            };
                          }
                        }

                        // nro
                        if (enderDest.nro) {
                          if (enderDest.nro.length > 0) {
                            dadosFormatados.destinatario.nro = {
                              descricao: 'Número',
                              value: enderDest.nro[0],
                              valorFormatado: enderDest.nro[0],
                            };
                          }
                        }

                        // xCpl
                        if (enderDest.xCpl) {
                          if (enderDest.xCpl.length > 0) {
                            dadosFormatados.destinatario.xCpl = {
                              descricao: 'Complemento',
                              value: enderDest.xCpl[0],
                              valorFormatado: enderDest.xCpl[0],
                            };
                          }
                        }

                        // xBairro
                        if (enderDest.xBairro) {
                          if (enderDest.xBairro.length > 0) {
                            dadosFormatados.destinatario.xBairro = {
                              descricao: 'Bairro',
                              value: enderDest.xBairro[0],
                              valorFormatado: enderDest.xBairro[0],
                            };
                          }
                        }

                        // cMun
                        if (enderDest.cMun) {
                          if (enderDest.cMun.length > 0) {
                            dadosFormatados.destinatario.cMun = {
                              descricao: 'Código do município',
                              value: enderDest.cMun[0],
                              valorFormatado: enderDest.cMun[0],
                            };
                          }
                        }

                        // xMun
                        if (enderDest.xMun) {
                          if (enderDest.xMun.length > 0) {
                            dadosFormatados.destinatario.xMun = {
                              descricao: 'Nome do município',
                              value: enderDest.xMun[0],
                              valorFormatado: enderDest.xMun[0],
                            };
                          }
                        }

                        // UF
                        if (enderDest.UF) {
                          if (enderDest.UF.length > 0) {
                            dadosFormatados.destinatario.UF = {
                              descricao: 'Sigla da UF',
                              value: enderDest.UF[0],
                              valorFormatado: enderDest.UF[0],
                            };
                          }
                        }

                        // CEP
                        if (enderDest.CEP) {
                          if (enderDest.CEP.length > 0) {
                            dadosFormatados.destinatario.CEP = {
                              descricao: 'Código do CEP',
                              value: enderDest.CEP[0],
                              valorFormatado: enderDest.CEP[0],
                            };
                          }
                        }

                        // cPais
                        if (enderDest.cPais) {
                          if (enderDest.cPais.length > 0) {
                            dadosFormatados.destinatario.cPais = {
                              descricao: 'Código do País',
                              value: enderDest.cPais[0],
                              valorFormatado: enderDest.cPais[0],
                            };
                          }
                        }

                        // xPais
                        if (enderDest.xPais) {
                          if (enderDest.xPais.length > 0) {
                            dadosFormatados.destinatario.xPais = {
                              descricao: 'Nome do País',
                              value: enderDest.xPais[0],
                              valorFormatado: enderDest.xPais[0],
                            };
                          }
                        }

                        // fone
                        if (enderDest.fone) {
                          if (enderDest.fone.length > 0) {
                            dadosFormatados.destinatario.fone = {
                              descricao: 'Telefone',
                              value: enderDest.fone[0],
                              valorFormatado: enderDest.fone[0],
                            };
                          }
                        }
                      }
                    }
                  }
                }

                ///////////

                if (infNFe.det) {
                  const dets = infNFe.det;

                  for (let i = 0; i < dets.length; i++) {
                    const det = dets[i];
                    const detFormatados = {};

                    // nItem
                    if (det.nItem) {
                      if (det.nItem.length > 0) {
                        detFormatados.nItem = {
                          descricao: 'Número do item',
                          value: det.nItem[0],
                          valorFormatado: det.nItem[0],
                        };
                      }
                    }

                    // prod
                    if (det.prod) {
                      if (det.prod.length > 0) {
                        const prod = det.prod[0];

                        // cProd
                        if (prod.cProd) {
                          if (prod.cProd.length > 0) {
                            detFormatados.cProd = {
                              descricao: 'Código do produto ou serviço',
                              value: prod.cProd[0],
                              valorFormatado: prod.cProd[0],
                            };
                          }
                        }

                        // cEAN
                        if (prod.cEAN) {
                          if (prod.cEAN.length > 0) {
                            detFormatados.cEAN = {
                              descricao:
                                'GTIN (Global Trade Item Number) do produto, antigo código EAN ou código de barras',
                              value: prod.cEAN[0],
                              valorFormatado: prod.cEAN[0],
                            };
                          }
                        }

                        // xProd
                        if (prod.xProd) {
                          if (prod.xProd.length > 0) {
                            detFormatados.xProd = {
                              descricao: 'Descrição do produto ou serviço',
                              value: prod.xProd[0]
                                .trim()
                                .replace(/\s{2,}/g, ' '),
                              valorFormatado: prod.xProd[0]
                                .trim()
                                .replace(/\s{2,}/g, ' '),
                            };
                          }
                        }

                        // NCM
                        if (prod.NCM) {
                          if (prod.NCM.length > 0) {
                            detFormatados.NCM = {
                              descricao: 'Código NCM com 8 dígitos ',
                              value: prod.NCM[0],
                              valorFormatado: prod.NCM[0],
                            };
                          }
                        }

                        // NVE
                        if (prod.NVE) {
                          if (prod.NVE.length > 0) {
                            detFormatados.NVE = {
                              descricao:
                                'Codificação NVE - Nomenclatura de Valor Aduaneiro e Estatística.',
                              value: prod.NVE[0],
                              valorFormatado: prod.NVE[0],
                            };
                          }
                        }

                        // CEST
                        if (prod.CEST) {
                          if (prod.CEST.length > 0) {
                            detFormatados.CEST = {
                              descricao: 'Código CEST',
                              value: prod.CEST[0],
                              valorFormatado: prod.CEST[0],
                            };
                          }
                        }

                        // indEscala
                        if (prod.indEscala) {
                          if (prod.indEscala.length > 0) {
                            detFormatados.indEscala = {
                              descricao: 'Indicador de Escala Relevante',
                              value: prod.indEscala[0],
                              valorFormatado: prod.indEscala[0],
                            };
                          }
                        }

                        // CNPJFab
                        if (prod.CNPJFab) {
                          if (prod.CNPJFab.length > 0) {
                            detFormatados.CNPJFab = {
                              descricao: 'CNPJ do Fabricante da Mercadoria',
                              value: prod.CNPJFab[0],
                              valorFormatado: prod.CNPJFab[0],
                            };
                          }
                        }

                        // cBenef
                        if (prod.cBenef) {
                          if (prod.cBenef.length > 0) {
                            detFormatados.cBenef = {
                              descricao:
                                'Código de Benefício Fiscal na UF aplicado ao item',
                              value: prod.cBenef[0],
                              valorFormatado: prod.cBenef[0],
                            };
                          }
                        }

                        // EXTIPI
                        if (prod.EXTIPI) {
                          if (prod.EXTIPI.length > 0) {
                            detFormatados.EXTIPI = {
                              descricao: 'EX_TIPI',
                              value: prod.EXTIPI[0],
                              valorFormatado: prod.EXTIPI[0],
                            };
                          }
                        }

                        // CFOP
                        if (prod.CFOP) {
                          if (prod.CFOP.length > 0) {
                            detFormatados.CFOP = {
                              descricao:
                                'Código Fiscal de Operações e Prestações',
                              value: prod.CFOP[0],
                              valorFormatado: prod.CFOP[0],
                            };
                          }
                        }

                        // uCom
                        if (prod.uCom) {
                          if (prod.uCom.length > 0) {
                            detFormatados.uCom = {
                              descricao: 'Unidade Comercial ',
                              value: prod.uCom[0],
                              valorFormatado: prod.uCom[0],
                            };
                          }
                        }

                        // qCom
                        if (prod.qCom) {
                          if (prod.qCom.length > 0) {
                            detFormatados.qCom = {
                              descricao: 'Quantidade Comercial',
                              value: Number(prod.qCom[0]),
                              valorFormatado: prod.qCom[0],
                            };
                          }
                        }

                        // vUnCom
                        if (prod.vUnCom) {
                          if (prod.vUnCom.length > 0) {
                            detFormatados.vUnCom = {
                              descricao: 'Valor Unitário de Comercialização',
                              value: Number(prod.vUnCom[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vUnCom[0]),
                              ),
                            };
                          }
                        }

                        // vProd
                        if (prod.vProd) {
                          if (prod.vProd.length > 0) {
                            detFormatados.vProd = {
                              descricao:
                                'Valor Total Bruto dos Produtos ou Serviços.',
                              value: Number(prod.vProd[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vProd[0]),
                              ),
                            };
                          }
                        }

                        // cEANTrib
                        if (prod.cEANTrib) {
                          if (prod.cEANTrib.length > 0) {
                            detFormatados.cEANTrib = {
                              descricao:
                                'GTIN (Global Trade Item Number) da unidade tributável, antigo código EAN ou código de barras',
                              value: prod.cEANTrib[0],
                              valorFormatado: prod.cEANTrib[0],
                            };
                          }
                        }

                        // uTrib
                        if (prod.uTrib) {
                          if (prod.uTrib.length > 0) {
                            detFormatados.uTrib = {
                              descricao: 'Unidade Tributável',
                              value: prod.uTrib[0],
                              valorFormatado: prod.uTrib[0],
                            };
                          }
                        }

                        // qTrib
                        if (prod.qTrib) {
                          if (prod.qTrib.length > 0) {
                            detFormatados.qTrib = {
                              descricao: 'Quantidade Tributável',
                              value: Number(prod.qTrib[0]),
                              valorFormatado: prod.qTrib[0],
                            };
                          }
                        }

                        // vUnTrib
                        if (prod.vUnTrib) {
                          if (prod.vUnTrib.length > 0) {
                            detFormatados.vUnTrib = {
                              descricao: 'Valor Unitário de tributação',
                              value: Number(prod.vUnTrib[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vUnTrib[0]),
                              ),
                            };
                          }
                        }

                        // vFrete
                        if (prod.vFrete) {
                          if (prod.vFrete.length > 0) {
                            dadosFormatados.totalRepasseFreteCalculado += Number(
                              prod.vFrete[0],
                            );
                            detFormatados.vFrete = {
                              descricao: 'Valor Total do Frete',
                              value: Number(prod.vFrete[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vFrete[0]),
                              ),
                            };
                          }
                        }

                        // vSeg
                        if (prod.vSeg) {
                          if (prod.vSeg.length > 0) {
                            dadosFormatados.totalRepasseSeguroCalculado += Number(
                              prod.vSeg[0],
                            );
                            detFormatados.vSeg = {
                              descricao: 'Valor Total do Seguro',
                              value: Number(prod.vSeg[0]),
                              valorFormatado: formatMoeda(Number(prod.vSeg[0])),
                            };
                          }
                        }

                        // vDesc
                        if (prod.vDesc) {
                          if (prod.vDesc.length > 0) {
                            detFormatados.vDesc = {
                              descricao: 'Valor do Desconto',
                              value: Number(prod.vDesc[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vDesc[0]),
                              ),
                            };
                          }
                        }

                        // vOutro
                        if (prod.vOutro) {
                          if (prod.vOutro.length > 0) {
                            dadosFormatados.totalRepasseOutrasDespesasCalculado += Number(
                              prod.vOutro[0],
                            );
                            detFormatados.vOutro = {
                              descricao: 'Outras despesas acessórias',
                              value: Number(prod.vOutro[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vOutro[0]),
                              ),
                            };
                          }
                        }

                        // indTot
                        if (prod.indTot) {
                          if (prod.indTot.length > 0) {
                            detFormatados.indTot = {
                              descricao:
                                'Indica se valor do Item (vProd) entra no valor total da NF-e (vProd)',
                              value: prod.indTot[0],
                              valorFormatado: prod.indTot[0],
                            };
                          }
                        }

                        // rastro
                        if (prod.rastro) {
                          let dataVal = null;
                          for (let i = 0; i < prod.rastro.length; i++) {
                            const rastro = prod.rastro[i];

                            // dVal
                            if (rastro.dVal) {
                              if (rastro.dVal.length > 0) {
                                const dVal = new Date(rastro.dVal[0]);
                                if (!dataVal) {
                                  dataVal = dVal;
                                } else if (dataVal.getTime() < dVal.getTime()) {
                                  dataVal = dVal;
                                }
                              }
                            }
                          }

                          detFormatados.dVal = {
                            descricao: 'Data de validade',
                            value: dataVal,
                            valorFormatado: formatDateDDMMYYYY(dataVal),
                          };
                        }
                      }
                    }

                    if (det.imposto) {
                      if (det.imposto.length > 0) {
                        const imposto = det.imposto[0];
                        if (imposto.IPI) {
                          if (imposto.IPI.length > 0) {
                            const IPI = imposto.IPI[0];

                            // IPITrib
                            if (IPI.IPITrib) {
                              if (IPI.IPITrib.length > 0) {
                                const IPITrib = IPI.IPITrib[0];

                                // vIPI
                                if (IPITrib.vIPI) {
                                  if (IPITrib.vIPI.length > 0) {
                                    dadosFormatados.totalRepasseIpiCalculado += Number(
                                      IPITrib.vIPI[0],
                                    );
                                    detFormatados.vIPI = {
                                      descricao: 'Valor do IPI',
                                      value: Number(IPITrib.vIPI[0]),
                                      valorFormatado: formatMoeda(
                                        Number(IPITrib.vIPI[0]),
                                      ),
                                    };
                                  }
                                }
                              }
                            }
                          }
                        }

                        //////////////////////

                        if (imposto.ICMS) {
                          if (imposto.ICMS.length > 0) {
                            const ICMS = imposto.ICMS[0];

                            if (ICMS.ICMS10) {
                              if (ICMS.ICMS10.length > 0) {
                                const ICMS10 = ICMS.ICMS10[0];

                                // vICMSST
                                if (ICMS10.vICMSST) {
                                  if (ICMS10.vICMSST.length > 0) {
                                    dadosFormatados.totalRepasseSTCalculado += Number(
                                      ICMS10.vICMSST[0],
                                    );
                                    detFormatados.vICMSST = {
                                      descricao: 'Valor do ICMS ST',
                                      value: Number(ICMS10.vICMSST[0]),
                                      valorFormatado: formatMoeda(
                                        Number(ICMS10.vICMSST[0]),
                                      ),
                                    };
                                  }
                                }
                              }
                            }

                            if (ICMS.ICMS70) {
                              if (ICMS.ICMS70.length > 0) {
                                const ICMS70 = ICMS.ICMS70[0];

                                // vICMSST
                                if (ICMS70.vICMSST) {
                                  if (ICMS70.vICMSST.length > 0) {
                                    dadosFormatados.totalRepasseSTCalculado += Number(
                                      ICMS70.vICMSST[0],
                                    );
                                    detFormatados.vICMSST = {
                                      descricao: 'Valor do ICMS ST',
                                      value: Number(ICMS70.vICMSST[0]),
                                      valorFormatado: formatMoeda(
                                        Number(ICMS70.vICMSST[0]),
                                      ),
                                    };
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    dadosFormatados.itens.push(detFormatados);
                  }
                }

                /////////

                if (infNFe.total) {
                  if (infNFe.total.length > 0) {
                    const total = infNFe.total[0];

                    // ICMSTot
                    if (total.ICMSTot) {
                      if (total.ICMSTot.length > 0) {
                        const ICMSTot = total.ICMSTot[0];

                        // vST
                        if (ICMSTot.vST) {
                          if (ICMSTot.vST.length > 0) {
                            dadosFormatados.vST = {
                              descricao: 'Valor Total do ICMS ST  ',
                              value: Number(ICMSTot.vST[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vST[0]),
                              ),
                            };
                          }
                        }

                        // vProd
                        if (ICMSTot.vProd) {
                          if (ICMSTot.vProd.length > 0) {
                            dadosFormatados.vProd = {
                              descricao: 'Valor Total dos produtos e serviços',
                              value: Number(ICMSTot.vProd[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vProd[0]),
                              ),
                            };
                          }
                        }

                        // vFrete
                        if (ICMSTot.vFrete) {
                          if (ICMSTot.vFrete.length > 0) {
                            dadosFormatados.vFrete = {
                              descricao: 'Valor Total do Frete',
                              value: Number(ICMSTot.vFrete[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vFrete[0]),
                              ),
                            };
                          }
                        }

                        // vSeg
                        if (ICMSTot.vSeg) {
                          if (ICMSTot.vSeg.length > 0) {
                            dadosFormatados.vSeg = {
                              descricao: 'Valor Total do Seguro',
                              value: Number(ICMSTot.vSeg[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vSeg[0]),
                              ),
                            };
                          }
                        }

                        // vDesc
                        if (ICMSTot.vDesc) {
                          if (ICMSTot.vDesc.length > 0) {
                            dadosFormatados.vDesc = {
                              descricao: 'Valor Total do Desconto',
                              value: Number(ICMSTot.vDesc[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vDesc[0]),
                              ),
                            };
                          }
                        }

                        // vII
                        if (ICMSTot.vII) {
                          if (ICMSTot.vII.length > 0) {
                            dadosFormatados.vII = {
                              descricao: 'Valor Total do II',
                              value: Number(ICMSTot.vII[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vII[0]),
                              ),
                            };
                          }
                        }

                        // vIPI
                        if (ICMSTot.vIPI) {
                          if (ICMSTot.vIPI.length > 0) {
                            dadosFormatados.vIPI = {
                              descricao: 'Valor Total do IPI',
                              value: Number(ICMSTot.vIPI[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vIPI[0]),
                              ),
                            };
                          }
                        }

                        // vIPIDevol
                        if (ICMSTot.vIPIDevol) {
                          if (ICMSTot.vIPIDevol.length > 0) {
                            dadosFormatados.vIPIDevol = {
                              descricao: 'Valor Total do IPI devolvido',
                              value: Number(ICMSTot.vIPIDevol[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vIPIDevol[0]),
                              ),
                            };
                          }
                        }

                        // vOutro
                        if (ICMSTot.vOutro) {
                          if (ICMSTot.vOutro.length > 0) {
                            dadosFormatados.vOutro = {
                              descricao: 'Outras Despesas acessórias',
                              value: Number(ICMSTot.vOutro[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vOutro[0]),
                              ),
                            };
                          }
                        }

                        // vNF
                        if (ICMSTot.vNF) {
                          if (ICMSTot.vNF.length > 0) {
                            dadosFormatados.vNF = {
                              descricao: 'Valor Total da NF-e',
                              value: Number(ICMSTot.vNF[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vNF[0]),
                              ),
                            };
                          }
                        }
                      }
                    }
                  }
                }

                ////////////
                if (infNFe.transp) {
                  if (infNFe.transp.length > 0) {
                    const transp = infNFe.transp[0];
                    const transpFomatado = {};

                    // modFrete
                    if (transp.modFrete) {
                      if (transp.modFrete.length > 0) {
                        transpFomatado.nFat = {
                          descricao: 'Modalidade do Frete',
                          value: transp.modFrete[0],
                          valorFormatado: transp.modFrete[0],
                        };
                      }
                    }

                    // transporta
                    if (transp.transporta) {
                      if (transp.transporta.length > 0) {
                        const transporta = transp.transporta[0];

                        // CNPJ
                        if (transporta.CNPJ) {
                          if (transporta.CNPJ.length > 0) {
                            transpFomatado.CNPJ = {
                              descricao: 'CNPJ do Transportador',
                              value: transporta.CNPJ[0],
                              valorFormatado: transporta.CNPJ[0],
                            };
                          }
                        }

                        // CPF
                        if (transporta.CPF) {
                          if (transporta.CPF.length > 0) {
                            transpFomatado.CPF = {
                              descricao: 'CPF do Transportador',
                              value: transporta.CPF[0],
                              valorFormatado: transporta.CPF[0],
                            };
                          }
                        }

                        // xNome
                        if (transporta.xNome) {
                          if (transporta.xNome.length > 0) {
                            transpFomatado.xNome = {
                              descricao: 'Razão Social ou nome',
                              value: transporta.xNome[0],
                              valorFormatado: transporta.xNome[0],
                            };
                          }
                        }

                        // IE
                        if (transporta.IE) {
                          if (transporta.IE.length > 0) {
                            transpFomatado.IE = {
                              descricao: 'Inscrição Estadual do Transportador',
                              value: transporta.IE[0],
                              valorFormatado: transporta.IE[0],
                            };
                          }
                        }

                        // xEnder
                        if (transporta.xEnder) {
                          if (transporta.xEnder.length > 0) {
                            transpFomatado.xEnder = {
                              descricao: 'Endereço Completo',
                              value: transporta.xEnder[0],
                              valorFormatado: transporta.xEnder[0],
                            };
                          }
                        }

                        // xMun
                        if (transporta.xMun) {
                          if (transporta.xMun.length > 0) {
                            transpFomatado.xMun = {
                              descricao: 'Nome do município',
                              value: transporta.xMun[0],
                              valorFormatado: transporta.xMun[0],
                            };
                          }
                        }

                        // UF
                        if (transporta.UF) {
                          if (transporta.UF.length > 0) {
                            transpFomatado.UF = {
                              descricao: 'Sigla da UF',
                              value: transporta.UF[0],
                              valorFormatado: transporta.UF[0],
                            };
                          }
                        }
                      }
                    }

                    // retTransp
                    if (transp.retTransp) {
                      if (transp.retTransp.length > 0) {
                        const retTransp = transp.retTransp[0];

                        // vServ
                        if (retTransp.vServ) {
                          if (retTransp.vServ.length > 0) {
                            transpFomatado.vServ = {
                              descricao: 'Valor do Serviço',
                              value: retTransp.vServ[0],
                              valorFormatado: retTransp.vServ[0],
                            };
                          }
                        }

                        // vBCRet
                        if (retTransp.vBCRet) {
                          if (retTransp.vBCRet.length > 0) {
                            transpFomatado.vBCRet = {
                              descricao: 'BC da Retenção do ICMS',
                              value: retTransp.vServ[0],
                              valorFormatado: retTransp.vBCRet[0],
                            };
                          }
                        }

                        // pICMSRet
                        if (retTransp.pICMSRet) {
                          if (retTransp.pICMSRet.length > 0) {
                            transpFomatado.pICMSRet = {
                              descricao: 'Alíquota da Retenção',
                              value: retTransp.pICMSRet[0],
                              valorFormatado: retTransp.pICMSRet[0],
                            };
                          }
                        }

                        // vICMSRet
                        if (retTransp.vICMSRet) {
                          if (retTransp.vICMSRet.length > 0) {
                            transpFomatado.vICMSRet = {
                              descricao: 'Valor do ICMS Retido',
                              value: retTransp.vICMSRet[0],
                              valorFormatado: retTransp.vICMSRet[0],
                            };
                          }
                        }

                        // CFOP
                        if (retTransp.CFOP) {
                          if (retTransp.CFOP.length > 0) {
                            transpFomatado.CFOP = {
                              descricao: 'CFOP',
                              value: retTransp.CFOP[0],
                              valorFormatado: retTransp.CFOP[0],
                            };
                          }
                        }

                        // cMunFG
                        if (retTransp.cMunFG) {
                          if (retTransp.cMunFG.length > 0) {
                            transpFomatado.cMunFG = {
                              descricao:
                                'Código do município de ocorrência do fato gerador do ICMS do transporte',
                              value: retTransp.cMunFG[0],
                              valorFormatado: retTransp.cMunFG[0],
                            };
                          }
                        }
                      }
                    }

                    // veicTransp
                    if (transp.veicTransp) {
                      if (transp.veicTransp.length > 0) {
                        const veicTransp = transp.veicTransp[0];

                        // placa
                        if (veicTransp.placa) {
                          if (veicTransp.placa.length > 0) {
                            transpFomatado.placa = {
                              descricao: 'Placa do Veículo',
                              value: veicTransp.placa[0],
                              valorFormatado: veicTransp.placa[0],
                            };
                          }
                        }

                        // UF
                        if (veicTransp.UF) {
                          if (veicTransp.UF.length > 0) {
                            transpFomatado.veicTranspUF = {
                              descricao: 'Sigla da UF',
                              value: veicTransp.UF[0],
                              valorFormatado: veicTransp.UF[0],
                            };
                          }
                        }

                        // RNTC
                        if (veicTransp.RNTC) {
                          if (veicTransp.RNTC.length > 0) {
                            transpFomatado.RNTC = {
                              descricao:
                                'Registro Nacional de Transportador de Carga (ANTT)',
                              value: veicTransp.RNTC[0],
                              valorFormatado: veicTransp.RNTC[0],
                            };
                          }
                        }
                      }
                    }

                    // reboque
                    if (transp.reboque) {
                      if (transp.reboque.length > 0) {
                        const reboque = transp.reboque[0];

                        // placa
                        if (reboque.placa) {
                          if (reboque.placa.length > 0) {
                            transpFomatado.placa = {
                              descricao: 'Placa do Veículo',
                              value: reboque.placa[0],
                              valorFormatado: reboque.placa[0],
                            };
                          }
                        }

                        // UF
                        if (reboque.UF) {
                          if (reboque.UF.length > 0) {
                            transpFomatado.reboqueUF = {
                              descricao: 'Sigla da UF',
                              value: reboque.UF[0],
                              valorFormatado: reboque.UF[0],
                            };
                          }
                        }

                        // RNTC
                        if (reboque.RNTC) {
                          if (reboque.RNTC.length > 0) {
                            transpFomatado.RNTC = {
                              descricao:
                                'Registro Nacional de Transportador de Carga (ANTT)',
                              value: reboque.RNTC[0],
                              valorFormatado: reboque.RNTC[0],
                            };
                          }
                        }

                        // vagao
                        if (reboque.vagao) {
                          if (reboque.vagao.length > 0) {
                            transpFomatado.vagao = {
                              descricao: 'Identificação do vagão',
                              value: reboque.vagao[0],
                              valorFormatado: reboque.vagao[0],
                            };
                          }
                        }

                        // balsa
                        if (reboque.balsa) {
                          if (reboque.balsa.length > 0) {
                            transpFomatado.balsa = {
                              descricao: 'Identificação da balsa',
                              value: reboque.balsa[0],
                              valorFormatado: reboque.balsa[0],
                            };
                          }
                        }
                      }
                    }

                    // modFrete
                    if (transp.vol) {
                      let qVol = 0;
                      let pesoL = 0;
                      let pesoB = 0;
                      if (transp.vol.length > 0) {
                        for (let i = 0; i < transp.vol.length; i++) {
                          const vol = transp.vol[0];

                          // qVol
                          if (vol.qVol) {
                            if (vol.qVol.length > 0) {
                              qVol += Number(vol.qVol[0]);
                            }
                          }

                          // esp
                          /* if (vol.esp) {
                            if (vol.esp.length > 0) {
                              transpFomatado.esp = {
                                descricao: 'Espécie dos volumes transportados',
                                value: vol.esp[0],
                                valorFormatado: vol.esp[0],
                              };
                            }
                          } */

                          // marca
                          /* if (vol.marca) {
                            if (vol.marca.length > 0) {
                              faturaFomatada.marca = {
                                descricao: 'Marca dos volumes transportados',
                                value: vol.marca[0],
                                valorFormatado: vol.marca[0],
                              };
                            }
                          } */

                          // nVol
                          /* if (vol.nVol) {
                            if (vol.nVol.length > 0) {
                              faturaFomatada.nVol = {
                                descricao:
                                  'Numeração dos volumes transportados',
                                value: vol.nVol[0],
                                valorFormatado: vol.nVol[0],
                              };
                            }
                          } */

                          // pesoL
                          if (vol.pesoL) {
                            if (vol.pesoL.length > 0) {
                              pesoL += Number(vol.pesoL[0]);
                            }
                          }

                          // pesoB
                          if (vol.pesoB) {
                            if (vol.pesoB.length > 0) {
                              pesoB += Number(vol.pesoB[0]);
                            }
                          }
                        }
                      }

                      transpFomatado.qVol = {
                        descricao: 'Quantidade de volumes transportados',
                        value: qVol,
                        valorFormatado: qVol,
                      };

                      transpFomatado.pesoL = {
                        descricao: 'Peso Líquido (em kg)',
                        value: pesoL,
                        valorFormatado: pesoL,
                      };

                      transpFomatado.pesoB = {
                        descricao: 'Peso Bruto (em kg)',
                        value: pesoB,
                        valorFormatado: pesoB,
                      };
                    }

                    dadosFormatados.transp = transpFomatado;
                  }
                }

                //////////

                if (infNFe.cobr) {
                  if (infNFe.cobr.length > 0) {
                    const cobr = infNFe.cobr[0];

                    // fat
                    if (cobr.fat) {
                      if (cobr.fat.length > 0) {
                        const fat = cobr.fat[0];
                        const fatTemp = {};

                        // nFat
                        if (fat.nFat) {
                          if (fat.nFat.length > 0) {
                            /*  dadosFormatados.nFat = {
                              descricao: 'Número da Fatura',
                              value: fat.nFat[0],
                              valorFormatado: fat.nFat[0],
                            }; */

                            fatTemp.nFat = {
                              descricao: 'Número da Fatura',
                              value: fat.nFat[0],
                              valorFormatado: fat.nFat[0],
                            };
                          }
                        }

                        // vOrig
                        if (fat.vOrig) {
                          if (fat.vOrig.length > 0) {
                            /*  dadosFormatados.vOrig = {
                              descricao: 'Valor Original da Fatura',
                              value: Number(fat.vOrig[0]),
                              valorFormatado: formatMoeda(Number(fat.vOrig[0])),
                            };
 */
                            fatTemp.vOrig = {
                              descricao: 'Valor Original da Fatura',
                              value: Number(fat.vOrig[0]),
                              valorFormatado: formatMoeda(Number(fat.vOrig[0])),
                            };
                          }
                        }

                        // vDesc
                        if (fat.vDesc) {
                          if (fat.vDesc.length > 0) {
                            /* dadosFormatados.vDesc = {
                              descricao: 'Valor do desconto',
                              value: Number(fat.vDesc[0]),
                              valorFormatado: formatMoeda(Number(fat.vDesc[0])),
                            }; */

                            fatTemp.vDesc = {
                              descricao: 'Valor do desconto',
                              value: Number(fat.vDesc[0]),
                              valorFormatado: formatMoeda(Number(fat.vDesc[0])),
                            };
                          }
                        }

                        // vLiq
                        if (fat.vLiq) {
                          if (fat.vLiq.length > 0) {
                            /* dadosFormatados.vLiq = {
                              descricao: 'Valor Líquido da Fatura',
                              value: Number(fat.vLiq[0]),
                              valorFormatado: formatMoeda(Number(fat.vLiq[0])),
                            }; */

                            fatTemp.vLiq = {
                              descricao: 'Valor Líquido da Fatura',
                              value: Number(fat.vLiq[0]),
                              valorFormatado: formatMoeda(Number(fat.vLiq[0])),
                            };
                          }
                        }

                        dadosFormatados.fat = fatTemp;
                      }
                    }

                    // dup
                    if (cobr.dup) {
                      const dups = cobr.dup;

                      for (let i = 0; i < dups.length; i++) {
                        const dup = dups[i];
                        const dupFormatado = [];
                        // nDup
                        if (dup.nDup) {
                          if (dup.nDup.length > 0) {
                            dupFormatado.nDup = {
                              descricao: 'Número da Parcela',
                              value: dup.nDup[0],
                              valorFormatado: dup.nDup[0],
                            };
                          }
                        }

                        // dVenc
                        if (dup.dVenc) {
                          if (dup.dVenc.length > 0) {
                            dupFormatado.dVenc = {
                              descricao: 'Data de vencimento',
                              value: new Date(dup.dVenc[0]),
                              valorFormatado: formatDateDDMMYYYY(
                                new Date(dup.dVenc[0]),
                              ),
                            };
                          }
                        }

                        // vDup
                        if (dup.vDup) {
                          if (dup.vDup.length > 0) {
                            dupFormatado.vDup = {
                              descricao: 'Valor da Parcela',
                              value: Number(dup.vDup[0]),
                              valorFormatado: formatMoeda(Number(dup.vDup[0])),
                            };
                          }
                        }

                        dadosFormatados.parcelas.push(dupFormatado);
                      }
                    }
                  }
                }

                /////////

                if (infNFe.pag) {
                  if (infNFe.pag.length > 0) {
                    const pag = infNFe.pag[0];

                    // detPag
                    if (pag.detPag) {
                      for (let i = 0; i < pag.detPag.length; i++) {
                        const detPag = pag.detPag[i];
                        const detPagFormatado = {};

                        // indPag
                        if (detPag.indPag) {
                          if (detPag.indPag.length > 0) {
                            detPagFormatado.indPag = {
                              descricao: 'Indicador da Forma de Pagamento',
                              value: detPag.indPag[0],
                              valorFormatado:
                                codigosIndicadorFormaDePagamento[
                                  detPag.indPag[0]
                                ],
                            };
                          }
                        }

                        // tPag
                        if (detPag.tPag) {
                          if (detPag.tPag.length > 0) {
                            detPagFormatado.tPag = {
                              descricao: 'Meio de pagamento',
                              value: detPag.tPag[0],
                              valorFormatado:
                                codigosMeiosDePagamento[detPag.tPag[0]],
                            };
                          }
                        }

                        // vPag
                        if (detPag.vPag) {
                          if (detPag.vPag.length > 0) {
                            detPagFormatado.vPag = {
                              descricao: 'Valor do Pagamento',
                              value: Number(detPag.vPag[0]),
                              valorFormatado: formatMoeda(
                                Number(detPag.vPag[0]),
                              ),
                            };
                          }
                        }

                        dadosFormatados.pagamentos.push(detPagFormatado);
                      }
                    }
                  }
                }

                /////////////
              }
            }
          }
        }
      }
    }
    return dadosFormatados;
  }

  async function buscarXml(chaveDeAcesso) {
    try {
      const response = await api.get(`/get_xml_nfe_compra/${chaveDeAcesso}`);
      const data = response.data;
      if (!data.status) throw data.message;
      const result = await xml2js.parseStringPromise(data.data, {
        mergeAttrs: true,
      });
      const dados = getDadosUteisXml(result);
      return dados;
    } catch (e) {
      enqueueSnackbar(e ? e.toString() : '', {
        variant: 'error',
      });
      return null;
    }
  }

  function getDescontoTotalDescarrego(fornecedor, dadosXML) {
    const dados = {
      descontoTotalDescarrego: 0,
      pesoB: 0,
      valorFrete: 0,
    };

    if (fornecedor) {
      dados.valorFrete = fornecedor.frete_valor_por_tonelada;
    }

    if (dadosXML) {
      if (dadosXML.transp) {
        if (dadosXML.transp.pesoB) {
          dados.pesoB = dadosXML.transp.pesoB.value;
        }
      }
    }

    dados.descontoTotalDescarrego = dados.valorFrete * (dados.pesoB / 1000);

    return dados;
  }

  async function getDataCompra(idCompra) {
    try {
      setTela(TELA_PRE_INICIO_FRENTE_CAIXA);
      const data = await getListAPI(
        'compras',
        ['id', 'asc'],
        [1, 1],
        { id: [idCompra] },
        [
          'lotes.produto.unidade',
          'lotes.dadosPrecificacao',
          'parcelas.despesa.conta',
          'fornecedor.estado',
          'fornecedor.cidade.estado',
          'fornecedor.prazosFornecedores',
          'parcelas.despesa.fatura.cartao',
          'parcelas.despesa.problema',
          'lotesSimplificados',
          'bonificacao',
        ],
      );
      if (data.data.length <= 0) {
        throw 'Esta compra não existe!';
      }

      const config = await getConfig();

      if (data.data[0].tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA) {
        if (data.data[0].valor_bonificacao_usado_totalmente) {
          throw 'Esta Bonificação não pode ser editada, porque já foi usada em uma compra!';
        }
      }

      const dadosXml = data.data[0].chave_acesso_nota_fiscal
        ? await buscarXml(data.data[0].chave_acesso_nota_fiscal)
        : null;

      const {
        descontoTotalDescarrego,
        pesoB,
        valorFrete,
      } = getDescontoTotalDescarrego(data.data[0].fornecedor, dadosXml);

      let bonificacao_usada_id = null;
      let total_desconto_bonificacao = 0;

      if (data.data[0].bonificacao) {
        bonificacao_usada_id = data.data[0].bonificacao.id;
        total_desconto_bonificacao = data.data[0].bonificacao.valor;
      }

      dispatch({
        type: 'PREENCHER_TUDO',
        idEdit: data.data[0].id,
        lotes:
          data.data[0].tipo === TIPO_COMPRA_MERCADORIAS_REVENDA ||
          data.data[0].tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
            ? formatLotes(data.data[0].lotes)
            : formatLotesSimplificados(data.data[0].lotesSimplificados),
        parcelas: formatParcelasCompra(data.data[0].parcelas),
        fornecedor: data.data[0].fornecedor,
        numeroNotaFiscal: data.data[0].numero_nota_fiscal
          ? data.data[0].numero_nota_fiscal
          : '',
        data_faturamento: formatDate(data.data[0].data_faturamento),
        data_criacao: new Date(data.data[0].data),
        loja:
          data.data[0].loja === 'CF'
            ? {
                nome: 'CARUARU FRIOS',
                value: 'CF',
              }
            : {
                nome: 'CASA DA PIZZA',
                value: 'CP',
              },
        repasse_ipi: data.data[0].repasse_ipi,
        repasse_frete: data.data[0].repasse_frete,
        repasse_st: data.data[0].repasse_st,
        repasse_seguros: data.data[0].repasse_seguros,
        repasse_outras_despesas: data.data[0].repasse_outras_despesas,
        carregando: false,
        config,
        numeroCompra: data.data[0].numero,
        isProdutosSomenteFornecedor: true,
        isIncluiInativos: false,
        serieNotaFiscal: data.data[0].serie_nota_fiscal
          ? data.data[0].serie_nota_fiscal
          : '',
        chaveAcessoNotaFiscal: data.data[0].chave_acesso_nota_fiscal
          ? data.data[0].chave_acesso_nota_fiscal
          : '',
        isImportacaoXml: data.data[0].is_importacao_xml
          ? data.data[0].is_importacao_xml
          : false,
        tipo: data.data[0].tipo,
        uf: '',
        dadosXml,
        total_desconto_descarrego: descontoTotalDescarrego,
        total_desconto_bonificacao,
        bonificacao_usada_id,
      });
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      irParaTelaInit();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setTela(TELA_TIPO_COMPRA);
      setOpen(true);
    },
    handleEdit(idCompra) {
      getDataCompra(idCompra);
      setOpen(true);
    },
    handleOpenApartirDaNfeCompra(chave) {
      reiniciar();
      setTela(TELA_IMPORTACAO_XML_SEM_ARQUIVO);
      setChaveDeAcesso(chave);
      setOpen(true);
    },
  }));

  function getDadosTamTela() {
    switch (tela) {
      case TELA_PRE_INICIO_FRENTE_CAIXA:
        return {
          fullScreen: true,
        };
      case TELA_INICIO_FRENTE_CAIXA:
        return {
          fullScreen: true,
        };
      case TELA_FINALIZAR_FRENTE_CAIXA:
        return {
          fullScreen: true,
        };
      case TELA_TIPO_COMPRA:
        return {
          fullWidth: true,
          maxWidth: 'sm',
        };
      case TELA_IMPORTACAO_XML:
        return {
          fullScreen: true,
        };
      default:
        return {
          fullScreen: true,
        };
    }
  }

  const dadosTela = getDadosTamTela();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          {...dadosTela}
          /* scroll="body" */
        >
          {/* <DialogTitle
            id="form-dialog-title"
          >
            {`Nova Compra - ${tela === TELA_INICIO_FRENTE_CAIXA ? 'LOTES' : 'PARCELAS'}`}
          </DialogTitle> */}
          <DialogContent
            style={{
              padding: '0',
              margin: '0',
              // height: 'calc(100vh)',
              // backgroundColor: '#f5f5f5',
              /* background: 'url(https://source.unsplash.com/collection/2288687/1600x900)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover', */
              backgroundColor: '#ede8e8',
              backgroundImage: `url(${imagem})`,
              backgroundSize: 'cover',
            }}
            dividers
          >
            <CompraContext.Provider
              value={{ compra, dispatch, openMenu: () => {} }}
            >
              {!compra.carregando ? (
                <SwitchElements
                  value={tela}
                  elementos={{
                    [TELA_PRE_INICIO_FRENTE_CAIXA]: {
                      elemento: PreInicioFrenteCaixaCompra,
                      props: {
                        irParaTelaInit,
                        irParaTelaFrentedeCaixa,
                      },
                    },
                    [TELA_INICIO_FRENTE_CAIXA]: {
                      elemento: InicioFrenteCaixaCompra,
                      props: {
                        irParaTelaInit: irParaTelaPreFrentedeCaixa,
                        irParaTelaFinalizarCompra,
                        showAtendente: false,
                      },
                    },
                    [TELA_FINALIZAR_FRENTE_CAIXA]: {
                      elemento: FinalizarFrenteCaixaCompra,
                      props: {
                        irParaTelaInit,
                        irParaTelaFrentedeCaixa,
                        showAtendente: false,
                        editarModo: compra.idEdit > 0,
                      },
                    },
                    [TELA_TIPO_COMPRA]: {
                      elemento: TipoCompra,
                      props: {
                        cancelarCompra: irParaTelaInit,
                        irParaTelaFrentedeCaixa: irParaTelaPreFrentedeCaixa,
                        irParaTelaCarregamentoXml,
                      },
                    },
                    [TELA_IMPORTACAO_XML]: {
                      elemento: ImportacaoXML,
                      props: {
                        cancelarCompra: irParaTelaInit,
                        irParaTelaFrentedeCaixa: irParaTelaPreFrentedeCaixa,
                      },
                    },
                    [TELA_IMPORTACAO_XML_SEM_ARQUIVO]: {
                      elemento: ImportacaoXMLSemArquivo,
                      props: {
                        cancelarCompra: irParaTelaInit,
                        irParaTelaFrentedeCaixa: irParaTelaPreFrentedeCaixa,
                        chaveDeAcesso,
                      },
                    },
                  }}
                />
              ) : (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'red',
                    fontSize: '70px',
                    fontWeight: 'bold',
                  }}
                >
                  Carregando...
                </div>
              )}
            </CompraContext.Provider>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
});

export default DialogoCreateCompra;
