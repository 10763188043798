import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI } from '../../services';
import {
  writeCodigoBarrasCDM,
  formatTelefone,
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  SECONDARY_COLOR,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  POR_PESO_FIXO,
  TIPO_IMPRESSAO_UN,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  formatDateDDMMYYYYHHmm,
} from '../../utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DEFAULT_ID = -1;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = React.useState(0);

  const [dados, setDados] = useState({
    data: '-',
    valor: '-',
    peso: '-',
    valor_unitario: '-',
    pago: '-',
    sessao: '',
    caixa: '-',
    operador: '-',
    fornecedor: {
      nome: '-',
      razaoSocial: '-',
      nomeFantasia: '-',
    },
    nfes: [],
    parcelas: [],
    codigo: '-',
  });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function formatFornecedor(data) {
    let nome = '-';
    let razaoSocial = '-';
    let nomeFantasia = '-';

    if (data) {
      nome = data.nome ? data.nome : '-';
      razaoSocial = data.razao_social ? data.razao_social : '-';
      nomeFantasia = data.nome_fantasia ? data.nome_fantasia : '-';
    }

    return {
      nome,
      razaoSocial,
      nomeFantasia,
    };
  }

  function formatItens(data, valorUnitario) {
    const vetor = [];
    for (let i = 0; i < data.length; i++) {
      const total = data[i].peso * valorUnitario;
      vetor.push({
        id: data[i].id,
        peso: formatPeso(data[i].peso),
        chave: data[i].nfe.chave,
        data: formatDateDDMMYYYYHHmm(data[i].nfe.dhEmis),
        descarrego: formatMoeda(total),
        total: formatMoeda(data[i].nfe.vNF),
      });
    }
    return vetor;
  }

  function formatParcelas(data) {
    const vetor = [];
    for (let i = 0; i < data.length; i++) {
      const parcela = data[i];
      const tipoPagamento = data[i].tipoPagamento;

      vetor.push({
        id: parcela.id,
        meioPagamento: parcela?.tipoPagamento?.nome || '-',
        valor: formatMoeda(parcela.valor),
        dataPagamento: moment(new Date(parcela.data_pagamento)).format(
          'DD/MM/YYYY',
        ),
        valorRecebido:
          tipoPagamento.id === 1 ? formatMoeda(parcela.valor_recebido) : '-',
        troco:
          tipoPagamento.id === 1
            ? formatMoeda(parcela.valor_recebido - parcela.valor)
            : '-',
        conta: parcela?.receita?.conta?.nome || '-',
      });
    }
    return vetor;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'descarrego_mercadorias',
        ['id', 'asc'],
        { id: [id] },
        [
          'fornecedor.cidade.estado',
          'itens.nfe',
          'parcelas.receita.conta',
          'parcelas.tipoPagamento',
          'sessao.user',
          'sessao.caixa',
        ],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        //
        setDados({
          data: formatDateDDMMYYYYHHmm(new Date(data.data[0].data)),
          valor: formatMoeda(data.data[0].valor),
          peso: formatPeso(data.data[0].peso),
          valor_unitario: `${formatMoeda(data.data[0].valor_unitario)} / t`,
          pago: data.data[0].pago ? 'Pago' : 'Pendente',
          sessao: data.data[0]?.sessao?.id || '-',
          caixa: data.data[0]?.sessao?.caixa?.nome || '-',
          operador: data.data[0]?.sessao?.user?.username || '-',
          fornecedor: formatFornecedor(data.data[0].fornecedor),
          nfes: formatItens(data.data[0].itens, data.data[0].valor_unitario),
          parcelas: formatParcelas(data.data[0].parcelas),
          codigo: writeCodigoBarrasCDM(data.data[0].id),
        });
        //
        setValue(0);

        setCarregando(false);
      } else {
        throw 'Esta compra não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      getDataResource(id);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">{`Descarrego Mercadoria nº ${id}`}</DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Fornecedor" {...a11yProps(1)} />
                  <Tab label="Nfes" {...a11yProps(2)} />
                  <Tab label="Parcelas" {...a11yProps(3)} />
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Código:
                          </span>
                          {dados.codigo}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Data:
                          </span>
                          {dados.data}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Peso:
                          </span>
                          {dados.peso}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Valor:
                          </span>
                          {dados.valor}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Descarrego por Tonelada:
                          </span>
                          {dados.valor_unitario}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Status:
                          </span>
                          {dados.pago}
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Responsável:
                          </span>
                          {dados.fornecedor.nome}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Razão Social:
                          </span>
                          {dados.fornecedor.razaoSocial}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Nome Fantasia:
                          </span>
                          {dados.fornecedor.nomeFantasia}
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Data</StyledTableCell>
                              <StyledTableCell>Chave</StyledTableCell>
                              <StyledTableCell>Peso (Kg)</StyledTableCell>
                              <StyledTableCell>Descarrego</StyledTableCell>
                              <StyledTableCell>Total Nfe</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dados.nfes.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.data}</StyledTableCell>
                                <StyledTableCell>{row.chave}</StyledTableCell>
                                <StyledTableCell>{row.peso}</StyledTableCell>
                                <StyledTableCell>
                                  {row.descarrego}
                                </StyledTableCell>
                                <StyledTableCell>{row.total}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                Meio de Pagamento
                              </StyledTableCell>
                              <StyledTableCell>Valor</StyledTableCell>
                              <StyledTableCell>
                                Data de Pagamento
                              </StyledTableCell>
                              <StyledTableCell>Valor Recebido</StyledTableCell>
                              <StyledTableCell>Troco</StyledTableCell>
                              <StyledTableCell>Conta</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dados.parcelas.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {row.meioPagamento}
                                </StyledTableCell>
                                <StyledTableCell>{row.valor}</StyledTableCell>
                                <StyledTableCell>
                                  {row.dataPagamento}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.valorRecebido}
                                </StyledTableCell>
                                <StyledTableCell>{row.troco}</StyledTableCell>
                                <StyledTableCell>{row.conta}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Fechar
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
