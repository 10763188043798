import React, { useRef } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  DateField,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  FunctionField,
  RecordContext,
  useRefresh,
} from 'react-admin';

import { Card } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import Datagrid from '../../Components/Datagrid';
import EditButton from '../../Components/EditButton2';
import ListTemplate from '../../Components/List';
import DialogoCarregarNota from '../../layout/Pages/TesteLeituraXml/DialogoCarregarNota';
import { formatPreco } from '../../utils';
import Actions from '../Compras/ActionsList';
import Dialogo from '../Compras/ContainerDialogoCompra';
import DialogoDataFaturameto from '../Compras/DialogoDataFaturameto';
import DialogoDeletar from '../Compras/DialogoDeletar';
import DialogoIcms from '../Compras/DialogoIcms';
import DialogoShow from '../Compras/DialogoShow';
import StatusField from '../Compras/Fields/StatusField2';
import DialogoPdf from '../NfeCompras/DialogoPdf';
import ActionsField from './ActionsField';
import Filter from './Filter';

const PostPanel = (props) => (
  <Show {...props} title="." actions={false}>
    <SimpleShowLayout>
      <TextField source="serie_nota_fiscal" />
      <TextField source="chave_acesso_nota_fiscal" />
      <TextField source="numero_nota_fiscal" />
      <TextField source="nome" />
      <ReferenceManyField
        addLabel={false}
        reference="parcelascompra"
        target="compra_id"
      >
        <Datagrid isBlue>
          <StatusField
            source="despesa.status"
            label="Status"
            sortable={false}
          />
          <DateField source="data_pagamento" label="Data de pagamento" />
          <ReferenceField
            label="Conta"
            source="despesa.conta_id"
            reference="contas"
            sortable={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <ReferenceField
            label="Categoria Problema"
            source="despesa.categoria_problema_despesa_id"
            reference="categorias-problemas-despesas"
            sortable={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <NumberField
            source="valor"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Valor"
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDeleteResource = useRef(null);
  const refDialogoIcms = useRef(null);
  const refDialogoDataFaturamento = useRef(null);
  const refDialogoShowCompra = useRef(null);
  const refDialogoCarregarNota = useRef(null);
  const refDialogoPdf = useRef(null);

  const refresh = useRefresh();

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  function deleteHundle({ id }) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(id);
  }

  function handleImprimir(record) {}

  function handleShow(record) {
    if (refDialogoShowCompra.current) {
      refDialogoShowCompra.current.handleOpen(record.id);
    }
  }

  function handleCarregarXml() {
    if (refDialogoCarregarNota.current) {
      refDialogoCarregarNota.current.handleOpen();
    }
  }

  function handleClose() {
    refresh();
  }

  function handlePDF(dados) {
    if (dados.chave_acesso_nota_fiscal) {
      if (refDialogoPdf.current) {
        refDialogoPdf.current.handleOpen(dados.chave_acesso_nota_fiscal);
      }
    }
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={
          <Actions
            handleCriar={handleCriar}
            handleCarregarXml={handleCarregarXml}
            {...props}
          />
        }
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="id" label="Nº compra" />
          <ReferenceField
            label="Fornecedor"
            source="fornecedor_id"
            reference="fornecedores"
          >
            <TextField source="nome" />
          </ReferenceField>
          <NumberField
            source="valor"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Valor Total"
          />

          <DateField source="data" label="Data da Compra" />
          <TextField source="numero_nota_fiscal" label="Número nota fiscal" />

          <FunctionField
            label="Faturamento"
            render={(record) => {
              if (record.data_faturamento) {
                const data = new Date(record.data_faturamento);
                return `${
                  data.getDate() < 10 ? `0${data.getDate()}` : data.getDate()
                }/${
                  data.getMonth() + 1 < 10
                    ? `0${data.getMonth() + 1}`
                    : data.getMonth() + 1
                }/${data.getFullYear()}`;
              }
              return '-';
            }}
          />

          <ActionsField
            source="lalala"
            handleEdit={handleEdit}
            handleShow={handleShow}
            handleDelete={deleteHundle}
            handleImprimir={handleImprimir}
            textAlign="right"
            label="Ações"
            sortable={false}
            handlePdf={handlePDF}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} />
      <DialogoDeletar
        ref={refDialogoDeleteResource}
        handleClose={handleClose}
      />
      <DialogoIcms ref={refDialogoIcms} handleClose={handleClose} />
      <DialogoDataFaturameto
        ref={refDialogoDataFaturamento}
        handleClose={handleClose}
      />
      <DialogoShow ref={refDialogoShowCompra} handleClose={() => {}} />
      <DialogoCarregarNota
        ref={refDialogoCarregarNota}
        handleClose={() => {}}
      />
      <DialogoPdf ref={refDialogoPdf} handleClose={() => {}} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Compras de Serviços"
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
