import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { useSnackbar } from 'notistack';
import xml2js from 'xml2js';

import {
  TextInput,
  SelectInput,
  CpfInput,
  CnpjInput,
  AutoCompleteRemoto,
  TelefoneInput,
  PesoInput,
} from '../../Components';
import { api } from '../../services';
import {
  formatCPF,
  formatCNPJ,
  PIX_ALEATORIA_OPTION,
  PIX_CNPJ_OPTION,
  PIX_CPF_OPTION,
  PIX_EMAIL_OPTION,
  PIX_TELEFONE_OPTION,
  formatTelefone,
  formatMoeda,
  formatDateDDMMYYYYHHmm,
  createQueryURL,
  SECONDARY_COLOR,
} from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
}))(TableCell);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
/*
const GenericoInput = forwardRef(({ formato, ...props }, ref) => (
  <TemplateNumberFormatInput {...props} formato={formato} ref={ref} />
)); */

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const DialogoCategoriaTransacao = forwardRef(
  ({ handleSave, fornecedor, nfes }, ref) => {
    const [open, setOpen] = useState(false);
    const [nfeCompraOptions, setNfeCompraOptions] = useState([]);
    const [nfeSelecionada, setNfeSelecionada] = useState(-1);
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles({ cor: SECONDARY_COLOR });

    const processando = useRef(false);

    const handleCloseDialog = () => {
      setOpen(false);
    };

    async function handleSalvar() {
      if (!processando.current) {
        processando.current = true;
        const { status, message, data } = await montarItem();
        if (status) {
          handleSave([data]);
          handleCloseDialog();
        } else {
          enqueueSnackbar(`${message}`, {
            variant: 'error',
          });
        }
      }
    }

    function getDadosUteisXml(dados) {
      if (dados) {
        const nfeProc = dados.nfeProc;
        if (nfeProc) {
          if (nfeProc.NFe) {
            if (nfeProc.NFe.length > 0) {
              const NFe = nfeProc.NFe[0];
              if (NFe.infNFe) {
                if (NFe.infNFe.length > 0) {
                  const infNFe = NFe.infNFe[0];

                  if (infNFe.transp) {
                    if (infNFe.transp.length > 0) {
                      const transp = infNFe.transp[0];

                      if (transp.vol) {
                        let qVol = 0;
                        let pesoL = 0;
                        let pesoB = 0;
                        if (transp.vol.length > 0) {
                          for (let i = 0; i < transp.vol.length; i++) {
                            const vol = transp.vol[0];

                            // qVol
                            if (vol.qVol) {
                              if (vol.qVol.length > 0) {
                                qVol += Number(vol.qVol[0]);
                              }
                            }

                            // pesoL
                            if (vol.pesoL) {
                              if (vol.pesoL.length > 0) {
                                pesoL += Number(vol.pesoL[0]);
                              }
                            }

                            // pesoB
                            if (vol.pesoB) {
                              if (vol.pesoB.length > 0) {
                                pesoB += Number(vol.pesoB[0]);
                              }
                            }
                          }
                        }

                        return {
                          qVol,
                          pesoL,
                          pesoB,
                        };
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return null;
    }

    async function montarItem() {
      if (nfeSelecionada === -1) {
        return {
          data: null,
          status: false,
          message: '',
        };
      }
      const nfe = nfeCompraOptions.find((item) => item.id === nfeSelecionada);
      if (nfe) {
        const peso = await buscaXml(nfe.chave);
        const dados = {
          peso,
          chave: nfe.chave,
          data: new Date(nfe.dhEmis),
          nfe_compra_id: nfe.id,
          vNF: nfe.vNF,
        };
        return {
          data: dados,
          status: true,
          message: '',
        };
      }
      return {
        data: null,
        status: false,
        message: '',
      };
    }

    async function buscaXml(chaveDeAcesso) {
      const response = await api.get(`/get_xml_nfe_compra/${chaveDeAcesso}`);
      const data = response.data;
      if (!data.status) throw data.message;
      const result = await xml2js.parseStringPromise(data.data, {
        mergeAttrs: true,
      });
      const dados = getDadosUteisXml(result);
      if (dados) {
        return dados.pesoB;
      }
      return 0;
    }

    function getIdsExcluso() {
      const ids = [];
      for (let i = 0; i < nfes.length; i++) {
        ids.push(nfes[i].nfe_compra_id);
      }
      return ids;
    }

    async function getNfeComprasOptions() {
      const response = await api.get(
        `/nfes-descarrego/${fornecedor.cnpj}?${createQueryURL(
          null,
          null,
          {
            ids_excluso: getIdsExcluso(),
          },
          null,
        )}`,
      );
      const { nfes } = response.data;

      return nfes;
    }

    async function reiniciar() {
      const nfes = await getNfeComprasOptions();
      setNfeCompraOptions(nfes);
      setNfeSelecionada(-1);
      processando.current = false;
      setOpen(true);
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        reiniciar();
      },
    }));

    function validateNfeCompras() {
      let error = '';
      if (nfeSelecionada === -1) {
        error = 'Este campo é obrigatório!';
      }
      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateNfeCompras();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: SECONDARY_COLOR, color: 'white' }}
          >
            Adicionar NFE ao descarrego
          </DialogTitle>
          <DialogContent
            style={{
              padding: '10px 36px 20px',
              margin: '0',
            }}
          >
            <div>
              <Box flex={1} mr="0.5em" mt="20px">
                <Table
                  aria-label="customized table"
                  style={{
                    width: '100%',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      />
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        Data
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        Chave
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        Total Nfe
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {nfeCompraOptions.map((item) => (
                      <TableRow key={item.id}>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {item.id === nfeSelecionada ? (
                            <Avatar className={classes.confirmado}>
                              <CheckIcon style={{ fontSize: 15 }} />
                            </Avatar>
                          ) : (
                            <Avatar
                              className={classes.pendente}
                              onClick={() => setNfeSelecionada(item.id)}
                            >
                              <PriorityHighIcon style={{ fontSize: 15 }} />
                            </Avatar>
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {formatDateDDMMYYYYHHmm(new Date(item.dhEmis))}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {item.chave}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                          }}
                        >
                          {formatMoeda(item.vNF)}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
