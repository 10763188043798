import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DetailsIcon from '@material-ui/icons/Details';
import GroupIcon from '@material-ui/icons/Group';

import { SwitchInput } from '../../../..';
import {
  formatMoeda,
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  TIPO_COMPRA_INSUMOS_UTILIZACAO,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
  TIPO_COMPRA_SERVICOS_PRESTADO_A_EMPRESA,
} from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
  },
  sectionDesktop: {
    display: 'flex',
    gap: '10px',
  },
}));

function getCor(tipo) {
  switch (tipo) {
    case TIPO_COMPRA_MERCADORIAS_REVENDA:
      return {};
    case TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA:
      return { backgroundColor: '#11e6186d' };
    case TIPO_COMPRA_INSUMOS_UTILIZACAO:
      return { backgroundColor: '#abb0ab6d' };
    case TIPO_COMPRA_SERVICOS_PRESTADO_A_EMPRESA:
      return { backgroundColor: '#cbce106d' };
    default:
      return {};
  }
}

function getLabel(tipo) {
  switch (tipo) {
    case TIPO_COMPRA_MERCADORIAS_REVENDA:
      return 'COMPRA DE MERCADORIAS PARA REVENDA';
    case TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA:
      return 'BONIFICAÇÃO DE MERCADORIAS PARA REVENDA';
    case TIPO_COMPRA_INSUMOS_UTILIZACAO:
      return 'COMPRA DE INSUMOS PARA UTILIZAÇÃO';
    case TIPO_COMPRA_SERVICOS_PRESTADO_A_EMPRESA:
      return 'SERVIÇOS PRESTADOS A NOSSA EMPRESA';
    default:
      return {};
  }
}

export default function AppBarNaturezaCompra({
  tipo,
  subTotalPresumido,
  showValoresReais = false,
  handleShowValoresReais = () => {},
  visibleSubTotalPresumido = true,
  visibleInput = true,
}) {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ ...getCor(tipo) }}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap />
          <div className={classes.grow}>
            <div>
              <span
                style={{
                  margin: '0 10px',
                  fontWeight: 'bold',
                }}
              >
                {getLabel(tipo)}
              </span>
              {visibleSubTotalPresumido ? (
                <>
                  <span
                    style={{
                      margin: '0 10px',
                    }}
                  >
                    |
                  </span>
                  <span
                    style={{
                      margin: '0 10px',
                      fontWeight: 'bold',
                    }}
                  >
                    SubTotal Real:
                  </span>
                  <span
                    style={{
                      margin: '0 10px',
                    }}
                  >
                    {formatMoeda(subTotalPresumido)}
                  </span>
                </>
              ) : null}
              {visibleInput ? (
                <>
                  <span
                    style={{
                      margin: '0 10px',
                    }}
                  >
                    |
                  </span>
                  <SwitchInput
                    name="mostrar_totais_reais"
                    handleEnter={() => {}}
                    label="Mostrar Totais Reais"
                    handleKey={() => {}}
                    checked={showValoresReais}
                    onChange={(value) =>
                      handleShowValoresReais(value.target.checked)
                    }
                    fullWidth
                  />
                </>
              ) : null}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
