import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';

const ActionsField = ({
  handleEdit,
  handleShow,
  handleDelete,
  handleImprimir,
  ...rest
}) => {
  const record = useRecordContext(rest);

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEdit(record.record)}
          disabled
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
        <IconButton
          aria-label="excluir"
          size="small"
          onClick={() => handleDelete(record.record)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="imprimir"
          size="small"
          onClick={() => handleImprimir(record.record)}
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
