import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box, requirePropFactory } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  IntegerInput,
  SearchProduto,
  PesoInput,
  AutoCompleteRemoto,
  DialogoConfirmacao,
} from '../../Components';
import {
  getListAllAPI,
  api,
  getUserId,
  createAPI,
  updateAPI,
} from '../../services';
import {
  writeCodigoBarrasCGP,
  formatPeso,
  round10,
  STATUS_ATIVO,
  SECONDARY_COLOR,
  formatMoeda,
  formatDateDDMMYYYYHHmm,
} from '../../utils';
import DialogoDeletarNfeCompra from './DialogoDeletarNfeCompra';
import DialogoNfeCompra from './DialogoNfeCompra';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState(-1);
    const [carregando, setCarregando] = useState(false);
    const [fornecedor, setFornecedor] = useState(null);
    const [nfesCompras, setNfesCompras] = useState([]);
    const [valorUnitario, setValorUnitario] = useState(0);

    const refDialogoPesagem = useRef(null);
    const refDialogoDeletarPesagem = useRef(null);

    const refDialogoConfirmacao = useRef(null);

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    const handleCloseDialogLocal = () => {
      setOpen(false);
      handleClose();
    };

    async function reiniciar() {
      setId(-1);
      setCarregando(false);
      setFornecedor(null);
      setCarregando(false);
      setNfesCompras([]);
      setValorUnitario(0);
    }

    function formatNfesCompras(itens) {
      const itensFormatados = [];
      for (let i = 0; i < itens.length; i++) {
        itensFormatados.push({
          peso: itens[i].peso,
          chave: itens[i].nfe.chave,
          data: new Date(itens[i].nfe.dhEmis),
          nfe_compra_id: itens[i].nfe.id,
          vNF: itens[i].nfe.vNF,
        });
      }
      return itensFormatados;
    }

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'descarrego_mercadorias',
          ['id', 'asc'],
          { id: [id] },
          ['fornecedor', 'itens.nfe'],
        );
        if (data.data.length > 0) {
          if (data.data[0].pago)
            throw 'Este Descarrego de Mercadoria não pode ser editado, pois já foi pago!!!';
          setFornecedor(data.data[0].fornecedor);
          setCarregando(false);
          setNfesCompras(formatNfesCompras(data.data[0].itens));
          setValorUnitario(data.data[0].valor_unitario);
        } else {
          throw 'Este Descarrego de Mercadoria não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      if (nfesCompras.length > 0) {
        if (valorUnitario > 0) {
          setCarregando(true);
          try {
            if (id <= 0) {
              await createAPI('descarrego_mercadorias', {
                data: new Date(),
                valor_unitario: valorUnitario,
                valor: valorTotal,
                peso: pesoTotal,
                itens: nfesCompras,
                fornecedor_id: fornecedor.id,
              });
              setCarregando(false);
              enqueueSnackbar(
                'Conferência de Grandes Pesagens criada com sucesso!',
                {
                  variant: 'success',
                },
              );
            } else {
              await updateAPI('descarrego_mercadorias', id, {
                data: new Date(),
                valor_unitario: valorUnitario,
                valor: valorTotal,
                peso: pesoTotal,
                itens: nfesCompras,
                fornecedor_id: fornecedor.id,
              });
              setCarregando(false);
              enqueueSnackbar(
                'Conferência de Grandes Pesagens atualizada com sucesso!',
                {
                  variant: 'success',
                },
              );
            }

            handleCloseDialog();
          } catch (erros) {
            enqueueSnackbar(`${erros}`, {
              variant: 'error',
            });
            handleCloseDialog();
          }
        } else {
          enqueueSnackbar(
            `O Valor do Descarrego por Tonelada não pode ser nulo!!!`,
            {
              variant: 'error',
            },
          );
        }
      } else {
        enqueueSnackbar(`Deve haver ao menos uma Nfe de Compra!!!`, {
          variant: 'error',
        });
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        reiniciar();
        setOpen(true);
      },
      handleEdit(id) {
        setCarregando(true);
        getDataResource(id);
        setId(id);
        setOpen(true);
      },
    }));

    function validateFornecedor() {
      let error = '';
      if (!fornecedor) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateNfesCompras() {
      let error = '';
      if (nfesCompras.length === 0) {
        error = 'É necessário pelo menos uma Nfe de compra';
      }
      return error;
    }

    function validateValorUnitario() {
      let error = '';
      if (valorUnitario < 0 || Number.isNaN(valorUnitario)) {
        error = 'Valor inválido';
      } else if (valorUnitario === 0) {
        error = 'O Valor do Descarrego por Tonelada não pode ser nulo!!!';
      }
      return error;
    }

    function getErros() {
      const errosOld = ['', '', ''];
      errosOld[0] = validateFornecedor();
      errosOld[1] = validateNfesCompras();
      errosOld[2] = validateValorUnitario();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function adicionarNfeCompra() {
      if (fornecedor) {
        if (refDialogoPesagem.current) {
          refDialogoPesagem.current.handleCreate();
        }
      } else {
        enqueueSnackbar(
          `Para Adicionar uma NFe de Compra é necessário especificar o Fornecedor!!!`,
          {
            variant: 'error',
          },
        );
      }
    }

    function removerNfeCompra(index, row) {
      if (refDialogoDeletarPesagem.current) {
        refDialogoDeletarPesagem.current.handleOpen(
          index,
          row.data,
          row.chave,
          row.peso,
          row.vNF,
          row.total,
        );
      }
    }

    function calculaTotais(itens, valorUnitario) {
      const itensFormatados = [];
      let pesoTotal = 0;
      let valorTotal = 0;
      for (let i = 0; i < itens.length; i++) {
        const total = itens[i].peso * (valorUnitario / 1000);
        itensFormatados.push({
          peso: formatPeso(itens[i].peso),
          chave: itens[i].chave,
          data: formatDateDDMMYYYYHHmm(itens[i].data),
          total: formatMoeda(total),
          vNF: formatMoeda(itens[i].vNF),
        });
        pesoTotal += itens[i].peso;
        valorTotal += total;
      }
      return {
        itensFormatados,
        pesoTotal,
        valorTotal,
      };
    }

    const { itensFormatados, pesoTotal, valorTotal } = calculaTotais(
      nfesCompras,
      valorUnitario,
    );

    function efeitoMundacaFornecedor(value) {
      const valorAntigo = fornecedor;
      if (value) {
        if (valorAntigo) {
          openConfirmacao(value);
        } else {
          setFornecedor(value);
          setValorUnitario(value.frete_valor_por_tonelada);
        }
      } else if (valorAntigo) {
        openConfirmacao(value);
      } else {
        setFornecedor(null);
        setValorUnitario(0);
      }
    }

    function openConfirmacao(value) {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Alteração do Fornecedor',
          'Tem certeza de que deseja alterar o Fornecedor ? Ao alterar o Fornecedor todas as NFes serão removedas deste descarrego!',
          value,
        );
      }
    }

    function handleConfirma(value) {
      if (value) {
        setFornecedor(value);
        setValorUnitario(value.frete_valor_por_tonelada);
      } else {
        setFornecedor(null);
        setValorUnitario(0);
      }
    }

    function ordenarNfes(arr) {
      arr.sort((a, b) => b.data.getTime() - a.data.getTime());
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="lg"
          //fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialogLocal}
            style={{
              backgroundColor: SECONDARY_COLOR,
              color: 'white',
            }}
          >
            {id > 0
              ? 'Editar Descarrego de Mercadoria'
              : 'Novo Descarrego de Mercadoria'}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Box display="flex" alignItems="center" flex={1}>
                  <AutoCompleteRemoto
                    id="cliente"
                    name="cliente"
                    label="Responsável"
                    value={fornecedor}
                    onChange={(value) => {
                      efeitoMundacaFornecedor(value);
                    }}
                    handleKey={(keyCode, keyName) => {}}
                    handleEnter={handleSalvar}
                    resource="fornecedores"
                    nested={['cidade.estado']}
                    toString={false}
                    fullwidth
                    autoFocus
                    filters={{ status: STATUS_ATIVO, ids_excluso: [1, 2] }}
                  />
                  <AutoCompleteRemoto
                    id="cliente"
                    name="cliente"
                    label="Nome Fantasia"
                    value={fornecedor}
                    onChange={(value) => {
                      efeitoMundacaFornecedor(value);
                    }}
                    handleKey={(keyCode, keyName) => {}}
                    handleEnter={handleSalvar}
                    resource="fornecedores"
                    campo="nome_fantasia"
                    nested={['cidade.estado']}
                    toString={false}
                    style={{ marginLeft: '10px' }}
                    fullwidth
                    filters={{ status: STATUS_ATIVO, ids_excluso: [1, 2] }}
                  />
                </Box>
                {fornecedor ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Fornecedor:
                        </span>
                        <span>{`${fornecedor.nome}`}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Valor do Descarrego por Tonelada:
                        </span>
                        <span>{`${formatMoeda(valorUnitario)}`}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Peso Bruto Total:
                        </span>
                        <span>{`${formatPeso(pesoTotal)}`}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '10px',
                          }}
                        >
                          Valor Total:
                        </span>
                        <span>{`${formatMoeda(valorTotal)}`}</span>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '10px 0',
                        }}
                      >
                        <h1>Nfes de Compra</h1>
                        <div>
                          <Button
                            onClick={adicionarNfeCompra}
                            variant="contained"
                            tabindex="-1"
                            style={{
                              backgroundColor: SECONDARY_COLOR,
                              color: 'white',
                            }}
                          >
                            Adicionar
                          </Button>
                        </div>
                      </div>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Data</StyledTableCell>
                              <StyledTableCell>Chave</StyledTableCell>
                              <StyledTableCell>Peso</StyledTableCell>
                              <StyledTableCell>Valor da Nfe</StyledTableCell>
                              <StyledTableCell>
                                Total Descarrego
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {itensFormatados.map((row, ind) => (
                              <StyledTableRow key={`${ind}-row`}>
                                <StyledTableCell>{row.data}</StyledTableCell>
                                <StyledTableCell>{row.chave}</StyledTableCell>
                                <StyledTableCell>{row.peso}</StyledTableCell>
                                <StyledTableCell>{row.vNF}</StyledTableCell>
                                <StyledTableCell>{row.total}</StyledTableCell>
                                <StyledTableCell align="right">
                                  <Tooltip
                                    title="Remover"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() => removerNfeCompra(ind, row)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialogLocal}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
              //color="primary"
              style={
                {
                  /*  backgroundColor: SECONDARY_COLOR,
                color: 'white', */
                }
              }
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  variant="contained"
                  //color="primary"
                  color="primary"
                  disabled={carregando || erroExistente}
                  style={
                    {
                      /*  backgroundColor: SECONDARY_COLOR,
                    color: 'white', */
                    }
                  }
                >
                  Salvar
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
        </Dialog>
        <DialogoNfeCompra
          ref={refDialogoPesagem}
          handleSave={(dados) => {
            const copia = [...nfesCompras];
            copia.push(...dados);
            ordenarNfes(copia);
            setNfesCompras(copia);
          }}
          fornecedor={fornecedor}
          nfes={nfesCompras}
        />
        <DialogoDeletarNfeCompra
          ref={refDialogoDeletarPesagem}
          handleDelete={(id) => {
            setNfesCompras(nfesCompras.filter((item, index) => index !== id));
          }}
        />
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
