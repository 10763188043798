import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

import { formatPeso } from '../../utils';

const PesoField = (props) => {
  const record = useRecordContext(props);
  const valor = record
    ? record.record
      ? `${formatPeso(record.record.peso)}`
      : '-'
    : '-';

  return <Typography variant="subtitle1">{valor}</Typography>;
};

export default PesoField;
