import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  IntegerInput,
  PesoInput,
  PrecoInput,
  DialogoProduto2 as DialogoProduto,
  PorcentagemInput,
  DateInput,
  SelectInput,
  SwitchInput,
} from '../../../..';
import {
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getTotalCompra,
  POR_PESO_FIXO,
  formatMoeda,
  SECONDARY_COLOR,
} from '../../../../../utils';
import AppBarNaturezaCompra from '../PreInicioFrenteCaixaCompra/AppBarNaturezaCompra';
import DialogoPrecificacaoNova from './DialogoPrecificacaoNova';

//////////////////////////////////////

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    //width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '7px',
    border: '1px solid black',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    width: 'min-content',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '7px',
    border: '1px solid black',
    width: 'min-content',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
    padding: 3,
  },
  body: {
    fontSize: 14,
    padding: 3,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

///////////////////////////////////////

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function verificaDiferencaNula(v1, v2, config = {}) {
  const configDefault = { diferencaAlvo: 0, tolerancia: 0.01 };

  const configFinal = { ...configDefault, ...config };

  const { diferencaAlvo, tolerancia } = configFinal;

  const diferencaCalculada = Math.abs(v1 - v2);
  if (
    diferencaCalculada >= diferencaAlvo - tolerancia &&
    diferencaCalculada <= diferencaAlvo + tolerancia
  )
    return true;
  return false;
}

function formataDados(dados) {
  const { index, produto: produto2, ...dados2 } = dados;

  let unidades = 0;
  let peso = 0;

  if (produto2.unidade.tipo === POR_PESO_FIXO) {
    unidades = dados.qtde;
    peso = unidades * produto2.peso_medio;
  } else if (produto2.unidade.tipo === POR_UNIDADE) {
    unidades = dados.qtde;
    peso = 0;
  } else {
    unidades = 0;
    peso = dados.qtde;
  }

  const dadosFormatados = {
    produto: produto2,
    peso: produto2.unidade.tipo === POR_UNIDADE ? 0 : peso,
    total: getTotalCompra(
      peso,
      unidades,
      dados2.preco_compra_impostos,
      produto2,
    ),
    unidades:
      produto2.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE ? 0 : unidades,
    unitario: dados2.preco_compra,
    uidd: `${produto2.nome}${index}`,
    idIndicadorChange: -1,
    validade: dados2.validade,
    unitarioImposto: dados2.preco_compra_impostos,
    cg: dados2.cg,
    dadosPrecificacao: dados2.dadosPrecificacao,
    nImpressoes: dados2.nImpressoes,
    index,
    validades: dados2.validades,
    unitarioPresumido: dados2.preco_compra_presumido,
    unitarioImpostoPresumido: dados2.preco_compra_imposto_presumido,
    totalPresumido: getTotalCompra(
      peso,
      unidades,
      dados2.preco_compra_presumido,
      produto2,
    ),
  };

  return dadosFormatados;
}

const Container = forwardRef(
  (
    {
      handleClose,
      estoque,
      hasRepasseNaoUnitarioIPI = false,
      hasRepasseNaoUnitarioFrete = false,
      hasRepasseNaoUnitarioST = false,
      hasRepasseNaoUnitarioSeguros = false,
      hasRepasseNaoUnitarioOutrasDespesas = false,
      label1 = 'Cancelar',
      label2 = 'Salvar',
      prefixoTitle = '',
      isCloseX = true,
      isEmitMessage = true,
      handleUpdateProduto = null,
      tipo,
      passadorLotes = false,
      handleClose2 = () => {},
      getErrosAdicionais = () => [''],
      getInformacoesAdicionais = () => [''],
    },
    ref,
  ) => {
    const [open, setOpen] = React.useState(false);
    const refDialogoPrecificacaoNova = useRef(null);
    const [
      valorNegociadoComAIndustriaPresumido,
      setValorNegociadoComAIndustriaPresumido,
    ] = React.useState(0);

    const [
      isIncluirValorPresumido,
      setIsIncluirValorPresumido,
    ] = React.useState(false);

    const [isIncluirPrecificacao, setIsIncluirPrecificacao] = React.useState(
      false,
    );

    const [dadosSalvos, setDadosSalvos] = React.useState(null);

    const refDadosTemp = useRef(null);

    const refDadosTempProduto = useRef(null);

    const refTipoMetodo = useRef(0);

    const classes = useStyles();

    const [validades, setValidades] = useState([]);
    const [validade, setValidade] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const handleCloseDialog = (dadosRetorno = null) => {
      setOpen(false);
      handleClose(dadosRetorno);
    };

    const handleCloseDialog2 = (valor1, valor2, index) => {
      setOpen(false);
      handleClose2(valor1, valor2, index);
    };

    useImperativeHandle(ref, () => ({
      /*  handleOpen(codigo, config, fornecedor, id) {
        refDadosTemp.current = {
          codigo,
          config,
          fornecedor,
          id,
        };
        if (refDialogoPrecificacaoNova.current) {
          refDialogoPrecificacaoNova.current.handleOpen(
            refDadosTemp.current.codigo,
            refDadosTemp.current.config,
            refDadosTemp.current.fornecedor,
            refDadosTemp.current.id,
          );
        }
      }, */
      handleEdit(
        {
          produtoId,
          config,
          fornecedor,
          validade,
          nImpressoes,
          valorNegociadoComAIndustria,
          quantidadeComprada,
          repasseDoIpi,
          repasseDoFreteUnitario,
          repasseDeSt,
          repasseDeSeguros,
          repasseDeOutrasDespesas,
          index,
          descontoNfe = 0,
          descontoBoleto = 0,
          descontoBonificacao = 0,
          descontoBacklight = 0,
          descontoJornalOfertas = 0,
          descontoDescargoMercadorias = 0,
          dadosComplementaresNota = null,
          validades = [],
        },
        produto,
        dadosFormatados,
      ) {
        refDadosTemp.current = {
          produtoId,
          config,
          fornecedor,
          validade,
          nImpressoes,
          valorNegociadoComAIndustria,
          quantidadeComprada,
          repasseDoIpi,
          repasseDoFreteUnitario,
          repasseDeSt,
          repasseDeSeguros,
          repasseDeOutrasDespesas,
          index,
          descontoNfe,
          descontoBoleto,
          descontoBonificacao,
          descontoBacklight,
          descontoJornalOfertas,
          descontoDescargoMercadorias,
          dadosComplementaresNota,
          validades,
        };
        refDadosTempProduto.current = produto;
        refTipoMetodo.current = 0;
        if (tipo !== TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA) {
          if (refDialogoPrecificacaoNova.current) {
            refDialogoPrecificacaoNova.current.handleEdit(
              refDadosTemp.current.produtoId,
              refDadosTemp.current.config,
              refDadosTemp.current.fornecedor,
              refDadosTemp.current.validade,
              refDadosTemp.current.nImpressoes,
              refDadosTemp.current.valorNegociadoComAIndustria,
              refDadosTemp.current.quantidadeComprada,
              refDadosTemp.current.repasseDoIpi,
              refDadosTemp.current.repasseDoFreteUnitario,
              refDadosTemp.current.repasseDeSt,
              refDadosTemp.current.repasseDeSeguros,
              refDadosTemp.current.repasseDeOutrasDespesas,
              refDadosTemp.current.index,
              refDadosTemp.current.descontoNfe,
              refDadosTemp.current.descontoBoleto,
              refDadosTemp.current.descontoBonificacao,
              refDadosTemp.current.descontoBacklight,
              refDadosTemp.current.descontoJornalOfertas,
              refDadosTemp.current.descontoDescargoMercadorias,
              refDadosTemp.current.dadosComplementaresNota,
              refDadosTemp.current.validades,
            );
          }
        } else {
          setOpen(true);
          if (dadosFormatados) {
            const valorNegociadoComAIndustria = dadosFormatados.unitario;
            const valorNegociadoComAIndustriaPresumido =
              dadosFormatados.unitarioPresumido;

            const isValorNulo = verificaDiferencaNula(
              0,
              valorNegociadoComAIndustriaPresumido,
              {
                tolerancia: 0.001,
              },
            );

            if (isValorNulo) {
              setValorNegociadoComAIndustriaPresumido(0);
              setIsIncluirValorPresumido(false);
            } else {
              const isIncluirValorPresumido = verificaDiferencaNula(
                valorNegociadoComAIndustria,
                valorNegociadoComAIndustriaPresumido,
                {
                  tolerancia: 0.001,
                },
              );

              if (!isIncluirValorPresumido) {
                setValorNegociadoComAIndustriaPresumido(
                  valorNegociadoComAIndustriaPresumido,
                );
                setIsIncluirValorPresumido(true);
              }
            }

            if (dadosFormatados.dadosPrecificacao) {
              setIsIncluirPrecificacao(true);
              setDadosSalvos(dadosFormatados);
            } else {
              setIsIncluirPrecificacao(false);
              setDadosSalvos(null);
            }
          } else {
            setValorNegociadoComAIndustriaPresumido(0);
            setIsIncluirValorPresumido(false);
            setIsIncluirPrecificacao(false);
            setDadosSalvos(null);
          }
        }
      },
      handleEditarDiferente(props, produto, dadosFormatados) {
        refDadosTemp.current = props;
        refDadosTempProduto.current = produto;
        refTipoMetodo.current = 1;

        if (tipo !== TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA) {
          if (refDialogoPrecificacaoNova.current) {
            refDialogoPrecificacaoNova.current.handleEditarDiferente(
              refDadosTemp.current,
            );
          }
        } else {
          setOpen(true);
          const valorNegociadoComAIndustria = props.valorNegociadoComAIndustria;
          const valorNegociadoComAIndustriaPresumido =
            props.valorNegociadoComAIndustriaPresumido;

          const isValorNulo = verificaDiferencaNula(
            0,
            valorNegociadoComAIndustriaPresumido,
            {
              tolerancia: 0.001,
            },
          );

          if (isValorNulo) {
            setValorNegociadoComAIndustriaPresumido(0);
            setIsIncluirValorPresumido(false);
          } else {
            const isIncluirValorPresumido = verificaDiferencaNula(
              valorNegociadoComAIndustria,
              valorNegociadoComAIndustriaPresumido,
              {
                tolerancia: 0.001,
              },
            );

            if (!isIncluirValorPresumido) {
              setValorNegociadoComAIndustriaPresumido(
                valorNegociadoComAIndustriaPresumido,
              );
              setIsIncluirValorPresumido(true);
            }
          }

          if (dadosFormatados.dadosPrecificacao) {
            setIsIncluirPrecificacao(true);
            setDadosSalvos(dadosFormatados);
          } else {
            setIsIncluirPrecificacao(false);
            setDadosSalvos(null);
          }
        }
      },
    }));

    function formatDate(date) {
      if (date === '') {
        return null;
      }
      const dataQuebrada = date.split('-');
      const ano = parseInt(dataQuebrada[0], 10);
      const mes = parseInt(dataQuebrada[1], 10) - 1;
      const dia = parseInt(dataQuebrada[2], 10);
      const dataNova = new Date();
      dataNova.setFullYear(ano);
      dataNova.setMonth(mes);
      dataNova.setDate(dia);
      return dataNova;
    }

    function deletarValidade(index) {
      const validades2 = validades.slice();
      validades2.splice(index);
      setValidades(validades2);
    }

    function formatarValidade(date) {
      if (date) {
        const arr = date.split('-');
        return arr.reverse().join('/');
      }

      return '-';
    }

    function validateValorNegociadoComAIndustriaPresumido() {
      let error = '';
      if (tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA) {
        if (isIncluirValorPresumido) {
          if (
            valorNegociadoComAIndustriaPresumido <= 0 ||
            Number.isNaN(valorNegociadoComAIndustriaPresumido)
          ) {
            error = 'Este campo é obrigatório';
          }
        }
      }
      return error;
    }

    function validarPrecificacao() {
      let error = '';
      if (isIncluirPrecificacao) {
        if (!dadosSalvos) {
          error = 'É necessário precificar';
        }
      }
      return error;
    }

    function validateValidade() {
      const error = '';
      //if (validade === null || validade === '') error = 'Data Inválida!';
      return error;
    }

    function getErros() {
      const erros = [''];
      erros[0] = validateValorNegociadoComAIndustriaPresumido();
      erros[1] = validarPrecificacao();
      erros[2] = validateValidade();

      return erros;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    const produto = refDadosTempProduto.current;

    function openPrecificacao() {
      if (refTipoMetodo.current === 0) {
        if (refDialogoPrecificacaoNova.current) {
          refDialogoPrecificacaoNova.current.handleEdit(
            refDadosTemp.current.produtoId,
            refDadosTemp.current.config,
            refDadosTemp.current.fornecedor,
            refDadosTemp.current.validade,
            refDadosTemp.current.nImpressoes,
            refDadosTemp.current.valorNegociadoComAIndustria,
            refDadosTemp.current.quantidadeComprada,
            refDadosTemp.current.repasseDoIpi,
            refDadosTemp.current.repasseDoFreteUnitario,
            refDadosTemp.current.repasseDeSt,
            refDadosTemp.current.repasseDeSeguros,
            refDadosTemp.current.repasseDeOutrasDespesas,
            refDadosTemp.current.index,
            refDadosTemp.current.descontoNfe,
            refDadosTemp.current.descontoBoleto,
            refDadosTemp.current.descontoBonificacao,
            refDadosTemp.current.descontoBacklight,
            refDadosTemp.current.descontoJornalOfertas,
            refDadosTemp.current.descontoDescargoMercadorias,
            refDadosTemp.current.dadosComplementaresNota,
            refDadosTemp.current.validades,
          );
        }
      } else if (refTipoMetodo.current === 1) {
        if (refDialogoPrecificacaoNova.current) {
          refDialogoPrecificacaoNova.current.handleEditarDiferente(
            refDadosTemp.current,
          );
        }
      }
    }

    function getInfosUteis(produto) {
      let fator = 1;
      let unidadeQtde = 'kg';
      let unidadePreco = 'kg';

      if (produto) {
        switch (produto.unidade.tipo) {
          case POR_UNIDADE:
            fator = 1;
            unidadeQtde = 'Un';
            unidadePreco = 'Un';
            break;
          case POR_PESO_FIXO:
            fator = produto.peso_medio;
            unidadeQtde = 'Un';
            unidadePreco = 'Kg';
            break;
          case POR_PESO_VARIAVEL_SEM_UNIDADE:
            fator = 1;
            unidadeQtde = 'Kg';
            unidadePreco = 'Un';
            break;
          default:
            fator = 1;
            break;
        }
      }

      return {
        fator,
        unidadeQtde,
        unidadePreco,
      };
    }

    function getDados() {
      if (refDadosTemp.current) {
        if (refTipoMetodo.current === 0) {
          const {
            produtoId,
            config,
            fornecedor,
            validade,
            nImpressoes,
            valorNegociadoComAIndustria,
            quantidadeComprada,
            repasseDoIpi,
            repasseDoFreteUnitario,
            repasseDeSt,
            repasseDeSeguros,
            repasseDeOutrasDespesas,
            index,
            descontoNfe,
            descontoBoleto,
            descontoBonificacao,
            descontoBacklight,
            descontoJornalOfertas,
            descontoDescargoMercadorias,
            dadosComplementaresNota,
            validades,
          } = refDadosTemp.current;

          const produto = refDadosTempProduto.current;

          const { fator, unidadeQtde, unidadePreco } = getInfosUteis(produto);

          let precoVenda = 0;

          if (dadosSalvos) {
            if (dadosSalvos.dadosPrecificacao) {
              precoVenda = dadosSalvos.dadosPrecificacao.preco_de_venda * fator;
            }
          }

          return {
            valorNegociadoComAIndustria: `${formatMoeda(
              valorNegociadoComAIndustria * fator,
            )} / ${unidadePreco}`,
            quantidade: `${quantidadeComprada} ${unidadeQtde}`,
            produto: refDadosTempProduto.current,
            repasseDoIpi: `${formatMoeda(
              repasseDoIpi * fator,
            )} / ${unidadePreco}`,
            repasseDoFreteUnitario: `${formatMoeda(
              repasseDoFreteUnitario * fator,
            )} / ${unidadePreco}`,
            repasseDeSt: `${formatMoeda(
              repasseDeSt * fator,
            )} / ${unidadePreco}`,
            repasseDeSeguros: `${formatMoeda(
              repasseDeSeguros * fator,
            )} / ${unidadePreco}`,
            repasseDeOutrasDespesas: `${formatMoeda(
              repasseDeOutrasDespesas * fator,
            )} / ${unidadePreco}`,
            descontoNfe: `${formatMoeda(
              descontoNfe * fator,
            )} / ${unidadePreco}`,
            descontoBoleto: `${formatMoeda(
              descontoBoleto * fator,
            )} / ${unidadePreco}`,
            descontoBonificacao: `${formatMoeda(
              descontoBonificacao * fator,
            )} / ${unidadePreco}`,
            descontoBacklight: `${formatMoeda(
              descontoBacklight * fator,
            )} / ${unidadePreco}`,
            descontoJornalOfertas: `${formatMoeda(
              descontoJornalOfertas * fator,
            )} / ${unidadePreco}`,
            descontoDescargoMercadorias: `${formatMoeda(
              descontoDescargoMercadorias * fator,
            )} / ${unidadePreco}`,
            precoVenda: precoVenda
              ? `${formatMoeda(precoVenda * fator)} / ${unidadePreco}`
              : '',
          };
        }
        const {
          produto,
          repasseDoIpi,
          repasseDoFreteUnitario,
          repasseDeSt,
          repasseDeSeguros,
          repasseDeOutrasDespesas,
          descontoNfe,
          descontoBoleto,
          descontoBonificacao,
          descontoBacklight,
          descontoJornalOfertas,
          descontoDescargoMercadorias,
          dadosComplementaresNota,
          validades,
          config,
          fornecedor,
          valorNegociadoComAIndustria,
          quantidadeComprada,
          valorNegociadoComAIndustriaPresumido,
          nImpressoes,
          margemLucroBruto,
        } = refDadosTemp.current;

        const { fator, unidadeQtde, unidadePreco } = getInfosUteis(produto);

        let precoVenda = 0;

        if (dadosSalvos) {
          if (dadosSalvos.dadosPrecificacao) {
            precoVenda = dadosSalvos.dadosPrecificacao.preco_de_venda;
          }
        }

        return {
          valorNegociadoComAIndustria: `${formatMoeda(
            valorNegociadoComAIndustria * fator,
          )} / ${unidadePreco}`,
          quantidade: `${quantidadeComprada} ${unidadeQtde}`,
          produto: refDadosTempProduto.current,
          repasseDoIpi: `${formatMoeda(
            repasseDoIpi * fator,
          )} / ${unidadePreco}`,
          repasseDoFreteUnitario: `${formatMoeda(
            repasseDoFreteUnitario * fator,
          )} / ${unidadePreco}`,
          repasseDeSt: `${formatMoeda(repasseDeSt * fator)} / ${unidadePreco}`,
          repasseDeSeguros: `${formatMoeda(
            repasseDeSeguros * fator,
          )} / ${unidadePreco}`,
          repasseDeOutrasDespesas: `${formatMoeda(
            repasseDeOutrasDespesas * fator,
          )} / ${unidadePreco}`,
          descontoNfe: `${formatMoeda(descontoNfe * fator)} / ${unidadePreco}`,
          descontoBoleto: `${formatMoeda(
            descontoBoleto * fator,
          )} / ${unidadePreco}`,
          descontoBonificacao: `${formatMoeda(
            descontoBonificacao * fator,
          )} / ${unidadePreco}`,
          descontoBacklight: `${formatMoeda(
            descontoBacklight * fator,
          )} / ${unidadePreco}`,
          descontoJornalOfertas: `${formatMoeda(
            descontoJornalOfertas * fator,
          )} / ${unidadePreco}`,
          descontoDescargoMercadorias: `${formatMoeda(
            descontoDescargoMercadorias * fator,
          )} / ${unidadePreco}`,
          precoVenda: precoVenda
            ? `${formatMoeda(precoVenda * fator)} / ${unidadePreco}`
            : '',
        };
      }
      return {
        valorNegociadoComAIndustria: 0,
        quantidade: 0,
        produto: null,
        repasseDoIpi: 0,
        repasseDoFreteUnitario: 0,
        repasseDeSt: 0,
        repasseDeSeguros: 0,
        repasseDeOutrasDespesas: 0,
        descontoNfe: 0,
        descontoBoleto: 0,
        descontoBonificacao: 0,
        descontoBacklight: 0,
        descontoJornalOfertas: 0,
        descontoDescargoMercadorias: 0,
      };
    }

    const {
      valorNegociadoComAIndustria,
      quantidade,
      produto: produto2,
      repasseDoIpi,
      repasseDoFreteUnitario,
      repasseDeSt,
      repasseDeSeguros,
      repasseDeOutrasDespesas,
      descontoNfe,
      descontoBoleto,
      descontoBonificacao,
      descontoBacklight,
      descontoJornalOfertas,
      descontoDescargoMercadorias,
      precoVenda,
    } = getDados();

    const { fator, unidadeQtde, unidadePreco } = getInfosUteis(produto);

    const valorNegociadoComAIndustriaPresumidoComputado =
      valorNegociadoComAIndustriaPresumido / fator;

    function getMensagemErro() {
      if (isIncluirPrecificacao) {
        if (!dadosSalvos) {
          return 'A precificação é necessária';
        }
      }

      return '';
    }

    const erro = getMensagemErro();

    function verificaValidadeExistente(validade) {
      return validades.find((item) => item === validade);
    }

    function validarDataValidade(data) {
      const dataValidade = new Date(
        moment(new Date(data)).endOf('day').format(),
      );
      const dataAtual = new Date(moment(new Date()).endOf('day').format());
      const dataFutura = new Date(
        moment(new Date()).add('years', 3).endOf('day').format(),
      );

      if (dataValidade.getTime() < dataAtual.getTime()) {
        return {
          status: false,
          message:
            'A data de Validade não pode ser uma data anterior ao dia de Hoje',
        };
      }

      if (dataValidade.getTime() > dataFutura.getTime()) {
        return {
          status: false,
          message: 'A data de validade não pode ser maior que 3 anos a frente',
        };
      }

      return {
        status: true,
        message: '',
      };
    }

    function handleAdicionarValidade(validade) {
      if (validade) {
        const { status, message } = validarDataValidade(validade);
        if (status) {
          const isExistente = verificaValidadeExistente(validade);
          if (!isExistente) {
            setValidades([...validades, validade]);
            setValidade('');
          } else {
            enqueueSnackbar('Esta validade ja foi registrada!', {
              variant: 'error',
            });
          }
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('A validade dever ser um data válida!', {
          variant: 'error',
        });
      }
    }

    return (
      <>
        <Dialog
          open={open}
          onClose={() => handleCloseDialog()}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={isCloseX ? () => handleCloseDialog() : null}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`${prefixoTitle}Precificação - ${produto ? produto.nome : ''}`}
          </DialogTitle>
          <AppBarNaturezaCompra
            tipo={tipo}
            subTotalPresumido={0}
            handleShowValoresReais={() => {}}
            showValoresReais={false}
            visibleInput={false}
            visibleSubTotalPresumido={false}
          />
          <DialogContent dividers>
            {tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA ? (
              <div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <SwitchInput
                    name="is_incluir_valor_presumido"
                    handleEnter={() => {}}
                    label="Incluir Preço Diferente"
                    handleKey={() => {}}
                    checked={isIncluirValorPresumido}
                    onChange={(value) => {
                      const valor = value.target.checked;
                      setDadosSalvos(null);
                      setIsIncluirValorPresumido(valor);
                    }}
                    fullWidth
                  />

                  {isIncluirValorPresumido ? (
                    <PrecoInput
                      name="valor_negociado_com_a_industria_presumido"
                      label={`Preço do Produto Integral / ${unidadePreco}`}
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(valorNegociadoComAIndustriaPresumido)
                          ? ''
                          : String(valorNegociadoComAIndustriaPresumido)
                      }
                      onChange={(value) => {
                        setValorNegociadoComAIndustriaPresumido(
                          value.target.value,
                        );
                      }}
                      error={erros[0] !== ''}
                      helperText={erros[0]}
                      //fullWidth
                      escala={2}
                      //disabled={isIncluirPrecificacao}
                    />
                  ) : null}

                  <SwitchInput
                    name="is_incluir_precificacao"
                    handleEnter={() => {}}
                    label="Incluir Precificação"
                    handleKey={() => {}}
                    checked={isIncluirPrecificacao}
                    onChange={(value) =>
                      setIsIncluirPrecificacao(value.target.checked)
                    }
                    fullWidth
                  />

                  {/* {isIncluirPrecificacao ? (
                    <Tooltip
                      title="Editar"
                      placement="bottom"
                      aria-label="add2"
                    >
                      <IconButton
                        aria-label="check"
                        size="small"
                        onClick={() => {
                          openPrecificacao();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null} */}
                  {isIncluirPrecificacao ? (
                    <Button
                      onClick={() => {
                        openPrecificacao();
                      }}
                      color="secondary"
                      variant="contained"
                      tabindex="-1"
                    >
                      Precificar
                    </Button>
                  ) : null}
                </div>
                {erro ? (
                  <div
                    style={{
                      margin: '10px 0px',
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    * {erro}
                  </div>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                  }}
                >
                  <Table
                    aria-label="a dense table"
                    style={{ width: 'min-content' }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                            backgroundColor: '#d8161687',
                          }}
                        >
                          Propriedade
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                            backgroundColor: '#d8161687',
                          }}
                        >
                          Valor
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {precoVenda ? (
                        <TableRow>
                          <StyledTableCell
                            align="left"
                            style={{
                              fontWeight: 'bold',
                              fontSize: '20px',
                            }}
                          >
                            Precificação
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{
                              fontWeight: 'bold',
                              fontSize: '20px',
                            }}
                          >
                            {precoVenda}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Preço do Produto Integral
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {formatMoeda(valorNegociadoComAIndustria)}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Quantidade Comprada
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {quantidade}
                        </StyledTableCell>
                      </TableRow>
                      {/* <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Total
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {quantidade}
                        </StyledTableCell>
                      </TableRow> */}
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Repasse de IPI
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {repasseDoIpi}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Repasse de Frete
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {repasseDoFreteUnitario}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Repasse de ST
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {repasseDeSt}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Repasse de Seguro
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {repasseDeSeguros}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Repasse de Outras Despesas
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {repasseDeOutrasDespesas}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Desconto em Nfe
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {descontoNfe}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Desconto em Boleto
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {descontoBoleto}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Desconto em Bonificação
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {descontoBonificacao}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Desconto em Backlight
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {descontoBacklight}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Desconto em Jornal de Ofertas
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {descontoJornalOfertas}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          Desconto em Descargo de Mercadorias
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          {descontoDescargoMercadorias}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

                {/* <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '10px',
                    flexDirection: 'column',
                  }}
                >
                  <DateInput
                    name="validade"
                    handleEnter={() => {
                      handleAdicionarValidade(validade);
                    }}
                    label="Validade"
                    handleKey={() => {}}
                    value={validade}
                    onChange={(value) => setValidade(value.target.value)}
                    error={erros[2] !== ''}
                    helperText={erros[2]}
                    fullWidth
                  />

                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell2>Ordem</StyledTableCell2>
                          <StyledTableCell2>Validade</StyledTableCell2>
                          <StyledTableCell2>Ações</StyledTableCell2>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {validades.map((row, ind) => (
                          <StyledTableRow key={`${ind}-row`}>
                            <StyledTableCell2>{ind + 1}</StyledTableCell2>
                            <StyledTableCell2>
                              {formatarValidade(row)}
                            </StyledTableCell2>
                            <StyledTableCell2>
                              <IconButton
                                aria-label="deletar"
                                size="small"
                                onClick={() => deletarValidade(ind)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </StyledTableCell2>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div> */}
              </div>
            ) : null}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => handleCloseDialog()}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              {label1}
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={() => {
                  if (isIncluirValorPresumido) {
                    if (dadosSalvos && isIncluirPrecificacao) {
                      handleCloseDialog2(
                        valorNegociadoComAIndustriaPresumido,
                        dadosSalvos,
                        refDadosTemp.current.index,
                      );
                    } else {
                      handleCloseDialog2(
                        valorNegociadoComAIndustriaPresumido,
                        null,
                        refDadosTemp.current.index,
                      );
                    }
                  } else if (dadosSalvos && isIncluirPrecificacao) {
                    handleCloseDialog2(
                      0,
                      dadosSalvos,
                      refDadosTemp.current.index,
                    );
                  } else {
                    handleCloseDialog2(0, null, refDadosTemp.current.index);
                  }
                }}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente}
                variant="contained"
                color="primary"
              >
                {label2}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <DialogoPrecificacaoNova
          getErrosAdicionais={getErrosAdicionais}
          getInformacoesAdicionais={getInformacoesAdicionais}
          ref={refDialogoPrecificacaoNova}
          //handleClose={() => {}}
          handleClose={(dados) => {
            if (tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA) {
              if (dados) {
                setDadosSalvos(formataDados(dados));
              }
            } else {
              handleCloseDialog(dados);
            }
          }}
          estoque={estoque}
          hasRepasseNaoUnitarioIPI={hasRepasseNaoUnitarioIPI}
          hasRepasseNaoUnitarioFrete={hasRepasseNaoUnitarioFrete}
          hasRepasseNaoUnitarioST={hasRepasseNaoUnitarioST}
          hasRepasseNaoUnitarioSeguros={hasRepasseNaoUnitarioSeguros}
          hasRepasseNaoUnitarioOutrasDespesas={
            hasRepasseNaoUnitarioOutrasDespesas
          }
          label1={label1}
          label2={label2}
          prefixoTitle={prefixoTitle}
          isCloseX={isCloseX}
          isEmitMessage={isEmitMessage}
          handleUpdateProduto={handleUpdateProduto}
          tipo={tipo}
          passadorLotes={passadorLotes}
          valorNegociadoComAIndustriaPresumido={
            valorNegociadoComAIndustriaPresumidoComputado
          }
          isIncluirValorPresumido={isIncluirValorPresumido}
        />
      </>
    );
  },
);

export default Container;
