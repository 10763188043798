import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useSnackbar } from 'notistack';

import {
  createAPI,
  updateAPI,
  getListAllAPI,
  api,
  getUserId,
} from '../../../services';
import {
  POR_PESO_FIXO,
  POR_UNIDADE,
  formatMoeda,
  STATUS_ATIVO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
  ESTADO_FISICO_CONGELADO,
  ESTADO_FISICO_RESFRIADO,
  ESTADO_FISICO_SECO,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_UN,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_CX1,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_CX2,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_CX3,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_KG,
  SECONDARY_COLOR,
} from '../../../utils';
import {
  TextInput,
  AutoCompleteRemoto,
  PesoInput,
  IntegerInput,
  PrecoInput,
  PorcentagemInput,
  TeclaInput,
  SwitchInput,
  CfopInput,
  CstInput,
  NcmInput,
  CestInput,
  SelectInput,
  VolumeInput,
} from '../../Inputs';
import DialogoSenha from '../DialogoTransacao/DialogoSenha';
import DialogoCreateEditCusto from './DialogoCreateEditCusto';
import DialogoDeletarCusto from './DialogoDeletarCusto';
import LocaisInput from './LocaisInput';

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';
const ABA_GERAL = 'F2';
const ABA_IMPOSTOS = 'F10';
const ABA_BALANCA = 'F7';
const DESBLOQUEAR_EDICAO_PRECO_VENDA = 'F9';

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
    padding: 3,
  },
  body: {
    fontSize: 14,
    padding: 3,
  },
}))(TableCell);

function IndicadorTravado({ value, onChange }) {
  const classes = useStyles();
  return value ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title="Travado" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          //classes={{ root: classes.root }}
          size="small"
          onClick={() => onChange(false)}
          style={{ color: 'red' }}
        >
          <LockIcon />
        </IconButton>
      </Tooltip>
      <span style={{ color: 'red' }}>(Travado)</span>
    </div>
  ) : (
    <Tooltip title="Sem Trave" placement="bottom" aria-label="add2">
      <IconButton
        aria-label="edit"
        //classes={{ root: classes.root }}
        size="small"
        onClick={() => onChange(true)}
        style={{ color: 'green' }}
      >
        <LockOpenIcon />
      </IconButton>
    </Tooltip>
  );
}

const optionsPorcentagemCustoOperacional = [
  {
    label: '100 %',
    value: 100,
  },
  {
    label: '75 %',
    value: 75,
  },
  {
    label: '50 %',
    value: 50,
  },
  {
    label: '25 %',
    value: 25,
  },
  {
    label: '0 %',
    value: 0,
  },
];

const optionsEstadoFísico = [
  {
    label: 'Congelado',
    value: ESTADO_FISICO_CONGELADO,
  },
  {
    label: 'Resfriado',
    value: ESTADO_FISICO_RESFRIADO,
  },
  {
    label: 'Seco',
    value: ESTADO_FISICO_SECO,
  },
];
/* 
const optionsUnidadePrecoPreCompra = [
  {
    label: 'Unidade',
    value: TIPO_UNIDADE_PRECO_PRE_COMPRA_UN,
  },
  {
    label: 'Kg',
    value: TIPO_UNIDADE_PRECO_PRE_COMPRA_KG,
  },
  {
    label: 'Caixa Nível 1',
    value: TIPO_UNIDADE_PRECO_PRE_COMPRA_CX1,
  },
  {
    label: 'Caixa Nível 2',
    value: TIPO_UNIDADE_PRECO_PRE_COMPRA_CX2,
  },
  {
    label: 'Caixa Nível 3',
    value: TIPO_UNIDADE_PRECO_PRE_COMPRA_CX3,
  },
]; */

const optionsCfop = [
  {
    label: '5102 - Venda de mercadoria de terceiros',
    value: '5102',
  },
  {
    label:
      '5405 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituído',
    value: '5405',
  },
];

const optionsCstIcms = [
  {
    label: '00 - Tributada integralmente',
    value: '00',
  },
  /*  {
    label: 'Tributada e com cobrança do ICMS por substituição tributária',
    value: '10',
  }, */
  {
    label: '20 - Tributação com redução de base de cálculo',
    value: '20',
  },
  /* {
    label:
      'Tributação Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
    value: '30',
  }, */
  {
    label: '40 - Tributação Isenta',
    value: '40',
  },
  {
    label: '41 - Tributação Não tributada',
    value: '41',
  },
  {
    label: '50 - Tributação Suspensão',
    value: '50',
  },
  /* {
    label: 'Tributação com Diferimento',
    value: '51',
  }, */
  /*  {
    label:
      '60 - Tributação ICMS cobrado anteriormente por substituição tributária',
    value: '60',
  }, */
  /*  {
    label:
      'Tributação ICMS com redução de base de cálculo e cobrança do ICMS por substituição tributária',
    value: '70',
  }, */
  /*  {
    label: 'Tributação ICMS: Outro',
    value: '90',
  }, */
];

const optionsCstIcms2 = [
  /*   {
    label: '00 - Tributada integralmente',
    value: '00',
  }, */
  /*  {
    label: 'Tributada e com cobrança do ICMS por substituição tributária',
    value: '10',
  }, */
  /*   {
    label: '20 - Tributação com redução de base de cálculo',
    value: '20',
  }, */
  /* {
    label:
      'Tributação Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
    value: '30',
  }, */
  /*   {
    label: '40 - Tributação Isenta',
    value: '40',
  },
  {
    label: '41 - Tributação Não tributada',
    value: '41',
  },
  {
    label: '50 - Tributação Suspensão',
    value: '50',
  }, */
  /* {
    label: 'Tributação com Diferimento',
    value: '51',
  }, */
  {
    label:
      '60 - Tributação ICMS cobrado anteriormente por substituição tributária',
    value: '60',
  },
  /*  {
    label:
      'Tributação ICMS com redução de base de cálculo e cobrança do ICMS por substituição tributária',
    value: '70',
  }, */
  /*  {
    label: 'Tributação ICMS: Outro',
    value: '90',
  }, */
];

const optionsCstPis = [
  {
    label:
      '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
    value: '01',
  },
  {
    label:
      '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
    value: '02',
  },
  /* {
    label:
      'Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)',
    value: '03',
  }, */
  {
    label: '04 - Operação Tributável (tributação monofásica (alíquota zero))',
    value: '04',
  },
  {
    label: '05 - Operação Tributável (Substituição Tributária)',
    value: '05',
  },
  {
    label: '06 - Operação Tributável (alíquota zero)',
    value: '06',
  },
  {
    label: '07 - Operação Isenta da Contribuição',
    value: '07',
  },
  {
    label: '08 - Operação Sem Incidência da Contribuição',
    value: '08',
  },
  {
    label: '09 - Operação com Suspensão da Contribuição',
    value: '09',
  },
];

const optionsCstCofins = [
  {
    label:
      '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
    value: '01',
  },
  {
    label:
      '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
    value: '02',
  },
  /* {
    label:
      'Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)',
    value: '03',
  }, */
  {
    label: '04 - Operação Tributável (tributação monofásica (alíquota zero))',
    value: '04',
  },
  {
    label: '05 - Operação Tributável (Substituição Tributária)',
    value: '05',
  },
  {
    label: '06 - Operação Tributável (alíquota zero)',
    value: '06',
  },
  {
    label: '07 - Operação Isenta da Contribuição',
    value: '07',
  },
  {
    label: '08 - Operação Sem Incidência da Contribuição',
    value: '08',
  },
  {
    label: '09 - Operação com Suspensão da Contribuição',
    value: '09',
  },
];

const optionsOrigIcms = [
  {
    label: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8;',
    value: '0',
  },
  {
    label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
    value: '1',
  },
  {
    label:
      '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
    value: '2',
  },
  {
    label:
      '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
    value: '3',
  },
  {
    label:
      '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
    value: '4',
  },
  {
    label:
      '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
    value: '5',
  },
  {
    label:
      '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natura',
    value: '6',
  },
  {
    label:
      '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural',
    value: '7',
  },
  {
    label:
      '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
    value: '8',
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function contAlphaNumerico(value) {
  let contAlphaNumericos = 0;
  if (value) {
    for (let i = 0; i < value.length; i += 1) {
      if (value[i] !== '-' && value[i] !== '_') {
        contAlphaNumericos += 1;
      }
    }
  }
  return contAlphaNumericos;
}

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    flexGrow: 1,
  },
}));

const ImpostoInput = forwardRef(
  (
    {
      name,
      handleEnter = () => {},
      label,
      handleKey = () => {},
      value,
      onChange,
      error = false,
      helperText = '',
      fullWidth = false,
      autoFocus = false,
      isCasoEspecial,
      disabled = false,
      onChangeIsCasoEspecial,
    },
    ref,
  ) => (
    <PorcentagemInput
      ref={ref}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      escala={2}
      autoFocus={autoFocus}
      handleEnter={handleEnter}
      handleKey={handleKey}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      InputProps={{
        disabled,
        endAdornment: (
          <SwitchInput
            name={`${name}_caso_especial`}
            handleEnter={() => {}}
            label="Caso Especial"
            handleKey={() => {}}
            checked={isCasoEspecial}
            onChange={(value) => {
              onChangeIsCasoEspecial(value.target.checked);
            }}
          />
        ),
      }}
    />
  ),
);

const NOME_POSITION = 0;
const CODIGO_POSITION = 1;
const UNIDADE_POSITION = 2;
const CATEGORIA_POSITION = 3;
const FAMILIA_POSITION = 4;
const ESTOQUE_MINIMO_POSITION = 5;
const PESO_MEDIO_POSITION = 6;
const TECLA_POSITION = 7;
const FORNECEDOR_POSITION = 8;
const LOCAIS_POSITION = 9;
const PRECO_VENDA_POSITION = 10;

const CODIGO_CAIXA_NIVEL_1 = 12;
const UNIDADES_CAIXA_NIVEL_1 = 13;
const CODIGO_CAIXA_NIVEL_2 = 14;
const UNIDADES_CAIXA_NIVEL_2 = 15;
const CODIGO_CAIXA_NIVEL_3 = 16;
const UNIDADES_CAIXA_NIVEL_3 = 17;
const TAXA_LUCRO_POSITION = 18;

const D1_POSITION = 0;
const DIAS_VALIDADE_POSITION = 1;
const TARA_POSITION = 2;
const EXIBIR_DIAS_VALIDADE_POSITION = 3;
const EXIBIR_VALIDADE_EMBALAGEM_POSITION = 4;

const IPI_POSITION = 0;
const PIS_POSITION = 1;
const COFINS_POSITION = 2;
const ICMS_AI_POSITION = 3;
const ICMS_INT_POSITION = 4;
const DF_POSITION = 5;
const RBC_POSITION = 6;
const MVA_POSITION = 7;
const NCM_POSITION = 8;
const CST_POSITION = 9;
const CFOP_POSITION = 10;

const CST_PIS_ENTRADA_POSITION = 11;
const CST_PIS_SAIDA_POSITION = 12;
const CST_COFINS_ENTRADA_POSITION = 13;
const CST_COFINS_SAIDA_POSITION = 14;
const CEST_POSITION = 15;

const TIPO_IMPRESSAO_KG = 'tipo_impressao_kg';
const TIPO_IMPRESSAO_UN = 'tipo_impressao_un';

const optionsTipoImpressao = [
  {
    label: 'KG',
    value: TIPO_IMPRESSAO_KG,
  },
  {
    label: 'UN',
    value: TIPO_IMPRESSAO_UN,
  },
];

const DEFAULT_ID = -1;

const inputsGeral = [
  {
    nome: 'nome', // 0
    defaultValue: '',
    label: 'Nome',
  },
  {
    nome: 'codigo', // 1
    defaultValue: '',
    label: 'Código de Barras',
  },
  {
    nome: 'unidade_id', // 2
    defaultValue: null,
    label: 'Unidade',
  },
  {
    nome: 'categoria_id', // 3
    defaultValue: null,
    label: 'Categoria',
  },
  {
    nome: 'estoque_minimo', // 4
    defaultValue: 0,
    label: 'Estoque Mínimo',
  },
  {
    nome: 'peso_medio', // 5
    defaultValue: 0,
    label: 'Peso Médio',
  },
  {
    nome: 'tecla', // 6
    defaultValue: '',
    label: 'Tecla',
  },
  {
    nome: 'preco_venda', // 7
    defaultValue: 0,
    label: 'Preço de Venda',
  },
  {
    nome: 'preco_revenda', // 8
    defaultValue: 0,
    label: 'Preço de Revenda',
  },
  {
    nome: 'familia_produto_id', // 9
    defaultValue: null,
    label: 'Família',
  },
  {
    nome: 'fornecedor_id', // 10
    defaultValue: null,
    label: 'Fornecedores',
  },
  {
    nome: 'desconto_caixa', // 11
    defaultValue: null,
    label: 'Margem Desconto (%)',
  },
  {
    nome: 'codigo_caixa_nivel_1', // 12
    defaultValue: '',
    label: 'Código de barras',
  },
  {
    nome: 'unidades_caixa_nivel_1', // 13
    defaultValue: 0,
    label: 'Unidades',
  },
  {
    nome: 'codigo_caixa_nivel_2', // 14
    defaultValue: '',
    label: 'Código de barras',
  },
  {
    nome: 'unidades_caixa_nivel_2', // 15
    defaultValue: 0,
    label: 'Caixas',
  },
  {
    nome: 'codigo_caixa_nivel_3', // 16
    defaultValue: '',
    label: 'Código de barras',
  },
  {
    nome: 'unidades_caixa_nivel_3', // 17
    defaultValue: 0,
    label: 'Caixetas',
  },
  {
    nome: 'locais', // 18
    defaultValue: [],
    label: 'Locais',
  },
  {
    nome: 'peso_provavel', // 19
    defaultValue: 0,
    label: 'Peso Provável',
  },
  {
    nome: 'taxa_de_lucro', // 20
    defaultValue: 0,
    label: 'Margem de Lucro Líquido',
  },
];

const inputsBalanca = [
  {
    nome: 'd1',
    defaultValue: '',
    label: 'Nome reduzido',
  },
  {
    nome: 'dias_validade',
    defaultValue: 0,
    label: 'Dias de validade',
  },
  {
    nome: 'tara',
    defaultValue: null,
    label: 'Tara',
  },
  {
    nome: 'exibir_dias_validade',
    defaultValue: 0,
    label: 'Exibir dias validade',
  },
  {
    nome: 'exibir_data_embalagem',
    defaultValue: 0,
    label: 'Exibir validade embalagem',
  },
];

/*const inputsImpostos = [
  {
    nome: 'ipi',
    defaultValue: 0,
    label: 'IPI (%)',
  }, // 0
  {
    nome: 'pis',
    defaultValue: 0,
    label: 'PIS (%)',
  }, // 1
  {
    nome: 'cofins',
    defaultValue: 0,
    label: 'COFINS (%)',
  }, // 2
  {
    nome: 'icms_ai',
    defaultValue: 0,
    label: 'ICMS AI (%)',
  }, // 3
  {
    nome: 'icms_int',
    defaultValue: 0,
    label: 'ICMS INT (%)',
  }, // 4
  {
    nome: 'df',
    defaultValue: 0,
    label: 'DF (%)',
  }, // 5
  {
    nome: 'rbc',
    defaultValue: 0,
    label: 'RBC',
  }, // 6
  {
    nome: 'ncm',
    defaultValue: '',
    label: 'NCM',
  }, // 7
  {
    nome: 'cst',
    defaultValue: '',
    label: 'CST ICMS ENTRADA E SAÍDA',
  }, // 8
  {
    nome: 'cfop',
    defaultValue: '',
    label: 'CFOP',
  }, // 9
  // LALAL
  {
    nome: 'cst_pis_entrada',
    defaultValue: '',
    label: 'CST PIS ENTRADA',
  }, // 10
  {
    nome: 'cst_pis_saida',
    defaultValue: '',
    label: 'CST PIS SAÍDA',
  }, // 11
  {
    nome: 'cst_cofins_entrada',
    defaultValue: '',
    label: 'CST COFINS ENTRADA',
  }, // 12
  {
    nome: 'cst_cofins_saida',
    defaultValue: '',
    label: 'CST COFINS SAÍDA',
  }, // 13
  {
    nome: 'cest',
    defaultValue: '',
    label: 'CEST',
  }, // 14
  {
    nome: 'mva',
    defaultValue: '',
    label: 'MVA',
  }, // 15
];*/

const inputsImpostos = [
  {
    nome: 'ipi_credito',
    defaultValue: 0,
    label: 'IPI Crédito (%)',
  }, // 0
  {
    nome: 'ipi_debito',
    defaultValue: 0,
    label: 'IPI Débito (%)',
  }, // 1
  {
    nome: 'pis_credito',
    defaultValue: 0,
    label: 'PIS Crédito (%)',
  }, // 2
  {
    nome: 'pis_debito',
    defaultValue: 0,
    label: 'PIS Débito(%)',
  }, // 3
  {
    nome: 'cofins_credito',
    defaultValue: 0,
    label: 'COFINS Crédito (%)',
  }, // 4
  {
    nome: 'cofins_debito',
    defaultValue: 0,
    label: 'COFINS Débito (%)',
  }, // 5
  {
    nome: 'icms_normal_credito',
    defaultValue: 0,
    label: 'ICMS Normal Crédito (%)',
  }, // 6
  {
    nome: 'icms_normal_debito',
    defaultValue: 0,
    label: 'ICMS Normal Débito (%)',
  }, // 7
  {
    nome: 'icms_fronteira',
    defaultValue: 0,
    label: 'ICMS Fronteira (%)',
  }, // 8
  {
    nome: 'icms_antecipacao',
    defaultValue: 0,
    label: 'ICMS Antecipação (%)',
  }, // 9
  {
    nome: 'df',
    defaultValue: 0,
    label: 'DF (%)',
  }, // 10
  {
    nome: 'rbc',
    defaultValue: 0,
    label: 'RBC',
  }, // 11
  {
    nome: 'ncm',
    defaultValue: '',
    label: 'NCM',
  }, // 12
  {
    nome: 'cst',
    defaultValue: '',
    label: 'CST ICMS ENTRADA E SAÍDA',
  }, // 13
  {
    nome: 'cfop',
    defaultValue: '',
    label: 'CFOP',
  }, // 14
  // LALAL
  {
    nome: 'cst_pis_entrada',
    defaultValue: '',
    label: 'CST PIS ENTRADA',
  }, // 15
  {
    nome: 'cst_pis_saida',
    defaultValue: '',
    label: 'CST PIS SAÍDA',
  }, // 16
  {
    nome: 'cst_cofins_entrada',
    defaultValue: '',
    label: 'CST COFINS ENTRADA',
  }, // 17
  {
    nome: 'cst_cofins_saida',
    defaultValue: '',
    label: 'CST COFINS SAÍDA',
  }, // 18
  {
    nome: 'cest',
    defaultValue: '',
    label: 'CEST',
  }, // 19
  {
    nome: 'mva',
    defaultValue: '',
    label: 'MVA',
  }, // 20,
  {
    nome: 'fcp',
    defaultValue: '',
    label: 'FCP',
  }, // 21
  {
    nome: 'orig',
    defaultValue: '',
    label: 'Origem',
  }, // 22
  {
    nome: 'pauta_fiscal',
    defaultValue: '',
    label: 'Pauta Fiscal',
  }, // 23
  {
    nome: 'cst_icms_entrada',
    defaultValue: '',
    label: 'CST ICMS ENTRADA',
  }, // 24
  {
    nome: 'cst_icms_saida',
    defaultValue: '',
    label: 'CST ICMS SAÍDA',
  }, // 25
];

function getDadosImpostosComputados(dados) {
  const {
    cstIcmsEntrada,
    cstIcmsSaida,
    cstPisEntrada,
    cstPisSaida,
    cstCofinsEntrada,
    cstCofinsSaida,
    cest,
  } = dados;

  return {
    isCasoEspecialPisCredito: cstPisEntrada ? cstPisEntrada !== '01' : false,
    isCasoEspecialPisDebito: cstPisSaida ? cstPisSaida !== '01' : false,
    isCasoEspecialCofinsCredito: cstCofinsEntrada
      ? cstCofinsEntrada !== '01'
      : false,
    isCasoEspecialCofinsDebito: cstCofinsSaida
      ? cstCofinsSaida !== '01'
      : false,
    isCasoEspecialIpiCredito: false,
    isCasoEspecialIpiDebito: false,
    isCasoEspecialIcmsNormalCredito: cstIcmsEntrada
      ? cstIcmsEntrada !== '00'
      : false,
    isCasoEspecialIcmsNormalDebito: cstIcmsSaida
      ? cstIcmsSaida !== '00'
      : false,
  };
}

function getOptionsUnidadePrecoPreCompra(
  tipoUnidade,
  unidadesCaixaNivel1,
  unidadesCaixaNivel2,
  unidadesCaixaNivel3,
) {
  if (
    tipoUnidade === POR_PESO_FIXO ||
    tipoUnidade === POR_PESO_VARIAVEL_SEM_UNIDADE
  ) {
    return [
      {
        label: 'Kg',
        value: TIPO_UNIDADE_PRECO_PRE_COMPRA_KG,
      },
    ];
  }

  const options = [
    {
      label: 'Unidade',
      value: TIPO_UNIDADE_PRECO_PRE_COMPRA_UN,
    },
  ];

  if (unidadesCaixaNivel1) {
    options.push({
      label: 'Caixa Nível 1',
      value: TIPO_UNIDADE_PRECO_PRE_COMPRA_CX1,
    });
  }

  if (unidadesCaixaNivel2) {
    options.push({
      label: 'Caixa Nível 2',
      value: TIPO_UNIDADE_PRECO_PRE_COMPRA_CX2,
    });
  }

  if (unidadesCaixaNivel3) {
    options.push({
      label: 'Caixa Nível 3',
      value: TIPO_UNIDADE_PRECO_PRE_COMPRA_CX3,
    });
  }

  return options;
}

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [id, setId] = useState(DEFAULT_ID);
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [unidade, setUnidade] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [estoqueMinimo, setEstoqueMinimo] = useState(0);
  const [pesoMedio, setPesoMedio] = useState(0);
  const [tecla, setTecla] = useState('');
  const [precoVenda, setPrecoVenda] = useState(0);
  const [precoCompraMedio, setPrecoCompraMedio] = useState(0);
  const [familia, setFamilia] = useState(null);
  const [value, setValue] = React.useState(0);
  const [locais, setLocais] = useState([]);
  const [locaisOptions, setLocaisOptions] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedoresOptions, setFornecedoresOptions] = useState([]);
  const [codigoCaixaNivel1, setCodigoCaixaNivel1] = useState('');
  const [codigoCaixaNivel2, setCodigoCaixaNivel2] = useState('');
  const [codigoCaixaNivel3, setCodigoCaixaNivel3] = useState('');
  const [unidadesCaixaNivel1, setUnidadesCaixaNivel1] = useState(0);
  const [unidadesCaixaNivel2, setUnidadesCaixaNivel2] = useState(0);
  const [unidadesCaixaNivel3, setUnidadesCaixaNivel3] = useState(0);
  const [pesoCaixaNivel1, setPesoCaixaNivel1] = useState(0);
  const [d1, setD1] = useState(0);
  const [diasValidade, setDiasValidade] = useState(0);
  const [exibirDiasValidade, setExibirDiasValidade] = useState(false);
  const [exibirValidadeEmbalagem, setExibirValidadeEmbalagem] = useState(false);
  const [tara, setTara] = useState(null);
  const [ipi, setIpi] = useState(0);
  const [pis, setPis] = useState(0);
  const [cofins, setCofins] = useState(0);
  const [icmsAi, setIcmsAi] = useState(0);
  const [icmsInt, setIcmsInt] = useState(0);
  const [df, setDf] = useState(0);
  const [rbc, setRbc] = useState(0);
  const [ncm, setNcm] = useState('');
  const [cst2, setCst] = useState('');
  const [cstIcmsEntrada2, setCstIcmsEntrada] = useState('');
  const [cstIcmsSaida2, setCstIcmsSaida] = useState('');
  //const [cfop, setCfop] = useState('');
  const [cstPisEntrada, setCstPisEntrada] = useState('');
  const [cstPisSaida, setCstPisSaida] = useState('');
  const [cstCofinsEntrada, setCstCofinsEntrada] = useState('');
  const [cstCofinsSaida, setCstCofinsSaida] = useState('');
  const [cest, setCest] = useState('');
  const [custos, setCustos] = useState([]);
  const [produtoBase, setProdutoBase] = useState(null);
  const [isIndustrializado, setIsIndustrializado] = useState(false);
  const [isPodeEditarPreco, setIsPodeEditarPreco] = useState(false);
  const [
    isPodeEditarPrecoConferenciaCaixaria,
    setIsPodeEditarPrecoConferenciaCaixaria,
  ] = useState(false);
  const [pesoProvavel, setPesoProvavel] = useState(0);

  const [mva, setMva] = useState(0);
  const [taxaDeLucro, setTaxaDeLucro] = useState(3);

  const [isImportado, setIsImportado] = useState(false);

  const [isPodeEditarPreco2, setIsPodeEditarPreco2] = useState(false);

  const [codigoInterno, setCodigoInterno] = useState('');

  const [tipoImpressao2, setTipoImpressao] = useState(TIPO_IMPRESSAO_KG);

  const [isFatorConversaoUp, setIsFatorConversaoUp] = useState(false);

  const [isFatorConversaoCaicharia, setIsFatorConversaoCaixaria] = useState(
    false,
  );

  const [fatorConversaoTipoCaixaria, setFatorConversaoTipoCaixaria] = useState(
    FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
  );

  const [
    fatorConversaoImportacaoXmlCompra,
    setFatorConversaoImportacaoXmlCompra,
  ] = useState(1);

  const [descricoesXml, setDescricoesXml] = useState([]);

  function getTipoImpressao() {
    if (!unidade)
      return {
        tipoImpressao: TIPO_IMPRESSAO_KG,
        disabledTipoImpressao: true,
      };
    if (unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
      return {
        tipoImpressao: TIPO_IMPRESSAO_KG,
        disabledTipoImpressao: true,
      };
    if (unidade.tipo === POR_UNIDADE)
      return {
        tipoImpressao: TIPO_IMPRESSAO_UN,
        disabledTipoImpressao: true,
      };
    if (unidade.tipo === POR_PESO_FIXO)
      return {
        tipoImpressao: tipoImpressao2,
        disabledTipoImpressao: false,
      };
    return {
      tipoImpressao: TIPO_IMPRESSAO_KG,
      disabledTipoImpressao: true,
    };
  }

  const { tipoImpressao, disabledTipoImpressao } = getTipoImpressao();

  const refDialogoCusto = useRef(null);
  const refDialogoDeletarCusto = useRef(null);
  const refsGeral = useRef([]);
  const refsBalanca = useRef([]);
  const refsImposto = useRef([]);
  const refDialogoSenha = useRef(null);

  const [carregando, setCarregando] = useState(false);

  ///////////////////////////////

  const [pisCredito, setPisCredito] = useState(0);
  /* const [isCasoEspecialPisCredito, setIsCasoEspecialPisCredito] = useState(
    false,
  ); */

  const [pisDebito, setPisDebito] = useState(0);
  /* const [isCasoEspecialPisDebito, setIsCasoEspecialPisDebito] = useState(false); */

  const [cofinsCredito, setCofinsCredito] = useState(0);
  /* const [
    isCasoEspecialCofinsCredito,
    setIsCasoEspecialCofinsCredito,
  ] = useState(false); */

  const [cofinsDebito, setCofinsDebito] = useState(0);
  /* const [isCasoEspecialCofinsDebito, setIsCasoEspecialCofinsDebito] = useState(
    false,
  ); */

  const [ipiCredito, setIpiCredito] = useState(0);
  const [isCasoEspecialIpiCredito, setIsCasoEspecialIpiCredito] = useState(
    false,
  );

  const [ipiDebito, setIpiDebito] = useState(0);
  const [isCasoEspecialIpiDebito, setIsCasoEspecialIpiDebito] = useState(false);

  const [icmsNormalCredito, setIcmsNormalCredito] = useState(0);
  /* const [
    isCasoEspecialIcmsNormalCredito,
    setIsCasoEspecialIcmsNormalCredito,
  ] = useState(false); */

  const [icmsNormalDebito, setIcmsNormalDebito] = useState(0);
  /* const [
    isCasoEspecialIcmsNormalDebito,
    setIsCasoEspecialIcmsNormalDebito,
  ] = useState(false); */

  const [icmsFronteira, setIcmsFonteira] = useState(0);
  const [
    isCasoEspecialIcmsFronteira,
    setIsCasoEspecialIcmsFronteira,
  ] = useState(false);

  const [icmsAntecipacao, setIcmsAntecipacao] = useState(0);
  const [
    isCasoEspecialIcmsAntecipacao,
    setIsCasoEspecialIcmsAntecipacao,
  ] = useState(false);

  const [descricaoPesquisaXML, setDescricaoPesquisaXML] = useState('');
  ///////////////////////////////
  /* const [permitirCriacaoOfertas, setPermitirCriacaoOfertas] = useState(true);
  const [permitirCriacaoLiberacoes, setPermitirCriacaoLiberacoes] = useState(
    true,
  ); */

  const [configs, setConfigs] = useState(null);

  const [ncmItem, setNcmItem] = useState(null);

  const [origIcms, setOrigIcms] = useState('');

  const [fcp, setFcp] = useState(0);

  const [pautaFiscalIcms, setPautaFiscalIcms] = useState(0);

  ///////////////////////////////////////////////////////

  const [descricaoLeiPis, setDescricaoLeiPis] = useState('');
  const [descricaoLeiCofins, setDescricaoLeiCofins] = useState('');
  const [descricaoLeiIcms, setDescricaoLeiIcms] = useState('');

  ///////////////////////////////////////////////////

  const [pisEntradaTravado, setPisEntradaTravado] = useState(false);
  const [pisSaidaTravado, setPisSaidaTravado] = useState(false);
  const [cofinsEntradaTravado, setCofinsEntradaTravado] = useState(false);
  const [cofinsSaidaTravado, setCofinsSaidaTravado] = useState(false);
  const [icmsEntradaTravado, setIcmsEntradaTravado] = useState(false);
  const [icmsSaidaTravado, setIcmsSaidaTravado] = useState(false);
  const [icmsAntecipacaoTravado, setIcmsAntecipacaoTravado] = useState(false);
  const [icmsFronteiraTravado, setIcmsFronteiraTravado] = useState(false);

  const [isInvencivel, setIsInvencivel] = useState(false);

  const [isComprador, setIsComprador] = useState(false);

  const [
    nomenclaturaReferenciaFornecedor,
    setNomenclaturaReferenciaFornecedor,
  ] = useState('');

  const [basePallete, setBasePallete] = useState(0);
  const [alturaPallete, setAlturaPallete] = useState(0);

  const [permitirCriacaoOfertas, setPermitirCriacaoOfertas] = useState(true);
  const [permitirCriacaoLiberacoes, setPermitirCriacaoLiberacoes] = useState(
    true,
  );
  const [descontoMaximoPrecoVenda, setDescontoMaximoPrecoVenda] = useState(50);

  const [pesoUnitario, setPesoUnitario] = useState(0);

  const [volumeUnitario, setVolumeUnitario] = useState(0);

  const [estadoFisico, setEstadoFisico] = useState(ESTADO_FISICO_CONGELADO);

  const [tipoUnidadePrecoPreCompra, setTipoUnidadePrecoPreCompra] = useState(
    null,
  );

  const [pertencenteAoMix, setPertencenteAoMix] = useState(false);

  const [
    porcentagemCustoOperacional,
    setPorcentagemCustoOperacional,
  ] = useState(100);

  const [descricaoPesquisaXMLTemp, setDescricaoPesquisaXMLTemp] = useState('');

  ///////////////////////////////////////////////////////

  //const cst = cest ? (cest.length === 7 ? '60' : cst2) : cst2;

  const cstIcmsEntrada = cest
    ? cest.length === 7
      ? '60'
      : cstIcmsEntrada2 === '60'
      ? '00'
      : cstIcmsEntrada2
    : cstIcmsEntrada2 === '60'
    ? '00'
    : cstIcmsEntrada2;
  const cstIcmsSaida = cest
    ? cest.length === 7
      ? '60'
      : cstIcmsSaida2 === '60'
      ? '00'
      : cstIcmsSaida2
    : cstIcmsSaida2 === '60'
    ? '00'
    : cstIcmsSaida2;

  const {
    isCasoEspecialCofinsCredito,
    isCasoEspecialCofinsDebito,
    isCasoEspecialIcmsNormalCredito,
    isCasoEspecialIcmsNormalDebito,
    isCasoEspecialPisCredito,
    isCasoEspecialPisDebito,
  } = getDadosImpostosComputados({
    cstIcmsEntrada,
    cstIcmsSaida,
    cstPisEntrada,
    cstPisSaida,
    cstCofinsEntrada,
    cstCofinsSaida,
    cest,
  });

  const cfop = cest ? (cest.length === 7 ? '5405' : '5102') : '5102';

  refsGeral.current = inputsGeral.map(
    (ref, index) => (refsGeral.current[index] = React.createRef()),
  );

  refsBalanca.current = inputsBalanca.map(
    (ref, index) => (refsBalanca.current[index] = React.createRef()),
  );

  refsImposto.current = inputsImpostos.map(
    (ref, index) => (refsImposto.current[index] = React.createRef()),
  );

  const isPorPesoFixo = unidade ? unidade.tipo === POR_PESO_FIXO : false;
  const isPorUnidade = unidade ? unidade.tipo === POR_UNIDADE : false;
  const isPorPesoVariavel = unidade
    ? unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
    : false;

  const precoVendaCaixaNivel1 =
    precoVenda *
    (isPorPesoFixo || isPorUnidade
      ? unidadesCaixaNivel1 * (isPorPesoFixo ? pesoMedio : 1)
      : isPorPesoVariavel
      ? pesoCaixaNivel1
      : 0);

  const precoVendaCaixaNivel2 =
    precoVenda *
    (isPorPesoFixo || isPorUnidade
      ? unidadesCaixaNivel1 *
        unidadesCaixaNivel2 *
        (isPorPesoFixo ? pesoMedio : 1)
      : isPorPesoVariavel
      ? pesoCaixaNivel1 * unidadesCaixaNivel2
      : 0);

  const precoVendaCaixaNivel3 =
    precoVenda *
    (isPorPesoFixo || isPorUnidade
      ? unidadesCaixaNivel1 *
        unidadesCaixaNivel2 *
        unidadesCaixaNivel3 *
        (isPorPesoFixo ? pesoMedio : 1)
      : isPorPesoVariavel
      ? pesoCaixaNivel1 * unidadesCaixaNivel2 * unidadesCaixaNivel3
      : 0);

  const pesoUnitario2 = isPorPesoFixo
    ? pesoMedio
    : isPorPesoVariavel
    ? 1
    : pesoUnitario;

  function getTipoUnidadePrecoPreCompra(tipoUnidadePrecoPreCompra) {
    if (tipoUnidadePrecoPreCompra === TIPO_UNIDADE_PRECO_PRE_COMPRA_KG) {
      return TIPO_UNIDADE_PRECO_PRE_COMPRA_UN;
    }

    if (
      tipoUnidadePrecoPreCompra === TIPO_UNIDADE_PRECO_PRE_COMPRA_CX1 &&
      !unidadesCaixaNivel1
    ) {
      return TIPO_UNIDADE_PRECO_PRE_COMPRA_UN;
    }

    if (
      tipoUnidadePrecoPreCompra === TIPO_UNIDADE_PRECO_PRE_COMPRA_CX2 &&
      !unidadesCaixaNivel2
    ) {
      return TIPO_UNIDADE_PRECO_PRE_COMPRA_UN;
    }

    if (
      tipoUnidadePrecoPreCompra === TIPO_UNIDADE_PRECO_PRE_COMPRA_CX3 &&
      !unidadesCaixaNivel3
    ) {
      return TIPO_UNIDADE_PRECO_PRE_COMPRA_UN;
    }

    return tipoUnidadePrecoPreCompra;
  }

  const tipoUnidadePrecoPreCompra2 =
    isPorPesoFixo || isPorPesoVariavel
      ? TIPO_UNIDADE_PRECO_PRE_COMPRA_KG
      : getTipoUnidadePrecoPreCompra(tipoUnidadePrecoPreCompra);

  const optionsUnidadePrecoPreCompra = getOptionsUnidadePrecoPreCompra(
    unidade ? unidade.tipo : '',
    unidadesCaixaNivel1,
    unidadesCaixaNivel2,
    unidadesCaixaNivel3,
  );

  useEffect(() => {
    async function getDadosLocais() {
      try {
        const data = await getListAllAPI(
          'locais_setores',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        setLocaisOptions(data.data);

        const dataFornecedores = await getListAllAPI(
          'fornecedores',
          ['id', 'asc'],
          { status: STATUS_ATIVO, ids_excluso: [1, 2] },
          [],
        );
        setFornecedoresOptions(dataFornecedores.data);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }
    if (open) getDadosLocais();
  }, [open]);

  function hidePesoMedio() {
    return !isPorPesoFixo;
  }

  function disabledPesoMedio() {
    return id > 0;
  }

  function disabledUnidade() {
    return id > 0;
  }

  function disabledCategoria() {
    return false;
  }

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validatePesoMedio() {
    let error = '';
    if (isPorPesoFixo) {
      if (pesoMedio <= 0 || Number.isNaN(pesoMedio)) {
        error = 'Este campo é obrigatório para esse tipo de unidade!';
      }
    }
    return error;
  }

  function validatePesoProvavel() {
    let error = '';
    if (isPorPesoVariavel) {
      if (pesoProvavel < 0 || Number.isNaN(pesoProvavel)) {
        error = 'Este campo é obrigatório para esse tipo de unidade!';
      }
    }
    return error;
  }

  function validateCodigo() {
    let error = '';
    if (!isPorPesoVariavel) {
      if (codigo) {
        if (codigo.length < 10) {
          error = 'Se preenchido esse campo deve ter no mínimo 10 caracteres!';
        } else if (codigo.length > 80) {
          error = 'Número de caracteres maior que o permitido (máximo 80)';
        }
        if (codigo === codigoCaixaNivel1) {
          error = 'O codigo do produto não pode ser igual o código da caixa!';
        }
        if (codigo === codigoCaixaNivel2) {
          error = 'O codigo do produto não pode ser igual o código da caixeta!';
        }
        if (codigo === codigoCaixaNivel3) {
          error =
            'O codigo do produto não pode ser igual o código da caixa-mãe!';
        }
      }
    }
    return error;
  }

  function validateCategoria() {
    let error = '';
    if (!categoria) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateTecla() {
    let error = '';
    if (tecla) {
      const cont = contAlphaNumerico(tecla);
      if (cont !== 4) error = 'A tecla associada deve ter 4 caracteres!';
    }
    return error;
  }

  function validateUnidade() {
    let error = '';
    if (!unidade) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validatePrecoVenda() {
    let error = '';
    if (precoVenda < 0 || Number.isNaN(precoVenda)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validatePrecoRevenda() {
    const error = '';
    return error;
  }

  function validateEstoqueMinimo() {
    let error = '';
    if (estoqueMinimo < 0 || Number.isNaN(estoqueMinimo)) {
      error = 'Este campo é obrigatório';
    }

    return error;
  }

  function validateFamilia() {
    const error = '';

    return error;
  }

  function validateFornecedor() {
    const error = '';
    return error;
  }

  function validateCodigoCaixaNivel1() {
    let error = '';
    if (codigoCaixaNivel1) {
      if (codigoCaixaNivel1.length < 6) {
        error = 'Se preenchido esse campo deve ter no mínimo 6 caracteres!';
      } else if (codigoCaixaNivel1.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      if (codigoCaixaNivel1 === codigo) {
        error = 'O codigo da caixa não pode ser igual o código do produto!';
      }
      if (codigoCaixaNivel1 === codigoCaixaNivel2) {
        error = 'O codigo da caixa não pode ser igual o código da caixeta!';
      }
      if (codigoCaixaNivel1 === codigoCaixaNivel3) {
        error = 'O codigo da caixa não pode ser igual o código da caixa-mãe!';
      }
    }
    return error;
  }

  function validateUnidadesCaixaNivel1() {
    let error = '';
    if (isPorPesoFixo || isPorUnidade) {
      if (unidadesCaixaNivel1 < 0 || Number.isNaN(unidadesCaixaNivel1)) {
        error = 'Este campo é obrigatório';
      }
    } else if (isPorPesoVariavel) {
      if (pesoCaixaNivel1 < 0 || Number.isNaN(pesoCaixaNivel1)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateCodigoCaixaNivel2() {
    let error = '';
    if (codigoCaixaNivel2) {
      if (codigoCaixaNivel2.length < 6) {
        error = 'Se preenchido esse campo deve ter no mínimo 6 caracteres!';
      } else if (codigoCaixaNivel2.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      if (codigoCaixaNivel2 === codigoCaixaNivel1) {
        error = 'O codigo da caixeta não pode ser igual o código da caixa!';
      }
      if (codigoCaixaNivel2 === codigo) {
        error = 'O codigo da caixeta não pode ser igual o código do produto!';
      }
      if (codigoCaixaNivel2 === codigoCaixaNivel3) {
        error = 'O codigo da caixeta não pode ser igual o código da caixa-mãe!';
      }
    }
    return error;
  }

  function validateUnidadesCaixaNivel2() {
    let error = '';
    if (isPorPesoFixo || isPorUnidade) {
      if (unidadesCaixaNivel2 < 0 || Number.isNaN(unidadesCaixaNivel2)) {
        error = 'Este campo é obrigatório';
      } else if (
        (unidadesCaixaNivel1 <= 0 || Number.isNaN(unidadesCaixaNivel1)) &&
        unidadesCaixaNivel2 > 0
      ) {
        error = 'O nível 1 deve ser preenchido!';
      }
    } else if (isPorPesoVariavel) {
      if (unidadesCaixaNivel2 < 0 || Number.isNaN(unidadesCaixaNivel2)) {
        error = 'Este campo é obrigatório';
      } else if (
        (pesoCaixaNivel1 <= 0 || Number.isNaN(pesoCaixaNivel1)) &&
        unidadesCaixaNivel2 > 0
      ) {
        error = 'O nível 1 deve ser preenchido!';
      }
    }
    return error;
  }

  function validateCodigoCaixaNivel3() {
    let error = '';
    if (codigoCaixaNivel3) {
      if (codigoCaixaNivel3.length < 6) {
        error = 'Se preenchido esse campo deve ter no mínimo 6 caracteres!';
      } else if (codigoCaixaNivel3.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      if (codigoCaixaNivel3 === codigoCaixaNivel1) {
        error = 'O codigo da caixa-mãe não pode ser igual o código da caixa!';
      }
      if (codigoCaixaNivel3 === codigoCaixaNivel2) {
        error = 'O codigo da caixa-mãe não pode ser igual o código da caixeta!';
      }
      if (codigoCaixaNivel3 === codigo) {
        error = 'O codigo da caixa-mãe não pode ser igual o código do produto!';
      }
    }
    return error;
  }

  function validateUnidadesCaixaNivel3() {
    let error = '';
    if (isPorPesoFixo || isPorUnidade) {
      if (unidadesCaixaNivel3 < 0 || Number.isNaN(unidadesCaixaNivel3)) {
        error = 'Este campo é obrigatório';
      } else if (
        (unidadesCaixaNivel2 <= 0 || Number.isNaN(unidadesCaixaNivel2)) &&
        unidadesCaixaNivel3 > 0
      ) {
        error = 'O nível 2 deve ser preenchido!';
      }
    } else if (isPorPesoVariavel) {
      if (unidadesCaixaNivel3 < 0 || Number.isNaN(unidadesCaixaNivel3)) {
        error = 'Este campo é obrigatório';
      } else if (
        (unidadesCaixaNivel2 <= 0 || Number.isNaN(unidadesCaixaNivel2)) &&
        unidadesCaixaNivel3 > 0
      ) {
        error = 'O nível 2 deve ser preenchido!';
      }
    }
    return error;
  }

  function validateIpiCredito() {
    const error = '';
    /*if (ipiCredito < 0 || Number.isNaN(ipiCredito)) {
      error = 'Este campo é obrigatório';
    } else if (ipiCredito > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }*/
    return error;
  }

  function validateIpiDebito() {
    const error = '';
    /*if (ipiDebito < 0 || Number.isNaN(ipiDebito)) {
      error = 'Este campo é obrigatório';
    } else if (ipiDebito > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }*/
    return error;
  }

  function validatePisCredito() {
    let error = '';
    if (isCasoEspecialPisCredito) {
      if (pisCredito < 0 || Number.isNaN(pisCredito)) {
        error = 'Este campo é obrigatório';
      } else if (pisCredito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validatePisDebito() {
    let error = '';
    if (isCasoEspecialPisDebito) {
      if (pisDebito < 0 || Number.isNaN(pisDebito)) {
        error = 'Este campo é obrigatório';
      } else if (pisDebito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateCofinsCredito() {
    let error = '';
    if (isCasoEspecialCofinsCredito) {
      if (cofinsCredito < 0 || Number.isNaN(cofinsCredito)) {
        error = 'Este campo é obrigatório';
      } else if (cofinsCredito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateCofinsDebito() {
    let error = '';
    if (isCasoEspecialCofinsDebito) {
      if (cofinsDebito < 0 || Number.isNaN(cofinsDebito)) {
        error = 'Este campo é obrigatório';
      } else if (cofinsDebito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateIcmsNormalCredito() {
    let error = '';
    if (isCasoEspecialIcmsNormalCredito) {
      if (icmsNormalCredito < 0 || Number.isNaN(icmsNormalCredito)) {
        error = 'Este campo é obrigatório';
      } else if (icmsNormalCredito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateIcmsNormalDebito() {
    let error = '';
    if (isCasoEspecialIcmsNormalDebito) {
      if (icmsNormalDebito < 0 || Number.isNaN(icmsNormalDebito)) {
        error = 'Este campo é obrigatório';
      } else if (icmsNormalDebito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateIcmsFronteira() {
    let error = '';
    if (isCasoEspecialIcmsFronteira) {
      if (icmsFronteira < 0 || Number.isNaN(icmsFronteira)) {
        error = 'Este campo é obrigatório';
      } else if (icmsFronteira > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateIcmsAntecipacao() {
    let error = '';
    if (isCasoEspecialIcmsAntecipacao) {
      if (icmsAntecipacao < 0 || Number.isNaN(icmsAntecipacao)) {
        error = 'Este campo é obrigatório';
      } else if (icmsAntecipacao > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateDf() {
    let error = '';
    if (df < 0 || Number.isNaN(df)) {
      error = 'Este campo é obrigatório';
    } else if (df > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateRbc() {
    let error = '';
    if (rbc < 0 || Number.isNaN(rbc)) {
      error = 'Este campo é obrigatório';
    } else if (rbc > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateNcm() {
    let error = '';
    /*  if (ncm) {
      if (ncm.length < 8) error = 'O NCM deve ter 8 dígitos';
    } */
    if (!ncmItem) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCst() {
    const error = '';
    /*  if (cst) {
      //if (cst.length < 3) error = 'O CST deve ter 3 dígitos';
    } else {
      //error = 'Este campo é obrigatório';
    } */
    return error;
  }

  function validateCfop() {
    const error = '';
    /* if (cfop) {
      if (cfop.length < 4) error = 'O CFOP deve ter 4 dígitos';
    } */
    return error;
  }

  function validateCstPisEntrada() {
    let error = '';
    if (cstPisEntrada) {
      /* if (cstPisEntrada.length < 3)
        error = 'O CST PIS ENTRADA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCstPisSaida() {
    let error = '';
    if (cstPisSaida) {
      /* if (cstPisSaida.length < 3) error = 'O CST PIS SAÍDA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCstCofinsEntrada() {
    let error = '';
    if (cstCofinsEntrada) {
      /* if (cstCofinsEntrada.length < 3)
        error = 'O CST COFINS ENTRADA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCstCofinsSaida() {
    let error = '';
    if (cstCofinsSaida) {
      /* if (cstCofinsSaida.length < 3)
        error = 'O CST COFINS SAÍDA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCest() {
    let error = '';
    if (cest) {
      if (cest.length < 7) error = 'Este campo deve conter 7 dígitos';
    }
    return error;
  }

  function verificaSeTemAcentos(str) {
    const regex = /^[^\u00C0-\u017F]+$/;
    return regex.test(str);
  }

  function validateD1() {
    const error = '';
    if (!isPorPesoFixo && !isPorUnidade) {
      //if (!d1) error = 'Este campo é obrigatório';
      /* else if (d1.length > 25) {
        error = 'Número de caracteres maior que o permitido (máximo 25)';
      } */
      /* else if (
        verificaSeTemAcentos(d1)
      ) {
        error = 'Não pode conter caracteres especiais como acentos';
      } */
    }
    return error;
  }

  function validateDiasValidade() {
    let error = '';
    if (!isPorPesoFixo && !isPorUnidade) {
      if (diasValidade < 0 || Number.isNaN(diasValidade)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateExibirDiasValidade() {
    const error = '';

    return error;
  }

  function validateExibirValidadeEmbalagem() {
    const error = '';

    return error;
  }

  function validateTara() {
    let error = '';
    if (!isPorPesoFixo && !isPorUnidade) {
      if (!tara) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateMva() {
    let error = '';
    if (mva < 0 || Number.isNaN(mva)) {
      error = 'Informe um valor válido!';
    }
    return error;
  }

  function validateTaxaDeLucro() {
    let error = '';
    if (mva < 0 || Number.isNaN(mva)) {
      error = 'Informe um valor válido!';
    }
    return error;
  }

  function validateFornecedores() {
    let error = '';
    if (fornecedores.length <= 0) {
      error = 'Deve ser informado ao menos um fornecedor!';
    }
    return error;
  }

  function validateDescricaoPesquisaXML() {
    let error = '';
    if (descricaoPesquisaXML) {
      if (descricaoPesquisaXML.length > 200) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
    }
    return error;
  }

  function validateOrigIcms() {
    let error = '';
    if (origIcms) {
      //if (origIcms.length < 1) error = 'Este campo deve ter 1 dígito';
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateFcp() {
    let error = '';
    if (fcp < 0 || Number.isNaN(fcp)) {
      error = 'Este campo é obrigatório';
    } else if (fcp > 5) {
      error = 'Não pode ultrapassar 5%!';
    }
    return error;
  }

  function validatePautaFiscal() {
    let error = '';
    if (pautaFiscalIcms < 0 || Number.isNaN(pautaFiscalIcms)) {
      error = 'Informe um valor válido!';
    }
    return error;
  }

  function validateCstIcmsEntrada() {
    let error = '';
    if (cstIcmsEntrada) {
      /* if (cstCofinsSaida.length < 3)
        error = 'O CST COFINS SAÍDA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCstIcmsSaida() {
    let error = '';
    if (cstIcmsSaida) {
      /* if (cstCofinsSaida.length < 3)
        error = 'O CST COFINS SAÍDA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateDescricaoLeiPis() {
    let error = '';
    if (descricaoLeiPis) {
      if (descricaoLeiPis.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
    }
    return error;
  }

  function validateDescricaoLeiCofins() {
    let error = '';
    if (descricaoLeiCofins) {
      if (descricaoLeiCofins.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
    }
    return error;
  }

  function validateDescricaoLeiIcms() {
    let error = '';
    if (descricaoLeiIcms) {
      if (descricaoLeiIcms.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
    }
    return error;
  }

  function validateFatorConversaoImportacaoXmlCompra() {
    let error = '';
    if (
      fatorConversaoImportacaoXmlCompra <= 0 ||
      Number.isNaN(fatorConversaoImportacaoXmlCompra)
    ) {
      error = 'Informe um valor válido (1 a 200)!';
    } else if (
      fatorConversaoImportacaoXmlCompra <= 0 ||
      fatorConversaoImportacaoXmlCompra > 200
    )
      error = 'Informe um valor válido (1 a 200)!';
    return error;
  }

  function validateDescontoMaximo() {
    let error = '';
    if (
      descontoMaximoPrecoVenda < 0 ||
      Number.isNaN(descontoMaximoPrecoVenda)
    ) {
      error = 'Este campo é obrigatório!';
    } else if (descontoMaximoPrecoVenda > 90) {
      error = 'O desconto não pode ser maior que 90%!';
    }
    return error;
  }

  function validatePesoUnitario() {
    let error = '';
    if (isPorUnidade) {
      if (pesoUnitario < 0 || Number.isNaN(pesoUnitario)) {
        error = 'Este campo é obrigatório!';
      } else if (pesoUnitario > 100) {
        error = 'Este campo não pode ser maior que 100!';
      }
    }
    return error;
  }

  function validateVolumeUnitario() {
    let error = '';
    if (volumeUnitario < 0 || Number.isNaN(volumeUnitario)) {
      error = 'Este campo é obrigatório!';
    } else if (volumeUnitario > 100) {
      error = 'Este campo não pode ser maior que 100!';
    }
    return error;
  }

  function validateNomenclaturaFornecedor() {
    let error = '';
    if (!nomenclaturaReferenciaFornecedor) {
      //error = 'Este campo é obrigatório';
    } else if (nomenclaturaReferenciaFornecedor.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 50)';
    }
    return error;
  }

  function validateBasePallet() {
    let error = '';
    if (basePallete < 0 || Number.isNaN(basePallete)) {
      error = 'Este campo é obrigatório!';
    }
    return error;
  }

  function validateAlturaPallet() {
    let error = '';
    if (alturaPallete < 0 || Number.isNaN(alturaPallete)) {
      error = 'Este campo é obrigatório!';
    }
    return error;
  }

  function validateQuantidadeTotalPallet() {
    let error = '';
    if (!(basePallete < 0 || Number.isNaN(basePallete))) {
      if (!(alturaPallete < 0 || Number.isNaN(alturaPallete))) {
        if (alturaPallete === 0 && basePallete !== 0) {
          error = 'A Quantidade Total no Pallet não pode ser 0!';
        } else if (alturaPallete !== 0 && basePallete === 0) {
          error = 'A Quantidade Total no Pallet não pode ser 0!';
        } else if (basePallete * alturaPallete > 1000) {
          error = 'A Quantidade Total no Pallet não pode ser maior que 1000!';
        }
      }
    }
    return error;
  }

  function validateEstadoFisico() {
    let error = '';
    if (!estadoFisico) {
      error = 'Este campo é obrigatório!';
    }
    return error;
  }

  function validateDescricaoPesquisaXMLTemp() {
    let error = '';
    if (descricaoPesquisaXMLTemp) {
      if (descricaoPesquisaXMLTemp.length > 200) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
    }
    return error;
  }

  function getErros() {
    const errosGeral = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    errosGeral[0] = validateNome();
    errosGeral[1] = validateCodigo();
    errosGeral[2] = validateUnidade();
    errosGeral[3] = validateCategoria();
    errosGeral[4] = validateEstoqueMinimo();
    errosGeral[5] = validatePesoMedio();
    errosGeral[6] = validateTecla();
    errosGeral[7] = validatePrecoVenda();
    errosGeral[8] = validatePrecoRevenda();
    errosGeral[9] = validateFamilia();
    errosGeral[10] = validateFornecedor();
    errosGeral[11] = '';
    errosGeral[12] = validateCodigoCaixaNivel1();
    errosGeral[13] = validateUnidadesCaixaNivel1();
    errosGeral[14] = validateCodigoCaixaNivel2();
    errosGeral[15] = validateUnidadesCaixaNivel2();
    errosGeral[16] = validateCodigoCaixaNivel3();
    errosGeral[17] = validateUnidadesCaixaNivel3();
    errosGeral[18] = '';
    errosGeral[19] = validatePesoProvavel();
    errosGeral[20] = validateTaxaDeLucro();
    errosGeral[21] = validateFornecedores();
    errosGeral[22] = validateDescricaoPesquisaXML();
    errosGeral[23] = validateFatorConversaoImportacaoXmlCompra();
    errosGeral[24] = validateEstadoFisico();
    errosGeral[25] = validateDescricaoPesquisaXMLTemp();
    // errosGeral[24] = validateDescontoMaximo();
    //errosGeral[25] = validatePesoUnitario();
    //errosGeral[26] = validateVolumeUnitario();
    //errosGeral[27] = validateNomenclaturaFornecedor();
    //errosGeral[28] = validateBasePallet();
    //errosGeral[29] = validateAlturaPallet();

    const errosBalanca = ['', '', '', '', ''];
    errosBalanca[0] = validateD1();
    errosBalanca[1] = validateDiasValidade();
    errosBalanca[2] = validateTara();
    errosBalanca[3] = validateExibirDiasValidade();
    errosBalanca[4] = validateExibirValidadeEmbalagem();

    const errosImpostos = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    errosImpostos[0] = validateIpiCredito();
    errosImpostos[1] = validateIpiDebito();
    errosImpostos[2] = validatePisCredito();
    errosImpostos[3] = validatePisDebito();
    errosImpostos[4] = validateCofinsCredito();
    errosImpostos[5] = validateCofinsDebito();
    errosImpostos[6] = validateIcmsNormalCredito();
    errosImpostos[7] = validateIcmsNormalDebito();
    errosImpostos[8] = validateIcmsFronteira();
    errosImpostos[9] = validateIcmsAntecipacao();

    errosImpostos[10] = validateDf();
    errosImpostos[11] = validateRbc();
    errosImpostos[12] = validateNcm();
    errosImpostos[13] = validateCst();
    errosImpostos[14] = validateCfop();

    errosImpostos[15] = validateCstPisEntrada();
    errosImpostos[16] = validateCstPisSaida();
    errosImpostos[17] = validateCstCofinsEntrada();
    errosImpostos[18] = validateCstCofinsSaida();
    errosImpostos[19] = validateCest();
    errosImpostos[20] = validateMva();
    errosImpostos[21] = validateFcp();
    errosImpostos[22] = validateOrigIcms();
    errosImpostos[23] = validatePautaFiscal();
    errosImpostos[24] = validateCstIcmsEntrada();
    errosImpostos[25] = validateCstIcmsSaida();

    errosImpostos[26] = validateDescricaoLeiPis();
    errosImpostos[27] = validateDescricaoLeiCofins();
    errosImpostos[28] = validateDescricaoLeiIcms();

    const errosComprador = ['', '', '', '', ''];
    errosComprador[0] = validateDescontoMaximo();
    errosComprador[1] = validatePesoUnitario();
    errosComprador[2] = validateNomenclaturaFornecedor();
    errosComprador[3] = validateBasePallet();
    errosComprador[4] = validateAlturaPallet();
    errosComprador[5] = validateVolumeUnitario();
    errosComprador[6] = validateQuantidadeTotalPallet();

    return {
      errosGeral,
      errosBalanca,
      errosImpostos,
      errosComprador,
    };
  }

  const {
    errosGeral,
    errosBalanca,
    errosImpostos,
    errosComprador,
  } = getErros();

  function hasError() {
    for (let i = 0; i < errosGeral.length; i += 1) {
      if (errosGeral[i] !== '') return true;
    }
    for (let i = 0; i < errosBalanca.length; i += 1) {
      if (errosBalanca[i] !== '') return true;
    }
    for (let i = 0; i < errosImpostos.length; i += 1) {
      if (errosImpostos[i] !== '') return true;
    }
    for (let i = 0; i < errosComprador.length; i += 1) {
      if (errosComprador[i] !== '') return true;
    }
    return false;
  }

  function contarErros() {
    let qtdeErrosGeral = 0;
    let qtdeErrosBalanca = 0;
    let qtdeErrosImposto = 0;
    let qtdeErrosComprador = 0;
    for (let i = 0; i < errosGeral.length; i += 1) {
      if (errosGeral[i] !== '') {
        qtdeErrosGeral += 1;
      }
    }
    for (let i = 0; i < errosBalanca.length; i += 1) {
      if (errosBalanca[i] !== '') {
        qtdeErrosBalanca += 1;
      }
    }
    for (let i = 0; i < errosImpostos.length; i += 1) {
      if (errosImpostos[i] !== '') {
        qtdeErrosImposto += 1;
      }
    }
    for (let i = 0; i < errosComprador.length; i += 1) {
      if (errosComprador[i] !== '') {
        qtdeErrosComprador += 1;
      }
    }
    return {
      qtdeErrosGeral,
      qtdeErrosBalanca,
      qtdeErrosImposto,
      qtdeErrosComprador,
    };
  }

  const erroExistente = hasError();

  const {
    qtdeErrosGeral,
    qtdeErrosBalanca,
    qtdeErrosImposto,
    qtdeErrosComprador,
  } = contarErros();

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function getPermissaoAbaComprador() {
    try {
      const response = await api.get(`/obter-cargo-usuario/${getUserId()}`);
      const { status, cargo } = response.data;
      if (status) {
        return cargo.sistema_aba_comprador_no_produto ? true : false;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  async function reiniciar() {
    try {
      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        ['cidade.estado'],
      );

      const dataTaraPadrao = await getListAllAPI(
        'taras_balanca',
        ['id', 'asc'],
        { id: 1 },
        [],
      );

      setConfigs(dataConfig.data[0]);
      setDescricaoPesquisaXML('');
      setId(DEFAULT_ID);
      setNome(inputsGeral[0].defaultValue);
      setCodigo(inputsGeral[1].defaultValue);
      setUnidade(inputsGeral[2].defaultValue);
      setCategoria(inputsGeral[3].defaultValue);
      setEstoqueMinimo(inputsGeral[4].defaultValue);
      setPesoMedio(inputsGeral[5].defaultValue);
      setTecla(inputsGeral[6].defaultValue);
      setPrecoVenda(inputsGeral[7].defaultValue);
      setFamilia(inputsGeral[9].defaultValue);
      setPrecoCompraMedio(0);
      setCodigoCaixaNivel1(inputsGeral[12].defaultValue);
      setUnidadesCaixaNivel1(inputsGeral[13].defaultValue);
      setCodigoCaixaNivel2(inputsGeral[14].defaultValue);
      setUnidadesCaixaNivel2(inputsGeral[15].defaultValue);
      setCodigoCaixaNivel3(inputsGeral[16].defaultValue);
      setUnidadesCaixaNivel3(inputsGeral[17].defaultValue);
      setLocais([]);
      setIpi(dataConfig.data[0].ipi);
      setPis(dataConfig.data[0].pis);
      setCofins(dataConfig.data[0].cofins);
      setIcmsAi(dataConfig.data[0].icms_ai);
      setIcmsInt(dataConfig.data[0].icms_int);
      setDf(0);
      setRbc(dataConfig.data[0].rbc);
      setNcm('');
      setCst('');
      //setCfop('');
      setCstPisEntrada('');
      setCstPisSaida('');
      setCstCofinsEntrada('');
      setCstCofinsSaida('');
      setCest('');
      setD1('');
      setDiasValidade(0);
      setExibirDiasValidade(false);
      setExibirValidadeEmbalagem(false);
      setTara(dataTaraPadrao.data[0]);
      setCustos([]);
      setIsIndustrializado(false);
      setProdutoBase(null);
      setIsPodeEditarPreco(false);
      setPesoProvavel(0);
      setPesoCaixaNivel1(0);
      setMva(0);
      setTaxaDeLucro(3);
      setIsImportado(false);
      setIsPodeEditarPreco2(
        dataConfig.data[0].is_permitir_editar_preco_venda_produto,
      );
      setCodigoInterno('');
      setCarregando(false);
      //
      setPisCredito(0);
      //setIsCasoEspecialPisCredito(false);
      setPisDebito(0);
      //setIsCasoEspecialPisDebito(false);
      setCofinsCredito(0);
      //setIsCasoEspecialCofinsCredito(false);
      setCofinsDebito(0);
      //setIsCasoEspecialCofinsDebito(false);
      setIpiCredito(0);
      setIsCasoEspecialIpiCredito(false);
      setIpiDebito(0);
      setIsCasoEspecialIpiDebito(false);
      setIcmsNormalCredito(0);
      //setIsCasoEspecialIcmsNormalCredito(false);
      setIcmsNormalDebito(0);
      //setIsCasoEspecialIcmsNormalDebito(false);
      setIcmsFonteira(0);
      setIsCasoEspecialIcmsFronteira(false);
      setIcmsAntecipacao(0);
      setIsCasoEspecialIcmsAntecipacao(false);
      setOrigIcms('');
      setFcp(0);
      setPautaFiscalIcms(0);
      setCstIcmsEntrada('');
      setCstIcmsSaida('');
      //setPermitirCriacaoOfertas(true);
      //setPermitirCriacaoLiberacoes(true);
      setNcmItem(null);
      setTipoImpressao(TIPO_IMPRESSAO_KG);

      setPisEntradaTravado(false);
      setPisSaidaTravado(false);
      setCofinsEntradaTravado(false);
      setCofinsSaidaTravado(false);
      setIcmsEntradaTravado(false);
      setIcmsSaidaTravado(false);
      setIcmsAntecipacaoTravado(false);
      setIcmsFronteiraTravado(false);

      setDescricaoLeiCofins('');
      setDescricaoLeiPis('');
      setDescricaoLeiIcms('');

      setFatorConversaoImportacaoXmlCompra(1);

      setIsFatorConversaoCaixaria(false);
      setIsFatorConversaoUp(false);

      setFatorConversaoTipoCaixaria(FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM);

      setIsInvencivel(false);

      setIsComprador(await getPermissaoAbaComprador());

      setNomenclaturaReferenciaFornecedor('');
      setBasePallete(0);
      setAlturaPallete(0);
      setPermitirCriacaoOfertas(true);
      setPermitirCriacaoLiberacoes(true);
      setDescontoMaximoPrecoVenda(0);

      setPesoUnitario(0);

      setVolumeUnitario(0);

      setEstadoFisico(ESTADO_FISICO_CONGELADO);

      setTipoUnidadePrecoPreCompra(null);

      setPertencenteAoMix(false);

      setPorcentagemCustoOperacional(100);

      setDescricoesXml([]);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function reiniciarApartirFamilia(idFamilia) {
    try {
      const dataFamilias = await getListAllAPI(
        'familias_produtos',
        ['id', 'asc'],
        { id: [idFamilia] },
        [],
      );

      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        ['cidade.estado'],
      );

      const dataTaraPadrao = await getListAllAPI(
        'taras_balanca',
        ['id', 'asc'],
        { id: 1 },
        [],
      );

      if (dataFamilias.data.length <= 0) throw 'Esta família não existe!';
      setDescricaoPesquisaXML('');
      setConfigs(dataConfig.data[0]);
      setId(DEFAULT_ID);
      setNome(inputsGeral[0].defaultValue);
      setCodigo(inputsGeral[1].defaultValue);
      setUnidade(inputsGeral[2].defaultValue);
      setCategoria(inputsGeral[3].defaultValue);
      setEstoqueMinimo(inputsGeral[4].defaultValue);
      setPesoMedio(inputsGeral[5].defaultValue);
      setTecla(inputsGeral[6].defaultValue);
      setPrecoVenda(dataFamilias.data[0].preco_venda);
      setFamilia(dataFamilias.data[0]);
      setPrecoCompraMedio(0);
      setCodigoCaixaNivel1(inputsGeral[12].defaultValue);
      setUnidadesCaixaNivel1(inputsGeral[13].defaultValue);
      setCodigoCaixaNivel2(inputsGeral[14].defaultValue);
      setUnidadesCaixaNivel2(inputsGeral[15].defaultValue);
      setCodigoCaixaNivel3(inputsGeral[16].defaultValue);
      setUnidadesCaixaNivel3(inputsGeral[17].defaultValue);
      setLocais([]);
      setFornecedores([]);
      setIpi(dataConfig.data[0].ipi);
      setPis(dataConfig.data[0].pis);
      setCofins(dataConfig.data[0].cofins);
      setIcmsAi(dataConfig.data[0].icms_ai);
      setIcmsInt(dataConfig.data[0].icms_int);
      setDf(0);
      setRbc(dataConfig.data[0].rbc);
      setNcm('');
      setCst('');
      //setCfop('');
      setCstPisEntrada('');
      setCstPisSaida('');
      setCstCofinsEntrada('');
      setCstCofinsSaida('');
      setCest('');
      setD1('');
      setDiasValidade(0);
      setExibirDiasValidade(false);
      setExibirValidadeEmbalagem(false);
      setTara(dataTaraPadrao.data[0]);
      setCustos([]);
      setIsIndustrializado(false);
      setProdutoBase(null);
      setIsPodeEditarPreco(false);
      setPesoProvavel(0);
      setPesoCaixaNivel1(0);
      setMva(0);
      setTaxaDeLucro(3);
      setIsImportado(false);
      setIsPodeEditarPreco2(
        dataConfig.data[0].is_permitir_editar_preco_venda_produto,
      );
      setCodigoInterno('');
      setCarregando(false);
      //
      setPisCredito(0);
      //setIsCasoEspecialPisCredito(false);
      setPisDebito(0);
      //setIsCasoEspecialPisDebito(false);
      setCofinsCredito(0);
      //setIsCasoEspecialCofinsCredito(false);
      setCofinsDebito(0);
      //setIsCasoEspecialCofinsDebito(false);
      setIpiCredito(0);
      setIsCasoEspecialIpiCredito(false);
      setIpiDebito(0);
      setIsCasoEspecialIpiDebito(false);
      setIcmsNormalCredito(0);
      //setIsCasoEspecialIcmsNormalCredito(false);
      setIcmsNormalDebito(0);
      //setIsCasoEspecialIcmsNormalDebito(false);
      setIcmsFonteira(0);
      setIsCasoEspecialIcmsFronteira(false);
      setIcmsAntecipacao(0);
      setIsCasoEspecialIcmsAntecipacao(false);
      setOrigIcms('');
      setFcp(0);
      setPautaFiscalIcms(0);
      setCstIcmsEntrada('');
      setCstIcmsSaida('');
      //setPermitirCriacaoOfertas(true);
      //setPermitirCriacaoLiberacoes(true);
      setNcmItem(null);
      setTipoImpressao(TIPO_IMPRESSAO_KG);

      setPisEntradaTravado(false);
      setPisSaidaTravado(false);
      setCofinsEntradaTravado(false);
      setCofinsSaidaTravado(false);
      setIcmsEntradaTravado(false);
      setIcmsSaidaTravado(false);
      setIcmsAntecipacaoTravado(false);
      setIcmsFronteiraTravado(false);

      setDescricaoLeiCofins('');
      setDescricaoLeiPis('');
      setDescricaoLeiIcms('');

      setFatorConversaoImportacaoXmlCompra(1);

      setIsFatorConversaoCaixaria(false);
      setIsFatorConversaoUp(false);
      setFatorConversaoTipoCaixaria(FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM);
      setIsInvencivel(false);
      setIsComprador(await getPermissaoAbaComprador());

      setNomenclaturaReferenciaFornecedor('');
      setBasePallete(0);
      setAlturaPallete(0);
      setPermitirCriacaoOfertas(true);
      setPermitirCriacaoLiberacoes(true);
      setDescontoMaximoPrecoVenda(0);

      setPesoUnitario(0);

      setVolumeUnitario(0);

      setEstadoFisico(ESTADO_FISICO_CONGELADO);

      setTipoUnidadePrecoPreCompra(null);

      setPertencenteAoMix(false);

      setPorcentagemCustoOperacional(100);

      setDescricoesXml([]);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  function adaptarCustosRetornoApi(custos) {
    const newCustos = custos.map((item) => ({
      nome: item.nome,
      preco: item.preco,
      peso: item.peso,
    }));
    return newCustos;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id: [id] },
        [
          'unidade',
          'categoria',
          'familia',
          'dadosBalanca.taraobj',
          'dadosImposto.itemNcm',
          'fornecedorPadrao',
          'locais',
          'fornecedores',
          'custos',
          'produtoBase',
          'descricoesXml',
        ],
      );

      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        ['cidade.estado'],
      );

      if (data.data.length > 0) {
        setDescricaoPesquisaXML(data.data[0].descricao_pesquisa_xml);
        setConfigs(dataConfig.data[0]);
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setCodigo(data.data[0].codigo);
        setUnidade(data.data[0].unidade);
        setCategoria(data.data[0].categoria);
        setEstoqueMinimo(data.data[0].estoque_minimo);
        setPesoMedio(data.data[0].peso_medio);
        setTecla(data.data[0].tecla);
        setPrecoVenda(data.data[0].preco_venda);
        setFamilia(data.data[0].familia);
        setPrecoCompraMedio(data.data[0].precoCompraMedioImpostos);
        setCodigoCaixaNivel1(data.data[0].codigo_caixa_nivel_1);
        setUnidadesCaixaNivel1(data.data[0].unidades_caixa_nivel_1);
        setCodigoCaixaNivel2(data.data[0].codigo_caixa_nivel_2);
        setUnidadesCaixaNivel2(data.data[0].unidades_caixa_nivel_2);
        setCodigoCaixaNivel3(data.data[0].codigo_caixa_nivel_3);
        setUnidadesCaixaNivel3(data.data[0].unidades_caixa_nivel_3);
        setLocais(data.data[0].locais);
        setFornecedores(data.data[0].fornecedores);
        setIpi(data.data[0].dadosImposto[0].ipi);
        setPis(data.data[0].dadosImposto[0].pis);
        setCofins(data.data[0].dadosImposto[0].cofins);
        setIcmsAi(data.data[0].dadosImposto[0].icms_ai);
        setIcmsInt(data.data[0].dadosImposto[0].icms_int);
        setDf(data.data[0].dadosImposto[0].df);
        setRbc(data.data[0].dadosImposto[0].rbc);
        setNcm(data.data[0].dadosImposto[0].ncm);
        setCst(data.data[0].dadosImposto[0].cst);
        //setCfop(data.data[0].dadosImposto[0].cfop);
        setCstPisEntrada(data.data[0].dadosImposto[0].cst_pis_entrada);
        setCstPisSaida(data.data[0].dadosImposto[0].cst_pis_saida);
        setCstCofinsEntrada(data.data[0].dadosImposto[0].cst_cofins_entrada);
        setCstCofinsSaida(data.data[0].dadosImposto[0].cst_cofins_saida);
        setCest(data.data[0].dadosImposto[0].cest);
        setD1(data.data[0].dadosBalanca[0].d1);
        setDiasValidade(data.data[0].dadosBalanca[0].dias_validade);
        setExibirDiasValidade(
          data.data[0].dadosBalanca[0].exibir_dias_validade,
        );
        setExibirValidadeEmbalagem(
          data.data[0].dadosBalanca[0].exibir_data_embalagem,
        );
        setTara(data.data[0].dadosBalanca[0].taraobj);
        setCustos(recalcularIds(adaptarCustosRetornoApi(data.data[0].custos)));
        setLocais(data.data[0].locais);
        setIsIndustrializado(
          data.data[0].is_industrializado
            ? data.data[0].is_industrializado
            : false,
        );
        setProdutoBase(data.data[0].produtoBase);
        setIsPodeEditarPreco(false);
        //setPesoMedio(data.data[0].peso_provavel);
        setPesoCaixaNivel1(data.data[0].peso_caixa_nivel_1);
        setMva(data.data[0].mva);
        setTaxaDeLucro(data.data[0].taxa_de_lucro);
        setIsImportado(data.data[0].importado);
        setIsPodeEditarPreco2(
          dataConfig.data[0].is_permitir_editar_preco_venda_produto,
        );
        setCodigoInterno(data.data[0].codigo_interno);
        setCarregando(false);
        //
        setPisCredito(data.data[0].dadosImposto[0].pis_credito);
        /* setIsCasoEspecialPisCredito(
          data.data[0].dadosImposto[0].is_caso_especial_pis_credito,
        ); */
        setPisDebito(data.data[0].dadosImposto[0].pis_debito);
        /* setIsCasoEspecialPisDebito(
          data.data[0].dadosImposto[0].is_caso_especial_pis_debito,
        ); */
        setCofinsCredito(data.data[0].dadosImposto[0].cofins_credito);
        /* setIsCasoEspecialCofinsCredito(
          data.data[0].dadosImposto[0].is_caso_especial_cofins_credito,
        ); */
        setCofinsDebito(data.data[0].dadosImposto[0].cofins_debito);
        /* setIsCasoEspecialCofinsDebito(
          data.data[0].dadosImposto[0].is_caso_especial_cofins_debito,
        ); */
        setIpiCredito(data.data[0].dadosImposto[0].ipi_credito);
        setIsCasoEspecialIpiCredito(
          data.data[0].dadosImposto[0].is_caso_especial_ipi_credito,
        );
        setIpiDebito(data.data[0].dadosImposto[0].ipi_debito);
        setIsCasoEspecialIpiDebito(
          data.data[0].dadosImposto[0].is_caso_especial_ipi_debito,
        );
        setIcmsNormalCredito(data.data[0].dadosImposto[0].icms_normal_credito);
        /* setIsCasoEspecialIcmsNormalCredito(
          data.data[0].dadosImposto[0].is_caso_especial_icms_normal_credito,
        ); */
        setIcmsNormalDebito(data.data[0].dadosImposto[0].icms_normal_debito);
        /* setIsCasoEspecialIcmsNormalDebito(
          data.data[0].dadosImposto[0].is_caso_especial_icms_normal_debito,
        ); */
        setIcmsFonteira(data.data[0].dadosImposto[0].icms_fronteira);
        setIsCasoEspecialIcmsFronteira(
          data.data[0].dadosImposto[0].is_caso_especial_icms_fronteira,
        );
        setIcmsAntecipacao(data.data[0].dadosImposto[0].icms_antecipacao);
        setIsCasoEspecialIcmsAntecipacao(
          data.data[0].dadosImposto[0].is_caso_especial_icms_antecipacao,
        );

        ////////////////////

        setOrigIcms(data.data[0].dadosImposto[0].orig);
        setFcp(
          data.data[0].dadosImposto[0].fcp
            ? data.data[0].dadosImposto[0].fcp
            : 0,
        );
        setPautaFiscalIcms(
          data.data[0].dadosImposto[0].pauta_fiscal
            ? data.data[0].dadosImposto[0].pauta_fiscal
            : 0,
        );

        setCstIcmsEntrada(data.data[0].dadosImposto[0].cst_icms_entrada);
        setCstIcmsSaida(data.data[0].dadosImposto[0].cst_icms_saida);

        setNcmItem(data.data[0].dadosImposto[0].itemNcm);

        //////////////

        setDescricaoLeiPis(data.data[0].dadosImposto[0].descricao_lei_pis);
        setDescricaoLeiCofins(
          data.data[0].dadosImposto[0].descricao_lei_cofins,
        );
        setDescricaoLeiIcms(data.data[0].dadosImposto[0].descricao_lei_icms);

        //////////////

        setPisEntradaTravado(
          data.data[0].dadosImposto[0].pis_entrada_travado ? true : false,
        );
        setPisSaidaTravado(
          data.data[0].dadosImposto[0].pis_saida_travado ? true : false,
        );
        setCofinsEntradaTravado(
          data.data[0].dadosImposto[0].cofins_entrada_travado ? true : false,
        );
        setCofinsSaidaTravado(
          data.data[0].dadosImposto[0].cofins_saida_travado ? true : false,
        );
        setIcmsEntradaTravado(
          data.data[0].dadosImposto[0].icms_entrada_travado ? true : false,
        );
        setIcmsSaidaTravado(
          data.data[0].dadosImposto[0].icms_saida_travado ? true : false,
        );
        setIcmsAntecipacaoTravado(
          data.data[0].dadosImposto[0].icms_antecipacao_travado ? true : false,
        );
        setIcmsFronteiraTravado(
          data.data[0].dadosImposto[0].icms_fronteira_travado ? true : false,
        );

        setFatorConversaoImportacaoXmlCompra(
          data.data[0].fator_conversao_importacao_xml_compra,
        );

        ///////////////////

        /* setPermitirCriacaoOfertas(
          data.data[0].permitir_criacao_ofertas ? true : false,
        );
        setPermitirCriacaoLiberacoes(
          data.data[0].permitir_criacao_liberacoes ? true : false,
        ); */

        setTipoImpressao(data.data[0].tipo_impressao);

        setIsFatorConversaoCaixaria(data.data[0].is_fator_conversao_caixaria);
        setIsFatorConversaoUp(data.data[0].is_fator_conversao_up);

        setFatorConversaoTipoCaixaria(
          data.data[0].fator_conversao_tipo_caixaria,
        );

        setIsInvencivel(data.data[0].is_invencivel);
        setIsComprador(await getPermissaoAbaComprador());

        setNomenclaturaReferenciaFornecedor(
          data.data[0].nomenclatura_no_fornecedor,
        );
        setBasePallete(data.data[0].pallet_base);
        setAlturaPallete(data.data[0].pallet_altura);
        setPermitirCriacaoOfertas(
          data.data[0].permitir_criacao_ofertas ? true : false,
        );
        setPermitirCriacaoLiberacoes(
          data.data[0].permitir_criacao_liberacoes ? true : false,
        );
        setDescontoMaximoPrecoVenda(data.data[0].desconto_maximo_preco_venda);

        setPesoUnitario(data.data[0].peso_unitario_pu);

        setVolumeUnitario(data.data[0].volume_unitario_vu);

        setEstadoFisico(data.data[0].estado_fisico);

        setTipoUnidadePrecoPreCompra(
          data.data[0].tipo_unidade_preco_pre_compra,
        );

        setPertencenteAoMix(data.data[0].pertencente_ao_mix ? true : false);

        setPorcentagemCustoOperacional(
          data.data[0].porcentagem_custo_operacional,
        );

        setDescricoesXml(
          data.data[0].descricoesXml
            ? data.data[0].descricoesXml.map(
                (item) => item.descricao_pesquisa_xml,
              )
            : [],
        );
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  function getIdsOptions() {
    const ids = [];
    for (let i = 0; i < locais.length; i += 1) {
      ids.push(locais[i].id);
    }
    return ids;
  }

  function getIdsOptionsFornecedores() {
    const ids = [];
    for (let i = 0; i < fornecedores.length; i += 1) {
      ids.push(fornecedores[i].id);
    }
    return ids;
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          const unidades_caixa_nivel_1_local =
            isPorPesoFixo || isPorUnidade
              ? unidadesCaixaNivel1
              : isPorPesoVariavel
              ? 0
              : 0;

          const unidades_caixa_nivel_2_local =
            isPorPesoFixo || isPorUnidade
              ? unidadesCaixaNivel2
              : isPorPesoVariavel
              ? unidadesCaixaNivel2
              : 0;

          const unidades_caixa_nivel_3_local =
            isPorPesoFixo || isPorUnidade
              ? unidadesCaixaNivel3
              : isPorPesoVariavel
              ? unidadesCaixaNivel3
              : 0;

          const peso_caixa_nivel_1_local = isPorPesoVariavel
            ? pesoCaixaNivel1
            : 0;

          let fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;

          if (fatorConversaoTipoCaixaria) {
            switch (fatorConversaoTipoCaixaria) {
              case FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM:
                fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;
                break;
              case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1:
                if (unidades_caixa_nivel_1_local || peso_caixa_nivel_1_local) {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1;
                } else {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;
                }
                break;
              case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2:
                if (unidades_caixa_nivel_2_local) {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2;
                } else {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;
                }
                break;
              case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3:
                if (unidades_caixa_nivel_3_local) {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3;
                } else {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;
                }
                break;
              default:
                break;
            }
          }

          const produtoCriado = await createAPI('produtos_impostos_balancas', {
            nome,
            codigo,
            unidade_id: unidade.id,
            categoria_id: categoria.id,
            estoque_minimo: estoqueMinimo,
            peso_medio: pesoMedio,
            tecla,
            preco_venda: precoVenda,
            preco_revenda: precoVenda,
            familia_produto_id: familia ? familia.id : null,
            fornecedor_id: 1,
            desconto_caixa: 0,
            codigo_caixa_nivel_1:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? codigoCaixaNivel1 || null
                : null,
            unidades_caixa_nivel_1: unidades_caixa_nivel_1_local,
            preco_venda_caixa_nivel_1: 0,
            codigo_caixa_nivel_2:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? codigoCaixaNivel2 || null
                : null,
            unidades_caixa_nivel_2:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? unidadesCaixaNivel2
                : 0,
            preco_venda_caixa_nivel_2: 0,
            codigo_caixa_nivel_3:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? codigoCaixaNivel3
                : null,
            unidades_caixa_nivel_3:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? unidadesCaixaNivel3
                : 0,
            preco_venda_caixa_nivel_3: 0,
            locais: getIdsOptions(),
            fornecedores: getIdsOptionsFornecedores(),
            ipi,
            pis,
            cofins,
            icms_ai: icmsAi,
            icms_int: icmsInt,
            ncm: ncmItem.ncm,
            df,
            cst: cstIcmsSaida,
            cfop,
            rbc,
            cst_pis_entrada: cstPisEntrada,
            cst_pis_saida: cstPisSaida,
            cst_cofins_entrada: cstCofinsEntrada,
            cst_cofins_saida: cstCofinsSaida,
            cest,
            d1: !isPorPesoFixo && !isPorUnidade ? d1 : 'vazio',
            dias_validade: diasValidade,
            exibir_dias_validade: exibirDiasValidade,
            exibir_data_embalagem: exibirValidadeEmbalagem,
            tara_balanca_id: tara.id,
            custos,
            is_industrializado: isIndustrializado,
            produto_base_id: produtoBase ? produtoBase.id : null,
            peso_provavel: isPorPesoVariavel ? pesoProvavel : 0,
            peso_caixa_nivel_1: peso_caixa_nivel_1_local,
            peso_caixa_nivel_2: 0,
            peso_caixa_nivel_3: 0,
            mva,
            taxa_de_lucro: taxaDeLucro,
            importado: isImportado,
            //
            pis_credito: pisCredito,
            is_caso_especial_pis_credito: isCasoEspecialPisCredito,
            pis_debito: pisDebito,
            is_caso_especial_pis_debito: isCasoEspecialPisDebito,
            cofins_credito: cofinsCredito,
            is_caso_especial_cofins_credito: isCasoEspecialCofinsCredito,
            cofins_debito: cofinsDebito,
            is_caso_especial_cofins_debito: isCasoEspecialCofinsDebito,
            ipi_credito: ipiCredito,
            is_caso_especial_ipi_credito: isCasoEspecialIpiCredito,
            ipi_debito: ipiDebito,
            is_caso_especial_ipi_debito: isCasoEspecialIpiDebito,
            icms_normal_credito: icmsNormalCredito,
            is_caso_especial_icms_normal_credito: isCasoEspecialIcmsNormalCredito,
            icms_normal_debito: icmsNormalDebito,
            is_caso_especial_icms_normal_debito: isCasoEspecialIcmsNormalDebito,
            icms_fronteira: icmsFronteira,
            is_caso_especial_icms_fronteira: isCasoEspecialIcmsFronteira,
            icms_antecipacao: icmsAntecipacao,
            is_caso_especial_icms_antecipacao: isCasoEspecialIcmsAntecipacao,
            descricao_pesquisa_xml: descricaoPesquisaXML
              ? descricaoPesquisaXML.trim().replace(/\s{2,}/g, ' ')
              : '',

            fcp,
            orig: origIcms,
            pauta_fiscal: pautaFiscalIcms,
            ////////////
            //permitir_criacao_ofertas: permitirCriacaoOfertas,
            //permitir_criacao_liberacoes: permitirCriacaoLiberacoes,
            cst_icms_entrada: cstIcmsEntrada,
            cst_icms_saida: cstIcmsSaida,
            item_ncm_id: ncmItem.id,
            //
            descricao_lei_pis: descricaoLeiPis,
            descricao_lei_cofins: descricaoLeiCofins,
            descricao_lei_icms: descricaoLeiIcms,
            //
            pis_entrada_travado: pisEntradaTravado,
            pis_saida_travado: pisSaidaTravado,
            cofins_entrada_travado: cofinsEntradaTravado,
            cofins_saida_travado: cofinsSaidaTravado,
            icms_entrada_travado: icmsEntradaTravado,
            icms_saida_travado: icmsSaidaTravado,
            icms_antecipacao_travado: icmsAntecipacaoTravado,
            icms_fronteira_travado: icmsFronteiraTravado,
            tipo_impressao: tipoImpressao,
            fator_conversao_importacao_xml_compra: isPorUnidade
              ? isFatorConversaoUp
                ? fatorConversaoImportacaoXmlCompra
                : 1
              : 1,
            is_fator_conversao_up: isPorUnidade ? isFatorConversaoUp : false,
            is_fator_conversao_caixaria:
              unidades_caixa_nivel_1_local || peso_caixa_nivel_1_local
                ? isFatorConversaoCaicharia
                : false,
            fator_conversao_tipo_caixaria,
            is_invencivel: isInvencivel,
            //
            permitir_criacao_ofertas: permitirCriacaoOfertas,
            permitir_criacao_liberacoes: permitirCriacaoLiberacoes,
            desconto_maximo_preco_venda: descontoMaximoPrecoVenda,
            pallet_base: basePallete,
            pallet_altura: alturaPallete,
            nomenclatura_no_fornecedor: nomenclaturaReferenciaFornecedor,
            tipo_unidade_preco_pre_compra: tipoUnidadePrecoPreCompra2,
            peso_unitario_pu: pesoUnitario2,
            volume_unitario_vu: volumeUnitario,
            estado_fisico: estadoFisico,
            pertencente_ao_mix: pertencenteAoMix,
            porcentagem_custo_operacional: porcentagemCustoOperacional,
            descricoesXml,
          });
          setCarregando(false);
          if (produtoCriado.data.id) {
            enqueueSnackbar('Produto criado com sucesso!', {
              variant: 'success',
            });
            idLocal = produtoCriado.data.id;
          } else {
            enqueueSnackbar(produtoCriado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        } else {
          const unidades_caixa_nivel_1_local =
            isPorPesoFixo || isPorUnidade
              ? unidadesCaixaNivel1
              : isPorPesoVariavel
              ? 0
              : 0;

          const unidades_caixa_nivel_2_local =
            isPorPesoFixo || isPorUnidade
              ? unidadesCaixaNivel2
              : isPorPesoVariavel
              ? unidadesCaixaNivel2
              : 0;

          const unidades_caixa_nivel_3_local =
            isPorPesoFixo || isPorUnidade
              ? unidadesCaixaNivel3
              : isPorPesoVariavel
              ? unidadesCaixaNivel3
              : 0;

          const peso_caixa_nivel_1_local = isPorPesoVariavel
            ? pesoCaixaNivel1
            : 0;

          let fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;

          if (fatorConversaoTipoCaixaria) {
            switch (fatorConversaoTipoCaixaria) {
              case FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM:
                fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;
                break;
              case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1:
                if (unidades_caixa_nivel_1_local || peso_caixa_nivel_1_local) {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1;
                } else {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;
                }
                break;
              case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2:
                if (unidades_caixa_nivel_2_local) {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2;
                } else {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;
                }
                break;
              case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3:
                if (unidades_caixa_nivel_3_local) {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3;
                } else {
                  fator_conversao_tipo_caixaria = FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM;
                }
                break;
              default:
                break;
            }
          }

          const produtoAtualizado = await updateAPI(
            'produtos_impostos_balancas',
            id,
            {
              nome,
              codigo,
              unidade_id: unidade.id,
              categoria_id: categoria.id,
              estoque_minimo: estoqueMinimo,
              peso_medio: pesoMedio,
              tecla,
              preco_venda: precoVenda,
              preco_revenda: precoVenda,
              familia_produto_id: familia ? familia.id : null,
              fornecedor_id: 1,
              desconto_caixa: 0,
              codigo_caixa_nivel_1:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? codigoCaixaNivel1 || null
                  : null,
              unidades_caixa_nivel_1: unidades_caixa_nivel_1_local,
              preco_venda_caixa_nivel_1: 0,
              codigo_caixa_nivel_2:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? codigoCaixaNivel2 || null
                  : null,
              unidades_caixa_nivel_2:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? unidadesCaixaNivel2
                  : 0,
              preco_venda_caixa_nivel_2: 0,
              codigo_caixa_nivel_3:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? codigoCaixaNivel3
                  : null,
              unidades_caixa_nivel_3:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? unidadesCaixaNivel3
                  : 0,
              preco_venda_caixa_nivel_3: 0,
              locais: getIdsOptions(),
              fornecedores: getIdsOptionsFornecedores(),
              ipi,
              pis,
              cofins,
              icms_ai: icmsAi,
              icms_int: icmsInt,
              ncm: ncmItem.ncm,
              df,
              cst: cstIcmsSaida,
              cfop,
              rbc,
              cst_pis_entrada: cstPisEntrada,
              cst_pis_saida: cstPisSaida,
              cst_cofins_entrada: cstCofinsEntrada,
              cst_cofins_saida: cstCofinsSaida,
              cest,
              d1: !isPorPesoFixo && !isPorUnidade ? d1 : 'vazio',
              dias_validade: diasValidade,
              exibir_dias_validade: exibirDiasValidade,
              exibir_data_embalagem: exibirValidadeEmbalagem,
              tara_balanca_id: tara.id,
              custos,
              is_industrializado: isIndustrializado,
              produto_base_id: produtoBase ? produtoBase.id : null,
              peso_provavel: isPorPesoVariavel ? pesoProvavel : 0,
              peso_caixa_nivel_1: peso_caixa_nivel_1_local,
              peso_caixa_nivel_2: 0,
              peso_caixa_nivel_3: 0,
              mva,
              taxa_de_lucro: taxaDeLucro,
              importado: isImportado,
              //
              //pis_credito: pisCredito,
              pis_credito: isCasoEspecialPisCredito
                ? cstPisEntrada === '02'
                  ? pisCredito
                  : 0
                : pisConfig,
              is_caso_especial_pis_credito: isCasoEspecialPisCredito,
              //pis_debito: pisDebito,
              pis_debito: isCasoEspecialPisDebito
                ? cstPisSaida === '02'
                  ? pisDebito
                  : 0
                : pisConfig,
              is_caso_especial_pis_debito: isCasoEspecialPisDebito,
              //cofins_credito: cofinsCredito,
              cofins_credito: isCasoEspecialCofinsCredito
                ? cstCofinsEntrada === '02'
                  ? cofinsCredito
                  : 0
                : cofinsConfig,
              is_caso_especial_cofins_credito: isCasoEspecialCofinsCredito,
              //cofins_debito: cofinsDebito,
              cofins_debito: isCasoEspecialCofinsDebito
                ? cstCofinsSaida === '02'
                  ? cofinsDebito
                  : 0
                : cofinsConfig,
              is_caso_especial_cofins_debito: isCasoEspecialCofinsDebito,
              ipi_credito: ipiCredito,
              is_caso_especial_ipi_credito: isCasoEspecialIpiCredito,
              ipi_debito: ipiDebito,
              is_caso_especial_ipi_debito: isCasoEspecialIpiDebito,
              //icms_normal_credito: icmsNormalCredito,
              icms_normal_credito: isCasoEspecialIcmsNormalCredito
                ? cstIcmsEntrada === '20'
                  ? icmsNormalCredito
                  : 0
                : icms_ai_compraConfig,
              is_caso_especial_icms_normal_credito: isCasoEspecialIcmsNormalCredito,
              //icms_normal_debito: icmsNormalDebito,
              icms_normal_debito: isCasoEspecialIcmsNormalDebito
                ? cstIcmsEntrada === '20'
                  ? icmsNormalDebito
                  : 0
                : icms_ai_compraConfig,
              is_caso_especial_icms_normal_debito: isCasoEspecialIcmsNormalDebito,
              icms_fronteira: icmsFronteira,
              is_caso_especial_icms_fronteira: isCasoEspecialIcmsFronteira,
              icms_antecipacao: icmsAntecipacao,
              is_caso_especial_icms_antecipacao: isCasoEspecialIcmsAntecipacao,
              descricao_pesquisa_xml: descricaoPesquisaXML
                ? descricaoPesquisaXML.trim().replace(/\s{2,}/g, ' ')
                : '',

              fcp,
              orig: origIcms,
              pauta_fiscal: pautaFiscalIcms,
              ////////////
              //permitir_criacao_ofertas: permitirCriacaoOfertas,
              //permitir_criacao_liberacoes: permitirCriacaoLiberacoes,
              cst_icms_entrada: cstIcmsEntrada,
              cst_icms_saida: cstIcmsSaida,
              item_ncm_id: ncmItem.id,
              //
              descricao_lei_pis: descricaoLeiPis,
              descricao_lei_cofins: descricaoLeiCofins,
              descricao_lei_icms: descricaoLeiIcms,
              //
              pis_entrada_travado: pisEntradaTravado,
              pis_saida_travado: pisSaidaTravado,
              cofins_entrada_travado: cofinsEntradaTravado,
              cofins_saida_travado: cofinsSaidaTravado,
              icms_entrada_travado: icmsEntradaTravado,
              icms_saida_travado: icmsSaidaTravado,
              icms_antecipacao_travado: icmsAntecipacaoTravado,
              icms_fronteira_travado: icmsFronteiraTravado,
              tipo_impressao: tipoImpressao,
              fator_conversao_importacao_xml_compra: isPorUnidade
                ? isFatorConversaoUp
                  ? fatorConversaoImportacaoXmlCompra
                  : 1
                : 1,
              is_fator_conversao_up: isPorUnidade ? isFatorConversaoUp : false,
              is_fator_conversao_caixaria:
                unidades_caixa_nivel_1_local || peso_caixa_nivel_1_local
                  ? isFatorConversaoCaicharia
                  : false,
              fator_conversao_tipo_caixaria,
              is_invencivel: isInvencivel,
              //
              permitir_criacao_ofertas: permitirCriacaoOfertas,
              permitir_criacao_liberacoes: permitirCriacaoLiberacoes,
              desconto_maximo_preco_venda: descontoMaximoPrecoVenda,
              pallet_base: basePallete,
              pallet_altura: alturaPallete,
              nomenclatura_no_fornecedor: nomenclaturaReferenciaFornecedor,
              tipo_unidade_preco_pre_compra: tipoUnidadePrecoPreCompra2,
              peso_unitario_pu: pesoUnitario2,
              volume_unitario_vu: volumeUnitario,
              estado_fisico: estadoFisico,
              pertencente_ao_mix: pertencenteAoMix,
              porcentagem_custo_operacional: porcentagemCustoOperacional,
              descricoesXml,
            },
          );
          setCarregando(false);
          if (produtoAtualizado.data.id) {
            enqueueSnackbar('Produto atualizado com sucesso!', {
              variant: 'success',
            });
            idLocal = id;
          } else {
            enqueueSnackbar(produtoAtualizado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setOpen(true);
      setCarregando(true);
      reiniciar();
      setValue(0);
      setIsPodeEditarPrecoConferenciaCaixaria(false);
    },
    handleCreateApartirFamilia(idFamilia) {
      setOpen(true);
      setCarregando(true);
      reiniciarApartirFamilia(idFamilia);
      setValue(0);
      setIsPodeEditarPrecoConferenciaCaixaria(false);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
      setValue(0);
      setIsPodeEditarPrecoConferenciaCaixaria(false);
    },
    handleEditConferenciaCaixaria(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
      setValue(0);
      setIsPodeEditarPrecoConferenciaCaixaria(true);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        verificaCaixaria();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog(-1);
        break;
      case ABA_GERAL:
        setValue(0);
        break;
      case ABA_BALANCA:
        if (!isPorPesoFixo && !isPorUnidade) {
          setValue(1);
        }
        break;
      case ABA_IMPOSTOS:
        if (!isPorPesoFixo && !isPorUnidade) {
          setValue(2);
        } else {
          setValue(1);
        }
        break;
      case DESBLOQUEAR_EDICAO_PRECO_VENDA:
        if (refDialogoSenha.current) {
          refDialogoSenha.current.handleOpen();
        }
        break;
      default:
        break;
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function getRefNextInputGeral(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        if (!disabledUnidade()) position = 2;
        else if (!disabledCategoria()) position = 3;
        else position = 4;
        break;
      case 2:
        if (!disabledCategoria()) position = 3;
        else position = 4;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 5;
        break;
      case 5:
        if (!disabledPesoMedio() && !hidePesoMedio()) position = 6;
        else position = 7;
        break;
      case 6:
        position = 7;
        break;
      case 7:
        position = 8;
        break;
      case 8:
        position = 9;
        break;
      case 9:
        position = 10;
        break;
      case 10:
        if (isPorPesoFixo || isPorUnidade) {
          position = 11;
        } else {
          position = 0;
        }
        break;
      case 11:
        position = 12;
        break;
      case 12:
        position = 13;
        break;
      case 13:
        position = 14;
        break;
      case 14:
        position = 15;
        break;
      case 15:
        position = 16;
        break;
      case 16:
        position = 17;
        break;
      case 17:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function getRefNextInputBalanca(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function getRefNextInputImposto(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 5;
        break;
      case 5:
        position = 6;
        break;
      case 6:
        position = 7;
        break;
      case 7:
        position = 8;
        break;
      case 8:
        position = 9;
        break;
      case 9:
        position = 10;
        break;
      case 10:
        position = 11;
        break;
      case 11:
        position = 12;
        break;
      case 12:
        position = 13;
        break;
      case 13:
        position = 14;
        break;
      case 14:
        position = 15;
        break;
      case 15:
        position = 16;
        break;
      case 16:
        position = 17;
        break;
      case 17:
        position = 18;
        break;
      case 18:
        position = 19;
        break;
      case 19:
        position = 20;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInputGeral(index) {
    const position = getRefNextInputGeral(index);
    if (position === -1) {
      refsGeral.current[0].current.focus();
      refsGeral.current[0].current.select();
    } else if (refsGeral.current[position].current) {
      refsGeral.current[position].current.focus();
      refsGeral.current[position].current.select();
    }
  }

  function handleNextInputBalanca(index) {
    const position = getRefNextInputBalanca(index);
    if (position === -1) {
      refsBalanca.current[0].current.focus();
      refsBalanca.current[0].current.select();
    } else if (refsBalanca.current[position].current) {
      refsBalanca.current[position].current.focus();
      refsBalanca.current[position].current.select();
    }
  }

  function handleNextInputImpostos(index) {
    /*     const position = getRefNextInputImposto(index);
    if (position === -1) {
      refsImposto.current[0].current.focus();
      refsImposto.current[0].current.select();
    } else if (refsImposto.current[position].current) {
      refsImposto.current[position].current.focus();
      refsImposto.current[position].current.select();
    } */
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function limparCodigo(codigo) {
    if (codigo) {
      const codigoSemEspacos = codigo.trim();
      return codigoSemEspacos.replace(/[^a-z0-9]/gi, '');
    }
    return '';
  }

  const lucroVenda = precoCompraMedio <= 0 ? 0 : precoVenda - precoCompraMedio;

  const margemLucroVenda =
    precoCompraMedio <= 0 ? 0 : (lucroVenda / precoCompraMedio) * 100;

  function criarCusto() {
    if (refDialogoCusto.current) {
      refDialogoCusto.current.handleCreate();
    }
  }

  function editarCusto(id, nome, preco, peso) {
    if (refDialogoCusto.current) {
      refDialogoCusto.current.handleEdit(id, nome, preco, peso);
    }
  }

  function handleSave(id, nome, preco, peso) {
    const newArray = custos.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          nome,
          preco,
          peso,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: custos.length + 1,
        nome,
        preco,
        peso,
      });
    }
    setCustos(recalcularIds(newArray));
  }

  function recalcularIds(arrayOld) {
    const newArray = [];
    for (let i = 0; i < arrayOld.length; i += 1) {
      newArray.push({
        nome: arrayOld[i].nome,
        preco: arrayOld[i].preco,
        peso: arrayOld[i].peso,
        id: i + 1,
      });
    }
    return newArray;
  }

  function deletarCusto(id, nome, preco, peso) {
    if (refDialogoDeletarCusto.current) {
      refDialogoDeletarCusto.current.handleDelete(id, nome, preco, peso);
    }
  }

  function handleDeletarCusto(id) {
    const newArray = custos.slice().filter((item) => item.id !== id);
    setCustos(recalcularIds(newArray));
  }

  function getNivelAtual() {
    if (unidadesCaixaNivel3 > 0) return 3;
    if (unidadesCaixaNivel2 > 0) return 2;
    if (unidadesCaixaNivel1 > 0) return 1;
    return 0;
  }

  function getNivelOferta(
    nUnidadeCaixaNivel1,
    nUnidadeCaixaNivel2,
    nUnidadeCaixaNivel3,
  ) {
    if (nUnidadeCaixaNivel3 > 0) return 3;
    if (nUnidadeCaixaNivel2 > 0) return 2;
    if (nUnidadeCaixaNivel1 > 0) return 1;
    return 0;
  }

  async function verificaCaixaria() {
    if (id > 0) {
      if (isPorPesoFixo || isPorUnidade) {
        const response = await api.get(`/verificar-oferta-nivel/${id}`);
        const {
          nUnidadeKg,
          nUnidadeUn,
          nUnidadeCaixaNivel1,
          nUnidadeCaixaNivel2,
          nUnidadeCaixaNivel3,
        } = response.data;

        const nivelOferta = getNivelOferta(
          nUnidadeCaixaNivel1,
          nUnidadeCaixaNivel2,
          nUnidadeCaixaNivel3,
        );

        const nivelAtual = getNivelAtual();

        if (nivelOferta > nivelAtual) {
          enqueueSnackbar(
            'Não é possível diminuir o nível de caixaria, pois existem ofertas ativas que usam esta caixaria, exclua ou espere terminar estas ofertas para poder diminuir o nível de caixaria!',
            {
              variant: 'error',
            },
          );
        } else {
          handleSalvar();
        }
      } else {
        handleSalvar();
      }
    } else {
      handleSalvar();
    }
  }

  function getDadosImpostos() {
    if (configs) {
      return {
        pisConfig: configs.pis,
        cofinsConfig: configs.cofins,
        icms_ai_compraConfig: configs.cidade.estado.icms_ai_compra,
        icms_antecipacaoConfig: configs.icms_antecipacao,
      };
    }
    return {
      pisConfig: 0,
      cofinsConfig: 0,
      icms_ai_compraConfig: 0,
      icms_antecipacaoConfig: 0,
    };
  }

  const {
    pisConfig,
    cofinsConfig,
    icms_ai_compraConfig,
    icms_antecipacaoConfig,
  } = getDadosImpostos();

  function getOptionsFatorConversaoTipoCaixaria() {
    if (unidadesCaixaNivel3) {
      return [
        {
          label: 'Sem Fator',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
        },
        {
          label: 'Caixa Nível 1',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
        },

        {
          label: 'Caixa Nível 2',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2,
        },
        {
          label: 'Caixa Nível 3',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3,
        },
      ];
    }

    if (unidadesCaixaNivel2) {
      return [
        {
          label: 'Sem Fator',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
        },
        {
          label: 'Caixa Nível 1',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
        },
        {
          label: 'Caixa Nível 2',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2,
        },
      ];
    }

    if (unidadesCaixaNivel1 || pesoCaixaNivel1) {
      return [
        {
          label: 'Sem Fator',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
        },
        {
          label: 'Caixa Nível 1',
          value: FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
        },
      ];
    }

    return [
      {
        label: 'Sem Fator',
        value: FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
      },
    ];
  }

  const optionsFatorConversaoTipoCaixaria = getOptionsFatorConversaoTipoCaixaria();

  function deletarDescricaoXml(index) {
    const descricoesXmlCopy = descricoesXml.slice();
    descricoesXmlCopy.splice(index - 1, 1);
    setDescricoesXml(descricoesXmlCopy);
  }

  function validarDataDescricaoXml() {
    if (errosGeral[25]) {
      return {
        status: false,
        message: errosGeral[25],
      };
    }

    return {
      status: true,
      message: '',
    };
  }

  function verificaDescricaoXmlExistente(validade) {
    return descricoesXml.find((item) => item === validade);
  }

  function handleAdicionarDescricaoXml(descricaoXml) {
    if (descricaoXml) {
      const { status, message } = validarDataDescricaoXml();
      if (status) {
        const isExistente = verificaDescricaoXmlExistente(descricaoXml);
        if (!isExistente) {
          setDescricoesXml([...descricoesXml, descricaoXml]);
          setDescricaoPesquisaXMLTemp('');
        } else {
          enqueueSnackbar(
            'Esta descrição XML já foi registrada neste produto!',
            {
              variant: 'error',
            },
          );
        }
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('A descrição XML dever ser válida!', {
        variant: 'error',
      });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {id > 0 ? `Editar Produto: id = ${id}` : 'Novo Produto'}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                {!isPodeEditarPreco && (
                  <div
                    style={{
                      margin: '10px 20px',
                    }}
                  >{`Editar Caixarias (${DESBLOQUEAR_EDICAO_PRECO_VENDA})`}</div>
                )}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={
                      <Badge color="error" badgeContent={qtdeErrosGeral}>
                        <Typography>{`Geral (${ABA_GERAL})`}</Typography>
                      </Badge>
                    }
                    {...a11yProps(0)}
                  />
                  {!isPorPesoFixo && !isPorUnidade && (
                    <Tab
                      label={
                        <Badge color="error" badgeContent={qtdeErrosBalanca}>
                          <Typography>{`Balanca (${ABA_BALANCA})`}</Typography>
                        </Badge>
                      }
                      {...a11yProps(3)}
                    />
                  )}
                  <Tab
                    label={
                      <Badge color="error" badgeContent={qtdeErrosImposto}>
                        <Typography>{`Impostos (${ABA_IMPOSTOS})`}</Typography>
                      </Badge>
                    }
                    {...a11yProps(2)}
                  />
                  {isIndustrializado && (
                    <Tab label="Custos" {...a11yProps(3)} />
                  )}

                  <Tab
                    label={
                      <Badge color="error" badgeContent={qtdeErrosComprador}>
                        <Typography>Comprador</Typography>
                      </Badge>
                    }
                    {...a11yProps(7)}
                  />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Typography variant="h5" gutterBottom>
                        Especificação do produto
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputsGeral[0].nome}
                            ref={refsGeral.current[NOME_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(NOME_POSITION)
                            }
                            label={inputsGeral[0].label}
                            handleKey={handleKey}
                            value={nome}
                            onChange={(value) => setNome(value.target.value)}
                            error={errosGeral[0] !== ''}
                            helperText={errosGeral[0]}
                            fullWidth
                            autoFocus
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            //name={inputsGeral[0].nome}
                            name="descricao_pesquisa_xml"
                            //ref={refsGeral.current[NOME_POSITION]}
                            handleEnter={() => {
                              //handleNextInputGeral(NOME_POSITION)
                            }}
                            //label={inputsGeral[0].label}
                            label="Descrição para pesquisa no XML da Compra"
                            //handleKey={handleKey}
                            handleKey={() => {}}
                            value={descricaoPesquisaXML}
                            onChange={(value) =>
                              setDescricaoPesquisaXML(value.target.value)
                            }
                            error={errosGeral[22] !== ''}
                            helperText={errosGeral[22]}
                            fullWidth
                            //autoFocus
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          {!isPorPesoVariavel ? (
                            <TextInput
                              name={inputsGeral[1].nome}
                              ref={refsGeral.current[CODIGO_POSITION]}
                              handleEnter={() =>
                                handleNextInputGeral(CODIGO_POSITION)
                              }
                              label={inputsGeral[1].label}
                              handleKey={handleKey}
                              value={codigo}
                              onChange={(value) =>
                                setCodigo(limparCodigo(value.target.value))
                              }
                              error={errosGeral[1] !== ''}
                              helperText={errosGeral[1]}
                              fullWidth
                            />
                          ) : null}
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <AutoCompleteRemoto
                            name={inputsGeral[2].nome}
                            ref={refsGeral.current[UNIDADE_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(UNIDADE_POSITION)
                            }
                            label={inputsGeral[2].label}
                            handleKey={handleKey}
                            value={unidade}
                            onChange={(value) => setUnidade(value)}
                            error={errosGeral[2] !== ''}
                            helperText={errosGeral[2]}
                            fullWidth
                            toString={false}
                            getOptionSelected={(option, value) =>
                              option.nome === value.nome
                            }
                            getOptionLabel={(option) => `${option.nome}`}
                            nested={[]}
                            filters={{ status: STATUS_ATIVO }}
                            resource="unidades"
                            disabled={disabledUnidade()}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <AutoCompleteRemoto
                            name={inputsGeral[3].nome}
                            ref={refsGeral.current[CATEGORIA_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(CATEGORIA_POSITION)
                            }
                            label={inputsGeral[3].label}
                            handleKey={handleKey}
                            value={categoria}
                            onChange={(value) => setCategoria(value)}
                            error={errosGeral[3] !== ''}
                            helperText={errosGeral[3]}
                            fullWidth
                            toString={false}
                            getOptionSelected={(option, value) =>
                              option.nome === value.nome
                            }
                            getOptionLabel={(option) => `${option.nome}`}
                            nested={[]}
                            filters={{ ativo: true }}
                            resource="categorias"
                            disabled={disabledCategoria()}
                          />
                        </Box>

                        <Box flex={1} ml="0.5em">
                          <AutoCompleteRemoto
                            name={inputsGeral[9].nome}
                            ref={refsGeral.current[FAMILIA_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(FAMILIA_POSITION)
                            }
                            label={inputsGeral[9].label}
                            handleKey={handleKey}
                            value={familia}
                            onChange={(value) => setFamilia(value)}
                            error={errosGeral[9] !== ''}
                            helperText={errosGeral[9]}
                            fullWidth
                            toString={false}
                            getOptionSelected={(option, value) =>
                              option.nome === value.nome
                            }
                            getOptionLabel={(option) => `${option.nome}`}
                            nested={[]}
                            filters={{ ativo: true }}
                            resource="familias_produtos"
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          {isPorUnidade || isPorPesoFixo ? (
                            <IntegerInput
                              name={inputsGeral[4].nome}
                              ref={refsGeral.current[ESTOQUE_MINIMO_POSITION]}
                              handleEnter={() =>
                                handleNextInputGeral(ESTOQUE_MINIMO_POSITION)
                              }
                              label={inputsGeral[4].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(estoqueMinimo)
                                  ? ''
                                  : String(estoqueMinimo)
                              }
                              onChange={(value) =>
                                setEstoqueMinimo(
                                  parseInt(value.target.value, 10),
                                )
                              }
                              error={errosGeral[4] !== ''}
                              helperText={errosGeral[4]}
                              fullWidth
                            />
                          ) : (
                            <PesoInput
                              name={inputsGeral[4].nome}
                              ref={refsGeral.current[ESTOQUE_MINIMO_POSITION]}
                              handleEnter={() =>
                                handleNextInputGeral(ESTOQUE_MINIMO_POSITION)
                              }
                              label={inputsGeral[4].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(estoqueMinimo)
                                  ? ''
                                  : String(estoqueMinimo)
                              }
                              onChange={(value) =>
                                setEstoqueMinimo(
                                  parseFloat(value.target.value, 10),
                                )
                              }
                              error={errosGeral[4] !== ''}
                              helperText={errosGeral[4]}
                              fullWidth
                            />
                          )}
                        </Box>
                        {!hidePesoMedio() && (
                          <Box flex={1} mr="0.5em" ml="0.5em">
                            <PesoInput
                              name={inputsGeral[5].nome}
                              ref={refsGeral.current[PESO_MEDIO_POSITION]}
                              handleEnter={() =>
                                handleNextInputGeral(PESO_MEDIO_POSITION)
                              }
                              label={inputsGeral[5].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(pesoMedio) ? '' : String(pesoMedio)
                              }
                              onChange={(value) =>
                                setPesoMedio(parseFloat(value.target.value))
                              }
                              error={errosGeral[5] !== ''}
                              helperText={errosGeral[5]}
                              fullWidth
                              disabled={disabledPesoMedio()}
                            />
                          </Box>
                        )}
                        {isPorPesoVariavel && (
                          <Box flex={1} mr="0.5em" ml="0.5em">
                            <PesoInput
                              name={inputsGeral[19].nome}
                              ref={refsGeral.current[19]}
                              handleEnter={() => handleNextInputGeral(19)}
                              label={inputsGeral[19].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(pesoProvavel)
                                  ? ''
                                  : String(pesoProvavel)
                              }
                              onChange={(value) =>
                                setPesoProvavel(parseFloat(value.target.value))
                              }
                              error={errosGeral[19] !== ''}
                              helperText={errosGeral[19]}
                              fullWidth
                            />
                          </Box>
                        )}
                        <Box flex={1} ml="0.5em">
                          <TeclaInput
                            name={inputsGeral[6].nome}
                            ref={refsGeral.current[TECLA_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(TECLA_POSITION)
                            }
                            label={inputsGeral[6].label}
                            handleKey={handleKey}
                            value={tecla}
                            onChange={(value) => setTecla(value.target.value)}
                            error={errosGeral[6] !== ''}
                            helperText={errosGeral[6]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                          <LocaisInput
                            name={inputsGeral[10].nome}
                            ref={refsGeral.current[FORNECEDOR_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(FORNECEDOR_POSITION)
                            }
                            label={inputsGeral[10].label}
                            handleKey={handleKey}
                            fullWidth
                            options={fornecedoresOptions}
                            value={fornecedores}
                            onChange={setFornecedores}
                            error={errosGeral[21] !== ''}
                            helperText={errosGeral[21]}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                          <LocaisInput
                            name={inputsGeral[18].nome}
                            ref={refsGeral.current[LOCAIS_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(LOCAIS_POSITION)
                            }
                            label={inputsGeral[18].label}
                            handleKey={handleKey}
                            fullWidth
                            options={locaisOptions}
                            value={locais}
                            onChange={setLocais}
                          />
                        </Box>

                        <Box flex={1} ml="0.5em">
                          <SwitchInput
                            name="is_industrializado"
                            handleEnter={() => {}}
                            label="Industrializado"
                            handleKey={handleKey}
                            checked={isIndustrializado}
                            onChange={(value) =>
                              setIsIndustrializado(value.target.checked)
                            }
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex" alignItems="center">
                        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                              backgroundColor: 'white',
                            }}
                            fullWidth
                          >
                            <InputLabel shrink id="tipo-impressao">
                              Tipo Impressão
                            </InputLabel>
                            <SelectInput
                              labelId="tipo-impressao"
                              name="tipo-impressao"
                              handleEnter={() => {}}
                              label="Tipo Impressão"
                              handleKey={() => {}}
                              value={tipoImpressao}
                              onChange={(value) => {
                                if (!disabledTipoImpressao) {
                                  setTipoImpressao(value.target.value);
                                }
                              }}
                              options={optionsTipoImpressao}
                              disabled={disabledTipoImpressao}
                            />
                          </FormControl>
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <SwitchInput
                            name="is_fator_conversao_up"
                            handleEnter={() => {}}
                            label="Produto UP"
                            handleKey={handleKey}
                            checked={isFatorConversaoUp}
                            onChange={(value) =>
                              setIsFatorConversaoUp(value.target.checked)
                            }
                            fullWidth
                          />
                        </Box>
                        {isPorUnidade && isFatorConversaoUp ? (
                          <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                            <IntegerInput
                              handleEnter={() => {}}
                              label="Fator de Conversão Produto UP"
                              handleKey={handleKey}
                              value={
                                Number.isNaN(fatorConversaoImportacaoXmlCompra)
                                  ? ''
                                  : String(fatorConversaoImportacaoXmlCompra)
                              }
                              onChange={(value) =>
                                setEstoqueMinimo(
                                  setFatorConversaoImportacaoXmlCompra(
                                    value.target.value,
                                    10,
                                  ),
                                )
                              }
                              error={errosGeral[23] !== ''}
                              helperText={errosGeral[23]}
                              fullWidth
                            />
                          </Box>
                        ) : null}
                      </Box>

                      <Box display="flex" alignItems="center">
                        {/*    {unidadesCaixaNivel1 || pesoCaixaNivel1 ? (
                          <Box flex={1} mr="0.5em">
                            <SwitchInput
                              name="is_fator_conversao_caixaria"
                              handleEnter={() => {}}
                              label="Aplicar Caixaria Nivel 1 como FATOR DE CONVERSÃO"
                              handleKey={handleKey}
                              checked={isFatorConversaoCaicharia}
                              onChange={(value) =>
                                setIsFatorConversaoCaixaria(
                                  value.target.checked,
                                )
                              }
                              fullWidth
                            />
                          </Box>
                        ) : null} */}
                        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                              backgroundColor: 'white',
                            }}
                            fullWidth
                          >
                            <InputLabel shrink id="tipo-impressao">
                              Fator Conversão de Caixaria
                            </InputLabel>
                            <SelectInput
                              labelId="fator-conversao-caixaria"
                              name="fator-conversao-caixaria"
                              handleEnter={() => {}}
                              label="Fator Conversão de Caixaria"
                              handleKey={() => {}}
                              value={fatorConversaoTipoCaixaria}
                              onChange={(value) => {
                                setFatorConversaoTipoCaixaria(
                                  value.target.value,
                                );
                              }}
                              options={optionsFatorConversaoTipoCaixaria}
                            />
                          </FormControl>
                        </Box>
                        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                              backgroundColor: 'white',
                            }}
                            fullWidth
                          >
                            <InputLabel shrink id="estado_fisico">
                              Estado Físico
                            </InputLabel>
                            <SelectInput
                              labelId="estado_fisico"
                              name="estado_fisico"
                              handleEnter={() => {}}
                              label="Estado Físico"
                              handleKey={() => {}}
                              value={estadoFisico}
                              onChange={(value) => {
                                setEstadoFisico(value.target.value);
                              }}
                              options={optionsEstadoFísico}
                              error={errosGeral[24] !== ''}
                              helperText={errosGeral[24]}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box mt="1em" />

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <SwitchInput
                            name="importado"
                            handleEnter={() => {}}
                            label="Importado"
                            handleKey={handleKey}
                            checked={isImportado}
                            onChange={(value) =>
                              setIsImportado(value.target.checked)
                            }
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" mr="0.5em">
                          {/* <SwitchInput
                            name="permitir_criacao_ofertas"
                            handleEnter={() => {}}
                            label="Permitir de Criação de Ofertas"
                            handleKey={handleKey}
                            checked={permitirCriacaoOfertas}
                            onChange={(value) =>
                              setPermitirCriacaoOfertas(value.target.checked)
                            }
                            fullWidth
                          /> */}
                          <SwitchInput
                            name="is_invencivel"
                            handleEnter={() => {}}
                            label="Produto Invencível (a validades não é um problema)"
                            handleKey={handleKey}
                            checked={isInvencivel}
                            onChange={(value) =>
                              setIsInvencivel(value.target.checked)
                            }
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          {/* <SwitchInput
                            name="permitir_criacao_liberacoes"
                            handleEnter={() => {}}
                            label="Permitir de Criação de Liberações de Preços"
                            handleKey={handleKey}
                            checked={permitirCriacaoLiberacoes}
                            onChange={(value) =>
                              setPermitirCriacaoLiberacoes(value.target.checked)
                            }
                            fullWidth
                          /> */}
                          {/* <SwitchInput
                            name="pertencente_ao_mix"
                            handleEnter={() => {}}
                            label="Pertencente ao MIX"
                            handleKey={handleKey}
                            checked={pertencenteAoMix}
                            onChange={(value) =>
                              setPertencenteAoMix(value.target.checked)
                            }
                            fullWidth
                          /> */}
                        </Box>
                      </Box>

                      <Box mt="1em" />

                      <Typography variant="h5" gutterBottom>
                        Dados de Venda
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PrecoInput
                            name={inputsGeral[7].nome}
                            ref={refsGeral.current[PRECO_VENDA_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(PRECO_VENDA_POSITION)
                            }
                            label={inputsGeral[7].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(precoVenda) ? '' : String(precoVenda)
                            }
                            onChange={(value) =>
                              setPrecoVenda(parseFloat(value.target.value))
                            }
                            error={errosGeral[7] !== ''}
                            helperText={errosGeral[7]}
                            //disabled={!isPodeEditarPreco}
                            disabled={!isPodeEditarPreco2}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" mr="0.5em">
                          <PrecoInput
                            name="lucro venda"
                            handleEnter={() => {}}
                            label="Lucro da Venda"
                            handleKey={handleKey}
                            value={lucroVenda}
                            onChange={() => {}}
                            fullWidth
                            disabled
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name="margem lucro venda"
                            handleEnter={() => {}}
                            label="Margem Lucro da Venda"
                            handleKey={handleKey}
                            value={margemLucroVenda}
                            onChange={() => {}}
                            fullWidth
                            disabled
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            name={inputsGeral[20].nome}
                            ref={refsGeral.current[20]}
                            handleEnter={() =>
                              handleNextInputImpostos(TAXA_LUCRO_POSITION)
                            }
                            label={inputsGeral[20].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(taxaDeLucro)
                                ? ''
                                : String(taxaDeLucro)
                            }
                            onChange={(value) =>
                              setTaxaDeLucro(parseFloat(value.target.value))
                            }
                            error={errosGeral[20] !== ''}
                            helperText={errosGeral[20]}
                            fullWidth
                            disabled={!isPodeEditarPreco}
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" mr="0.5em" />
                        <Box flex={1} ml="0.5em" />
                      </Box>

                      {(isPorPesoFixo || isPorUnidade || isPorPesoVariavel) && (
                        <>
                          <Box mt="1em" />
                          <Typography variant="h6" gutterBottom>
                            Caixa (Nível 1)
                          </Typography>
                          <Box display="flex" mb="30px">
                            <Box flex={1} mr="0.5em">
                              <TextInput
                                name={inputsGeral[12].nome}
                                ref={refsGeral.current[CODIGO_CAIXA_NIVEL_1]}
                                handleEnter={() =>
                                  handleNextInputGeral(CODIGO_CAIXA_NIVEL_1)
                                }
                                label={inputsGeral[12].label}
                                handleKey={handleKey}
                                value={codigoCaixaNivel1}
                                onChange={(value) =>
                                  setCodigoCaixaNivel1(
                                    limparCodigo(value.target.value),
                                  )
                                }
                                error={errosGeral[12] !== ''}
                                helperText={errosGeral[12]}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em" mr="0.5em" display="flex">
                              {!isPorPesoVariavel ? (
                                <IntegerInput
                                  name={inputsGeral[13].nome}
                                  ref={
                                    refsGeral.current[UNIDADES_CAIXA_NIVEL_1]
                                  }
                                  handleEnter={() =>
                                    handleNextInputGeral(UNIDADES_CAIXA_NIVEL_1)
                                  }
                                  label={inputsGeral[13].label}
                                  handleKey={handleKey}
                                  value={
                                    Number.isNaN(unidadesCaixaNivel1)
                                      ? ''
                                      : String(unidadesCaixaNivel1)
                                  }
                                  onChange={(value) =>
                                    setUnidadesCaixaNivel1(
                                      parseInt(value.target.value, 10),
                                    )
                                  }
                                  error={errosGeral[13] !== ''}
                                  helperText={errosGeral[13]}
                                  disabled={
                                    !isPodeEditarPreco &&
                                    !isPodeEditarPrecoConferenciaCaixaria
                                  }
                                  fullWidth
                                />
                              ) : (
                                <PesoInput
                                  name={inputsGeral[13].nome}
                                  ref={
                                    refsGeral.current[UNIDADES_CAIXA_NIVEL_1]
                                  }
                                  handleEnter={() =>
                                    handleNextInputGeral(UNIDADES_CAIXA_NIVEL_1)
                                  }
                                  label="Peso"
                                  handleKey={handleKey}
                                  value={
                                    Number.isNaN(pesoCaixaNivel1)
                                      ? ''
                                      : String(pesoCaixaNivel1)
                                  }
                                  onChange={(value) =>
                                    setPesoCaixaNivel1(
                                      parseFloat(value.target.value),
                                    )
                                  }
                                  error={errosGeral[13] !== ''}
                                  helperText={errosGeral[13]}
                                  disabled={
                                    !isPodeEditarPreco &&
                                    !isPodeEditarPrecoConferenciaCaixaria
                                  }
                                  fullWidth
                                />
                              )}
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <PrecoInput
                                handleEnter={() => {}}
                                label="Preço de venda"
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(precoVendaCaixaNivel1)
                                    ? ''
                                    : String(precoVendaCaixaNivel1)
                                }
                                onChange={() => {}}
                                fullWidth
                                disabled
                              />
                            </Box>
                          </Box>
                          <Typography variant="h6" gutterBottom>
                            Caixeta (Nível 2)
                          </Typography>
                          <Box display="flex" mb="30px">
                            <Box flex={1} mr="0.5em">
                              <TextInput
                                name={inputsGeral[14].nome}
                                ref={refsGeral.current[CODIGO_CAIXA_NIVEL_2]}
                                handleEnter={() =>
                                  handleNextInputGeral(CODIGO_CAIXA_NIVEL_2)
                                }
                                label={inputsGeral[14].label}
                                handleKey={handleKey}
                                value={codigoCaixaNivel2}
                                onChange={(value) =>
                                  setCodigoCaixaNivel2(
                                    limparCodigo(value.target.value),
                                  )
                                }
                                error={errosGeral[14] !== ''}
                                helperText={errosGeral[14]}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em" mr="0.5em" display="flex">
                              <IntegerInput
                                name={inputsGeral[15].nome}
                                ref={refsGeral.current[UNIDADES_CAIXA_NIVEL_2]}
                                handleEnter={() =>
                                  handleNextInputGeral(UNIDADES_CAIXA_NIVEL_2)
                                }
                                label={inputsGeral[15].label}
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(unidadesCaixaNivel2)
                                    ? ''
                                    : String(unidadesCaixaNivel2)
                                }
                                onChange={(value) =>
                                  setUnidadesCaixaNivel2(
                                    parseInt(value.target.value, 10),
                                  )
                                }
                                error={errosGeral[15] !== ''}
                                helperText={errosGeral[15]}
                                disabled={
                                  !isPodeEditarPreco &&
                                  !isPodeEditarPrecoConferenciaCaixaria
                                }
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <PrecoInput
                                handleEnter={() => {}}
                                label="Preço de venda"
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(precoVendaCaixaNivel2)
                                    ? ''
                                    : String(precoVendaCaixaNivel2)
                                }
                                onChange={() => {}}
                                fullWidth
                                disabled
                              />
                            </Box>
                          </Box>
                          <Typography variant="h6" gutterBottom>
                            Caixa-Mãe (Nível 3)
                          </Typography>
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <TextInput
                                name={inputsGeral[16].nome}
                                ref={refsGeral.current[CODIGO_CAIXA_NIVEL_3]}
                                handleEnter={() =>
                                  handleNextInputGeral(CODIGO_CAIXA_NIVEL_3)
                                }
                                label={inputsGeral[16].label}
                                handleKey={handleKey}
                                value={codigoCaixaNivel3}
                                onChange={(value) =>
                                  setCodigoCaixaNivel3(
                                    limparCodigo(value.target.value),
                                  )
                                }
                                error={errosGeral[16] !== ''}
                                helperText={errosGeral[16]}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em" mr="0.5em" display="flex">
                              <IntegerInput
                                name={inputsGeral[17].nome}
                                ref={refsGeral.current[UNIDADES_CAIXA_NIVEL_3]}
                                handleEnter={() =>
                                  handleNextInputGeral(UNIDADES_CAIXA_NIVEL_3)
                                }
                                label={inputsGeral[17].label}
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(unidadesCaixaNivel3)
                                    ? ''
                                    : String(unidadesCaixaNivel3)
                                }
                                onChange={(value) =>
                                  setUnidadesCaixaNivel3(
                                    parseInt(value.target.value, 10),
                                  )
                                }
                                error={errosGeral[17] !== ''}
                                helperText={errosGeral[17]}
                                disabled={
                                  !isPodeEditarPreco &&
                                  !isPodeEditarPrecoConferenciaCaixaria
                                }
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <PrecoInput
                                handleEnter={() => {}}
                                label="Preço de venda"
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(precoVendaCaixaNivel3)
                                    ? ''
                                    : String(precoVendaCaixaNivel3)
                                }
                                onChange={() => {}}
                                fullWidth
                                disabled
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box>
                    <div>
                      <div
                        style={{
                          color: 'blue',
                          margin: '10px 0',
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        * Para adicionar uma nova Descrição de Xml aperta a
                        TECLA ENTER no campo abaixo
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: '10px',
                          flexDirection: 'column',
                        }}
                      >
                        <TextInput
                          name="descricao_xml_adicional"
                          handleEnter={() => {
                            handleAdicionarDescricaoXml(
                              descricaoPesquisaXMLTemp,
                            );
                          }}
                          label="Descrição XML Adicional"
                          handleKey={() => {}}
                          value={descricaoPesquisaXMLTemp}
                          onChange={(value) =>
                            setDescricaoPesquisaXMLTemp(value.target.value)
                          }
                          error={errosGeral[25] !== ''}
                          helperText={errosGeral[25]}
                          fullWidth
                        />

                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell2>Ordem</StyledTableCell2>
                                <StyledTableCell2>Descrição</StyledTableCell2>
                                <StyledTableCell2>Ações</StyledTableCell2>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {descricoesXml.map((row, ind) => (
                                <StyledTableRow key={`${ind}-row`}>
                                  <StyledTableCell2>{ind + 1}</StyledTableCell2>
                                  <StyledTableCell2>{row}</StyledTableCell2>
                                  <StyledTableCell2>
                                    <IconButton
                                      aria-label="deletar"
                                      size="small"
                                      onClick={() => deletarDescricaoXml(ind)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </StyledTableCell2>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </Box>
                </TabPanel>
                {!isPorPesoFixo && !isPorUnidade && (
                  <TabPanel value={value} index={1}>
                    <Box display="flex">
                      <Box flex={2} mr="1em">
                        <Typography variant="h6" gutterBottom>
                          Dados balança
                        </Typography>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextInput
                              name={inputsBalanca[0].nome}
                              ref={refsBalanca.current[D1_POSITION]}
                              handleEnter={() =>
                                handleNextInputBalanca(D1_POSITION)
                              }
                              label={inputsBalanca[0].label}
                              handleKey={handleKey}
                              value={d1}
                              onChange={(value) => setD1(value.target.value)}
                              error={errosBalanca[0] !== ''}
                              helperText={errosBalanca[0]}
                              fullWidth
                              autoFocus
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <IntegerInput
                              name={inputsBalanca[1].nome}
                              ref={refsBalanca.current[DIAS_VALIDADE_POSITION]}
                              handleEnter={() =>
                                handleNextInputBalanca(DIAS_VALIDADE_POSITION)
                              }
                              label={inputsBalanca[1].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(diasValidade)
                                  ? ''
                                  : String(diasValidade)
                              }
                              onChange={(value) =>
                                setDiasValidade(
                                  parseInt(value.target.value, 10),
                                )
                              }
                              error={errosBalanca[1] !== ''}
                              helperText={errosBalanca[1]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <AutoCompleteRemoto
                              name={inputsBalanca[2].nome}
                              ref={refsBalanca.current[TARA_POSITION]}
                              handleEnter={() =>
                                handleNextInputBalanca(TARA_POSITION)
                              }
                              label={inputsBalanca[2].label}
                              handleKey={handleKey}
                              value={tara}
                              onChange={(value) => setTara(value)}
                              error={errosBalanca[2] !== ''}
                              helperText={errosBalanca[2]}
                              fullWidth
                              toString={false}
                              getOptionSelected={(option, value) =>
                                option.nome === value.nome
                              }
                              getOptionLabel={(option) => `${option.nome}`}
                              nested={[]}
                              filters={{}}
                              resource="taras_balanca"
                              disabled={false}
                            />
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <SwitchInput
                              name={inputsBalanca[3].nome}
                              ref={
                                refsBalanca.current[
                                  EXIBIR_DIAS_VALIDADE_POSITION
                                ]
                              }
                              handleEnter={() =>
                                handleNextInputBalanca(
                                  EXIBIR_DIAS_VALIDADE_POSITION,
                                )
                              }
                              label={inputsBalanca[3].label}
                              handleKey={handleKey}
                              checked={exibirDiasValidade}
                              onChange={(value) =>
                                setExibirDiasValidade(value.target.checked)
                              }
                              error={errosBalanca[3] !== ''}
                              helperText={errosBalanca[3]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr="0.5em">
                            <SwitchInput
                              name={inputsBalanca[4].nome}
                              ref={
                                refsBalanca.current[
                                  EXIBIR_VALIDADE_EMBALAGEM_POSITION
                                ]
                              }
                              handleEnter={() =>
                                handleNextInputBalanca(
                                  EXIBIR_VALIDADE_EMBALAGEM_POSITION,
                                )
                              }
                              label={inputsBalanca[4].label}
                              handleKey={handleKey}
                              checked={exibirValidadeEmbalagem}
                              onChange={(value) =>
                                setExibirValidadeEmbalagem(value.target.checked)
                              }
                              error={errosBalanca[4] !== ''}
                              helperText={errosBalanca[4]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <Box flex={1} ml="0.5em">
                              {id > 0 ? (
                                <TextInput
                                  handleEnter={() => {}}
                                  label="Código na Balança"
                                  handleKey={() => {}}
                                  value={codigoInterno}
                                  onChange={(value) => {}}
                                  fullWidth
                                  disabled
                                />
                              ) : null}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TabPanel>
                )}
                <TabPanel
                  value={value}
                  index={isPorPesoFixo || isPorUnidade ? 1 : 2}
                  //index={5}
                >
                  <Box display="flex">
                    <Box
                      flex={2}
                      mr="1em"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                      }}
                    >
                      <Box
                        flex={1}
                        style={{
                          padding: '10px',
                          border: '3px solid red',
                          borderRadius: '10px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            color: 'red',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            textDecoration: 'underline',
                          }}
                        >
                          Geral
                        </Typography>

                        <Box>
                          <Box display="flex" style={{ gap: '10px' }}>
                            <Box flex={1}>
                              <AutoCompleteRemoto
                                name={inputsImpostos[12].nome}
                                ref={refsImposto.current[UNIDADE_POSITION]}
                                handleEnter={() =>
                                  handleNextInputImpostos(UNIDADE_POSITION)
                                }
                                label={inputsImpostos[12].label}
                                handleKey={handleKey}
                                value={ncmItem}
                                onChange={(value) => setNcmItem(value)}
                                error={errosImpostos[12] !== ''}
                                helperText={errosImpostos[12]}
                                fullWidth
                                toString={false}
                                getOptionSelected={(option, value) =>
                                  option.ncm === value.ncm
                                }
                                getOptionLabel={(option) => `${option.ncm}`}
                                nested={[]}
                                resource="tabela-ncm"
                                campo="ncm"
                              />
                            </Box>
                            <Box flex={1}>
                              <AutoCompleteRemoto
                                name={inputsImpostos[12].nome}
                                ref={refsImposto.current[UNIDADE_POSITION]}
                                handleEnter={() =>
                                  handleNextInputImpostos(UNIDADE_POSITION)
                                }
                                label={inputsImpostos[12].label}
                                handleKey={handleKey}
                                value={ncmItem}
                                onChange={(value) => setNcmItem(value)}
                                error={errosImpostos[12] !== ''}
                                helperText={errosImpostos[12]}
                                fullWidth
                                toString={false}
                                getOptionSelected={(option, value) =>
                                  option.descricao === value.descricao
                                }
                                getOptionLabel={(option) =>
                                  `${option.descricao}`
                                }
                                nested={[]}
                                resource="tabela-ncm"
                                campo="descricao"
                                perPage={100}
                              />
                            </Box>
                          </Box>

                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cfop">
                              CFOP
                            </InputLabel>
                            <SelectInput
                              labelId="cfop"
                              name="turno"
                              handleEnter={() => {}}
                              label="CFOP"
                              handleKey={() => {}}
                              value={cfop}
                              onChange={(value) => {
                                //setCfop(value.target.value)
                              }}
                              options={optionsCfop}
                              //error={errosImpostos[14] !== ''}
                              //helperText={errosImpostos[14]}
                              disabled
                            />
                          </FormControl>
                          <CestInput
                            name={inputsImpostos[19].nome}
                            ref={refsImposto.current[19]}
                            handleEnter={() => handleNextInputImpostos(19)}
                            label={inputsImpostos[19].label}
                            handleKey={handleKey}
                            value={cest}
                            onChange={(value) => setCest(value.target.value)}
                            error={errosImpostos[19] !== ''}
                            helperText={errosImpostos[19]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box
                        flex={1}
                        style={{
                          padding: '10px',
                          border: '3px solid red',
                          borderRadius: '10px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            color: 'red',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            textDecoration: 'underline',
                          }}
                        >
                          ICMS
                        </Typography>

                        <Box
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="orig-icms">
                              ORIG
                            </InputLabel>
                            <SelectInput
                              labelId="orig-icms"
                              name="turno"
                              handleEnter={() => {}}
                              label="ORIG"
                              handleKey={() => {}}
                              value={origIcms}
                              onChange={(value) =>
                                setOrigIcms(value.target.value)
                              }
                              options={optionsOrigIcms}
                              error={errosImpostos[22] !== ''}
                              helperText={errosImpostos[22]}
                            />
                          </FormControl>

                          <ImpostoInput
                            name={inputsImpostos[8].nome}
                            ref={refsImposto.current[8]}
                            handleEnter={() => handleNextInputImpostos(8)}
                            label={inputsImpostos[8].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialIcmsFronteira
                                  ? icmsFronteira
                                  : Number.NaN,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialIcmsFronteira
                                      ? icmsFronteira
                                      : Number.NaN,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialIcmsFronteira) {
                                setIcmsFonteira(parseFloat(value.target.value));
                              }
                            }}
                            error={errosImpostos[8] !== ''}
                            helperText={errosImpostos[8]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialIcmsFronteira}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialIcmsFronteira(value);
                              if (isCasoEspecialIcmsFronteira) {
                                setIcmsFonteira(0);
                              }
                            }}
                            disabled={!isCasoEspecialIcmsFronteira}
                          />
                          <ImpostoInput
                            name={inputsImpostos[9].nome}
                            ref={refsImposto.current[9]}
                            handleEnter={() => handleNextInputImpostos(9)}
                            label={inputsImpostos[9].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialIcmsAntecipacao
                                  ? icmsAntecipacao
                                  : icms_antecipacaoConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialIcmsAntecipacao
                                      ? icmsAntecipacao
                                      : icms_antecipacaoConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialIcmsAntecipacao) {
                                setIcmsAntecipacao(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            error={errosImpostos[9] !== ''}
                            helperText={errosImpostos[9]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialIcmsAntecipacao}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialIcmsAntecipacao(value);
                              if (isCasoEspecialIcmsAntecipacao) {
                                setIcmsAntecipacao(icms_antecipacaoConfig);
                              }
                            }}
                            disabled={!isCasoEspecialIcmsAntecipacao}
                          />
                        </Box>

                        <Box
                          style={{
                            padding: '10px',
                            border: '3px solid blue',
                            borderRadius: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              //gutterBottom
                              style={{
                                color: 'blue',
                                fontWeight: 'bold',
                                //textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              ENTRADA
                            </Typography>
                            <IndicadorTravado
                              value={icmsEntradaTravado}
                              onChange={(value) => {
                                if (value) {
                                  setIcmsEntradaTravado(true);
                                } else {
                                  setIcmsEntradaTravado(false);
                                }
                              }}
                            />
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-icms-entrada">
                              CST
                            </InputLabel>

                            <SelectInput
                              labelId="cst-icms-entrada"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST"
                              handleKey={() => {}}
                              value={cstIcmsEntrada}
                              onChange={(value) =>
                                setCstIcmsEntrada(value.target.value)
                              }
                              options={
                                cstIcmsEntrada === '60'
                                  ? optionsCstIcms2
                                  : optionsCstIcms
                              }
                              error={errosImpostos[24] !== ''}
                              helperText={errosImpostos[24]}
                            />
                          </FormControl>
                          <PorcentagemInput
                            name={inputsImpostos[6].nome}
                            ref={refsImposto.current[6]}
                            label="Aliquota"
                            value={
                              Number.isNaN(
                                isCasoEspecialIcmsNormalCredito
                                  ? cstIcmsEntrada === '20'
                                    ? icmsNormalCredito
                                    : 0
                                  : icms_ai_compraConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialIcmsNormalCredito
                                      ? cstIcmsEntrada === '20'
                                        ? icmsNormalCredito
                                        : 0
                                      : icms_ai_compraConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (cstIcmsEntrada === '20') {
                                setIcmsNormalCredito(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            escala={2}
                            handleEnter={() => handleNextInputImpostos(6)}
                            handleKey={handleKey}
                            fullWidth
                            //disabled={!isCasoEspecialIcmsNormalCredito}
                            error={errosImpostos[6] !== ''}
                            helperText={errosImpostos[6]}
                            disabled={cstIcmsEntrada !== '20'}
                          />
                        </Box>
                        <Box
                          style={{
                            padding: '10px',
                            border: '3px solid blue',
                            borderRadius: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              //gutterBottom
                              style={{
                                color: 'blue',
                                fontWeight: 'bold',
                                //textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              SAÍDA
                            </Typography>
                            <IndicadorTravado
                              value={icmsSaidaTravado}
                              onChange={(value) => {
                                if (value) {
                                  setIcmsSaidaTravado(true);
                                } else {
                                  setIcmsSaidaTravado(false);
                                }
                              }}
                            />
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-icms-saida">
                              CST
                            </InputLabel>
                            <SelectInput
                              labelId="cst-icms-saida"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST"
                              handleKey={() => {}}
                              value={cstIcmsSaida}
                              onChange={(value) =>
                                setCstIcmsSaida(value.target.value)
                              }
                              options={
                                cstIcmsSaida === '60'
                                  ? optionsCstIcms2
                                  : optionsCstIcms
                              }
                              error={errosImpostos[25] !== ''}
                              helperText={errosImpostos[25]}
                            />
                          </FormControl>
                          <PorcentagemInput
                            name={inputsImpostos[7].nome}
                            ref={refsImposto.current[7]}
                            label="Aliquota"
                            value={
                              Number.isNaN(
                                isCasoEspecialIcmsNormalDebito
                                  ? cstIcmsSaida === '20'
                                    ? icmsNormalDebito
                                    : 0
                                  : icms_ai_compraConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialIcmsNormalDebito
                                      ? cstIcmsSaida === '20'
                                        ? icmsNormalDebito
                                        : 0
                                      : icms_ai_compraConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (cstIcmsSaida === '20') {
                                setIcmsNormalDebito(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            escala={2}
                            handleEnter={() => handleNextInputImpostos(7)}
                            handleKey={handleKey}
                            error={errosImpostos[7] !== ''}
                            helperText={errosImpostos[7]}
                            fullWidth
                            disabled={cstIcmsSaida !== '20'}
                          />

                          {cstIcmsSaida === '20' ? (
                            <PorcentagemInput
                              name={inputsImpostos[11].nome}
                              ref={refsImposto.current[11]}
                              label="RBC"
                              value={Number.isNaN(rbc) ? '' : String(rbc)}
                              onChange={(value) =>
                                setRbc(parseFloat(value.target.value))
                              }
                              escala={2}
                              handleEnter={() => handleNextInputImpostos(11)}
                              handleKey={handleKey}
                              error={errosImpostos[11] !== ''}
                              helperText={errosImpostos[11]}
                              fullWidth
                            />
                          ) : null}
                          {cstIcmsSaida === '60' ? (
                            <PorcentagemInput
                              name={inputsImpostos[20].nome}
                              ref={refsImposto.current[20]}
                              label="MVA"
                              value={Number.isNaN(mva) ? '' : String(mva)}
                              onChange={(value) =>
                                setMva(parseFloat(value.target.value))
                              }
                              escala={2}
                              handleEnter={() => handleNextInputGeral(20)}
                              handleKey={handleKey}
                              error={errosImpostos[20] !== ''}
                              helperText={errosImpostos[20]}
                              fullWidth
                            />
                          ) : null}
                          {cstIcmsSaida === '60' ? (
                            <PorcentagemInput
                              name={inputsImpostos[21].nome}
                              ref={refsImposto.current[21]}
                              label="FCP"
                              value={Number.isNaN(fcp) ? '' : String(fcp)}
                              onChange={(value) =>
                                setFcp(parseFloat(value.target.value))
                              }
                              escala={2}
                              handleEnter={() => handleNextInputImpostos(2)}
                              handleKey={handleKey}
                              error={errosImpostos[21] !== ''}
                              helperText={errosImpostos[21]}
                              fullWidth
                            />
                          ) : null}
                          {cstIcmsSaida === '60' ? (
                            <PrecoInput
                              name={inputsImpostos[23].nome}
                              ref={refsImposto.current[23]}
                              handleEnter={() => handleNextInputImpostos(20)}
                              label="Pauta Fiscal"
                              handleKey={handleKey}
                              value={
                                Number.isNaN(pautaFiscalIcms)
                                  ? ''
                                  : String(pautaFiscalIcms)
                              }
                              onChange={(value) =>
                                setPautaFiscalIcms(
                                  parseFloat(value.target.value),
                                )
                              }
                              error={errosImpostos[23] !== ''}
                              helperText={errosImpostos[23]}
                              fullWidth
                            />
                          ) : null}

                          <TextInput
                            //name={inputsGeral[0].nome}
                            //ref={refsGeral.current[NOME_POSITION]}
                            handleEnter={() => {}}
                            label="Texto Lei"
                            handleKey={() => {}}
                            value={descricaoLeiIcms}
                            onChange={(value) =>
                              setDescricaoLeiIcms(value.target.value)
                            }
                            error={errosImpostos[28] !== ''}
                            helperText={errosImpostos[28]}
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Box>
                      </Box>

                      <Box
                        flex={1}
                        style={{
                          padding: '10px',
                          border: '3px solid red',
                          borderRadius: '10px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            color: 'red',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            textDecoration: 'underline',
                          }}
                        >
                          PIS
                        </Typography>

                        <Box
                          style={{
                            padding: '10px',
                            border: '3px solid blue',
                            borderRadius: '10px',
                            marginBottom: '10px',
                          }}
                          //gutterBottom
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              //gutterBottom
                              style={{
                                color: 'blue',
                                fontWeight: 'bold',
                                //textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              ENTRADA
                            </Typography>
                            <IndicadorTravado
                              value={pisEntradaTravado}
                              onChange={(value) => {
                                if (value) {
                                  setPisEntradaTravado(true);
                                } else {
                                  setPisEntradaTravado(false);
                                }
                              }}
                            />
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-pis-entrada">
                              CST
                            </InputLabel>
                            <SelectInput
                              labelId="cst-pis-entrada"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST"
                              handleKey={() => {}}
                              value={cstPisEntrada}
                              onChange={(value) =>
                                setCstPisEntrada(value.target.value)
                              }
                              options={optionsCstPis}
                              error={errosImpostos[15] !== ''}
                              helperText={errosImpostos[15]}
                            />
                          </FormControl>
                          <PorcentagemInput
                            name={inputsImpostos[2].nome}
                            ref={refsImposto.current[2]}
                            label="Aliquota"
                            value={
                              Number.isNaN(
                                isCasoEspecialPisCredito
                                  ? cstPisEntrada === '02'
                                    ? pisCredito
                                    : 0
                                  : pisConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialPisCredito
                                      ? cstPisEntrada === '02'
                                        ? pisCredito
                                        : 0
                                      : pisConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (cstPisEntrada === '02') {
                                setPisCredito(parseFloat(value.target.value));
                              }
                            }}
                            escala={2}
                            handleEnter={() => handleNextInputImpostos(2)}
                            handleKey={handleKey}
                            error={errosImpostos[2] !== ''}
                            helperText={errosImpostos[2]}
                            fullWidth
                            disabled={cstPisEntrada !== '02'}
                          />
                        </Box>
                        <Box
                          style={{
                            padding: '10px',
                            border: '3px solid blue',
                            borderRadius: '10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              //gutterBottom
                              style={{
                                color: 'blue',
                                fontWeight: 'bold',
                                //textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              SAÍDA
                            </Typography>
                            <IndicadorTravado
                              value={pisSaidaTravado}
                              onChange={(value) => {
                                if (value) {
                                  setPisSaidaTravado(true);
                                } else {
                                  setPisSaidaTravado(false);
                                }
                              }}
                            />
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-pis-saida">
                              CST
                            </InputLabel>
                            <SelectInput
                              labelId="cst-pis-saida"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST"
                              handleKey={() => {}}
                              value={cstPisSaida}
                              onChange={(value) =>
                                setCstPisSaida(value.target.value)
                              }
                              options={optionsCstPis}
                              error={errosImpostos[16] !== ''}
                              helperText={errosImpostos[16]}
                            />
                          </FormControl>
                          <PorcentagemInput
                            name={inputsImpostos[3].nome}
                            ref={refsImposto.current[3]}
                            label="Aliquota"
                            value={
                              Number.isNaN(
                                isCasoEspecialPisDebito
                                  ? cstPisSaida === '02'
                                    ? pisDebito
                                    : 0
                                  : pisConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialPisDebito
                                      ? cstPisSaida === '02'
                                        ? pisDebito
                                        : 0
                                      : pisConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (cstPisSaida === '02') {
                                setPisDebito(parseFloat(value.target.value));
                              }
                            }}
                            escala={2}
                            handleEnter={() => handleNextInputImpostos(2)}
                            handleKey={handleKey}
                            error={errosImpostos[3] !== ''}
                            helperText={errosImpostos[3]}
                            fullWidth
                            disabled={cstPisSaida !== '02'}
                          />
                          <TextInput
                            //name={inputsGeral[0].nome}
                            //ref={refsGeral.current[NOME_POSITION]}
                            handleEnter={() => {}}
                            label="Texto Lei"
                            handleKey={() => {}}
                            value={descricaoLeiPis}
                            onChange={(value) =>
                              setDescricaoLeiPis(value.target.value)
                            }
                            error={errosImpostos[26] !== ''}
                            helperText={errosImpostos[26]}
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Box>
                      </Box>

                      <Box
                        flex={1}
                        style={{
                          padding: '10px',
                          border: '3px solid red',
                          borderRadius: '10px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            color: 'red',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            textDecoration: 'underline',
                          }}
                        >
                          COFINS
                        </Typography>

                        <Box
                          style={{
                            padding: '10px',
                            border: '3px solid blue',
                            borderRadius: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              //gutterBottom
                              style={{
                                color: 'blue',
                                fontWeight: 'bold',
                                //textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              ENTRADA
                            </Typography>
                            <IndicadorTravado
                              value={cofinsEntradaTravado}
                              onChange={(value) => {
                                if (value) {
                                  setCofinsEntradaTravado(true);
                                } else {
                                  setCofinsEntradaTravado(false);
                                }
                              }}
                            />
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-cofins-entrada">
                              CST
                            </InputLabel>
                            <SelectInput
                              labelId="cst-cofins-entrada"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST"
                              handleKey={() => {}}
                              value={cstCofinsEntrada}
                              onChange={(value) =>
                                setCstCofinsEntrada(value.target.value)
                              }
                              options={optionsCstCofins}
                              error={errosImpostos[17] !== ''}
                              helperText={errosImpostos[17]}
                            />
                          </FormControl>
                          <PorcentagemInput
                            name={inputsImpostos[4].nome}
                            ref={refsImposto.current[4]}
                            label="Aliquota"
                            value={
                              Number.isNaN(
                                isCasoEspecialCofinsCredito
                                  ? cstCofinsEntrada === '02'
                                    ? cofinsCredito
                                    : 0
                                  : cofinsConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialCofinsCredito
                                      ? cstCofinsEntrada === '02'
                                        ? cofinsCredito
                                        : 0
                                      : cofinsConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (cstCofinsEntrada === '02') {
                                setCofinsCredito(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            escala={2}
                            handleEnter={() => handleNextInputImpostos(4)}
                            handleKey={handleKey}
                            error={errosImpostos[4] !== ''}
                            helperText={errosImpostos[4]}
                            fullWidth
                            disabled={cstCofinsEntrada !== '02'}
                          />
                        </Box>

                        <Box
                          style={{
                            padding: '10px',
                            border: '3px solid blue',
                            borderRadius: '10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              //gutterBottom
                              style={{
                                color: 'blue',
                                fontWeight: 'bold',
                                //textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              SAÍDA
                            </Typography>
                            <IndicadorTravado
                              value={cofinsSaidaTravado}
                              onChange={(value) => {
                                if (value) {
                                  setCofinsSaidaTravado(true);
                                } else {
                                  setCofinsSaidaTravado(false);
                                }
                              }}
                            />
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-cofins-saida">
                              CST
                            </InputLabel>
                            <SelectInput
                              labelId="cst-cofins-saida"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST"
                              handleKey={() => {}}
                              value={cstCofinsSaida}
                              onChange={(value) =>
                                setCstCofinsSaida(value.target.value)
                              }
                              options={optionsCstCofins}
                              error={errosImpostos[18] !== ''}
                              helperText={errosImpostos[18]}
                            />
                          </FormControl>
                          <PorcentagemInput
                            name={inputsImpostos[5].nome}
                            ref={refsImposto.current[5]}
                            label="Aliquota"
                            value={
                              Number.isNaN(
                                isCasoEspecialCofinsDebito
                                  ? cstCofinsSaida === '02'
                                    ? cofinsDebito
                                    : 0
                                  : cofinsConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialCofinsDebito
                                      ? cstCofinsSaida === '02'
                                        ? cofinsDebito
                                        : 0
                                      : cofinsConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (cstCofinsSaida === '02') {
                                setCofinsDebito(parseFloat(value.target.value));
                              }
                            }}
                            escala={2}
                            handleEnter={() => handleNextInputImpostos(5)}
                            handleKey={handleKey}
                            error={errosImpostos[5] !== ''}
                            helperText={errosImpostos[5]}
                            fullWidth
                            disabled={cstCofinsSaida !== '02'}
                          />
                          <TextInput
                            //name={inputsGeral[0].nome}
                            //ref={refsGeral.current[NOME_POSITION]}
                            handleEnter={() => {}}
                            label="Texto Lei"
                            handleKey={() => {}}
                            value={descricaoLeiCofins}
                            onChange={(value) =>
                              setDescricaoLeiCofins(value.target.value)
                            }
                            error={errosImpostos[27] !== ''}
                            helperText={errosImpostos[27]}
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel
                  value={value}
                  //index={isPorPesoFixo || isPorUnidade ? 1 : 2}
                  index={5}
                >
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Typography variant="h6" gutterBottom>
                        Impostos
                      </Typography>

                      {/*<Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[0].nome}
                            ref={refsImposto.current[0]}
                            handleEnter={() => handleNextInputImpostos(0)}
                            label={inputsImpostos[0].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(ipiCredito) ? '' : String(ipiCredito)
                            }
                            onChange={(value) =>
                              setIpiCredito(parseFloat(value.target.value))
                            }
                            error={errosImpostos[0] !== ''}
                            helperText={errosImpostos[0]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialIpiCredito}
                            onChangeIsCasoEspecial={(value) =>
                              setIsCasoEspecialIpiCredito(value)
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[1].nome}
                            ref={refsImposto.current[1]}
                            handleEnter={() => handleNextInputImpostos(1)}
                            label={inputsImpostos[1].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(ipiDebito) ? '' : String(ipiDebito)
                            }
                            onChange={(value) =>
                              setIpiDebito(parseFloat(value.target.value))
                            }
                            error={errosImpostos[1] !== ''}
                            helperText={errosImpostos[1]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialIpiDebito}
                            onChangeIsCasoEspecial={(value) =>
                              setIsCasoEspecialIpiDebito(value)
                            }
                          />
                        </Box>
                          </Box>*/}

                      {/*<Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[2].nome}
                            ref={refsImposto.current[2]}
                            handleEnter={() => handleNextInputImpostos(2)}
                            label={inputsImpostos[2].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialPisCredito
                                  ? pisCredito
                                  : pisConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialPisCredito
                                      ? pisCredito
                                      : pisConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialPisCredito) {
                                setPisCredito(parseFloat(value.target.value));
                              }
                            }}
                            error={errosImpostos[2] !== ''}
                            helperText={errosImpostos[2]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialPisCredito}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialPisCredito(value);
                              if (isCasoEspecialPisCredito) {
                                setPisCredito(pisConfig);
                              }
                            }}
                            disabled={!isCasoEspecialPisCredito}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[3].nome}
                            ref={refsImposto.current[3]}
                            handleEnter={() => handleNextInputImpostos(3)}
                            label={inputsImpostos[3].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialPisDebito ? pisDebito : pisConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialPisDebito
                                      ? pisDebito
                                      : pisConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialPisDebito) {
                                setPisDebito(parseFloat(value.target.value));
                              }
                            }}
                            error={errosImpostos[3] !== ''}
                            helperText={errosImpostos[3]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialPisDebito}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialPisDebito(value);
                              if (isCasoEspecialPisDebito) {
                                setPisDebito(pisConfig);
                              }
                            }}
                            disabled={!isCasoEspecialPisDebito}
                          />
                        </Box>
                      </Box>*/}

                      {/*<Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[4].nome}
                            ref={refsImposto.current[4]}
                            handleEnter={() => handleNextInputImpostos(4)}
                            label={inputsImpostos[4].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialCofinsCredito
                                  ? cofinsCredito
                                  : cofinsConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialCofinsCredito
                                      ? cofinsCredito
                                      : cofinsConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialCofinsCredito) {
                                setCofinsCredito(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            error={errosImpostos[4] !== ''}
                            helperText={errosImpostos[4]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialCofinsCredito}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialCofinsCredito(value);
                              if (isCasoEspecialCofinsCredito) {
                                setCofinsCredito(cofinsConfig);
                              }
                            }}
                            disabled={!isCasoEspecialCofinsCredito}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[5].nome}
                            ref={refsImposto.current[5]}
                            handleEnter={() => handleNextInputImpostos(5)}
                            label={inputsImpostos[5].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialCofinsDebito
                                  ? cofinsDebito
                                  : cofinsConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialCofinsDebito
                                      ? cofinsDebito
                                      : cofinsConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialCofinsDebito) {
                                setCofinsDebito(parseFloat(value.target.value));
                              }
                            }}
                            error={errosImpostos[5] !== ''}
                            helperText={errosImpostos[5]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialCofinsDebito}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialCofinsDebito(value);
                              if (isCasoEspecialCofinsDebito) {
                                setCofinsDebito(cofinsConfig);
                              }
                            }}
                            disabled={!isCasoEspecialCofinsDebito}
                          />
                        </Box>
                      </Box>*/}

                      {/*<Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[6].nome}
                            ref={refsImposto.current[6]}
                            handleEnter={() => handleNextInputImpostos(6)}
                            label={inputsImpostos[6].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialIcmsNormalCredito
                                  ? icmsNormalCredito
                                  : icms_ai_compraConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialIcmsNormalCredito
                                      ? icmsNormalCredito
                                      : icms_ai_compraConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialIcmsNormalCredito) {
                                setIcmsNormalCredito(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            error={errosImpostos[6] !== ''}
                            helperText={errosImpostos[6]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialIcmsNormalCredito}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialIcmsNormalCredito(value);
                              if (isCasoEspecialIcmsNormalCredito) {
                                setIcmsNormalCredito(icms_ai_compraConfig);
                              }
                            }}
                            disabled={!isCasoEspecialIcmsNormalCredito}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[7].nome}
                            ref={refsImposto.current[7]}
                            handleEnter={() => handleNextInputImpostos(7)}
                            label={inputsImpostos[7].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialIcmsNormalDebito
                                  ? icmsNormalDebito
                                  : icms_ai_compraConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialIcmsNormalDebito
                                      ? icmsNormalDebito
                                      : icms_ai_compraConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialIcmsNormalDebito) {
                                setIcmsNormalDebito(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            error={errosImpostos[7] !== ''}
                            helperText={errosImpostos[7]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialIcmsNormalDebito}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialIcmsNormalDebito(value);
                              if (isCasoEspecialIcmsNormalDebito) {
                                setIcmsNormalDebito(icms_ai_compraConfig);
                              }
                            }}
                            disabled={!isCasoEspecialIcmsNormalDebito}
                          />
                        </Box>
                      </Box>*/}

                      {/*<Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[8].nome}
                            ref={refsImposto.current[8]}
                            handleEnter={() => handleNextInputImpostos(8)}
                            label={inputsImpostos[8].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialIcmsFronteira
                                  ? icmsFronteira
                                  : Number.NaN,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialIcmsFronteira
                                      ? icmsFronteira
                                      : Number.NaN,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialIcmsFronteira) {
                                setIcmsFonteira(parseFloat(value.target.value));
                              }
                            }}
                            error={errosImpostos[8] !== ''}
                            helperText={errosImpostos[8]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialIcmsFronteira}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialIcmsFronteira(value);
                              if (isCasoEspecialIcmsFronteira) {
                                setIcmsFonteira(0);
                              }
                            }}
                            disabled={!isCasoEspecialIcmsFronteira}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[9].nome}
                            ref={refsImposto.current[9]}
                            handleEnter={() => handleNextInputImpostos(9)}
                            label={inputsImpostos[9].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(
                                isCasoEspecialIcmsAntecipacao
                                  ? icmsAntecipacao
                                  : icms_antecipacaoConfig,
                              )
                                ? ''
                                : String(
                                    isCasoEspecialIcmsAntecipacao
                                      ? icmsAntecipacao
                                      : icms_antecipacaoConfig,
                                  )
                            }
                            onChange={(value) => {
                              if (isCasoEspecialIcmsAntecipacao) {
                                setIcmsAntecipacao(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            error={errosImpostos[9] !== ''}
                            helperText={errosImpostos[9]}
                            fullWidth
                            autoFocus
                            isCasoEspecial={isCasoEspecialIcmsAntecipacao}
                            onChangeIsCasoEspecial={(value) => {
                              setIsCasoEspecialIcmsAntecipacao(value);
                              if (isCasoEspecialIcmsAntecipacao) {
                                setIcmsAntecipacao(icms_antecipacaoConfig);
                              }
                            }}
                            disabled={!isCasoEspecialIcmsAntecipacao}
                          />
                        </Box>
                      </Box>*/}

                      {/*<Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <ImpostoInput
                            name={inputsImpostos[0].nome}
                            ref={refsImposto.current[0]}
                            handleEnter={() =>
                              handleNextInputImpostos(IPI_POSITION)
                            }
                            label="PIS Crédito"
                            handleKey={handleKey}
                            value={
                              Number.isNaN(pisCredito) ? '' : String(pisCredito)
                            }
                            onChange={(value) =>
                              setPisCredito(parseFloat(value.target.value))
                            }
                            error={errosImpostos[0] !== ''}
                            helperText={errosImpostos[0]}
                            fullWidth
                            autoFocus
                            isCasoEspecial
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[0].nome}
                            ref={refsImposto.current[0]}
                            handleEnter={() =>
                              handleNextInputImpostos(IPI_POSITION)
                            }
                            label={inputsImpostos[0].label}
                            handleKey={handleKey}
                            value={Number.isNaN(ipi) ? '' : String(ipi)}
                            onChange={(value) =>
                              setIpi(parseFloat(value.target.value))
                            }
                            error={errosImpostos[0] !== ''}
                            helperText={errosImpostos[0]}
                            fullWidth
                            escala={2}
                            autoFocus
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[1].nome}
                            ref={refsImposto.current[1]}
                            handleEnter={() =>
                              handleNextInputImpostos(PIS_POSITION)
                            }
                            label={inputsImpostos[1].label}
                            handleKey={handleKey}
                            value={Number.isNaN(pis) ? '' : String(pis)}
                            onChange={(value) =>
                              setPis(parseFloat(value.target.value))
                            }
                            error={errosImpostos[1] !== ''}
                            helperText={errosImpostos[1]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[2].nome}
                            ref={refsImposto.current[2]}
                            handleEnter={() =>
                              handleNextInputImpostos(COFINS_POSITION)
                            }
                            label={inputsImpostos[2].label}
                            handleKey={handleKey}
                            value={Number.isNaN(cofins) ? '' : String(cofins)}
                            onChange={(value) =>
                              setCofins(parseFloat(value.target.value))
                            }
                            error={errosImpostos[2] !== ''}
                            helperText={errosImpostos[2]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                          </Box>*/}
                      {/*<Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[3].nome}
                            ref={refsImposto.current[3]}
                            handleEnter={() =>
                              handleNextInputImpostos(ICMS_AI_POSITION)
                            }
                            label={inputsImpostos[3].label}
                            handleKey={handleKey}
                            value={Number.isNaN(icmsAi) ? '' : String(icmsAi)}
                            onChange={(value) =>
                              setIcmsAi(parseFloat(value.target.value))
                            }
                            error={errosImpostos[3] !== ''}
                            helperText={errosImpostos[3]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[4].nome}
                            ref={refsImposto.current[4]}
                            handleEnter={() =>
                              handleNextInputImpostos(ICMS_INT_POSITION)
                            }
                            label={inputsImpostos[4].label}
                            handleKey={handleKey}
                            value={Number.isNaN(icmsInt) ? '' : String(icmsInt)}
                            onChange={(value) =>
                              setIcmsInt(parseFloat(value.target.value))
                            }
                            error={errosImpostos[4] !== ''}
                            helperText={errosImpostos[4]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[5].nome}
                            ref={refsImposto.current[5]}
                            handleEnter={() =>
                              handleNextInputImpostos(DF_POSITION)
                            }
                            label={inputsImpostos[5].label}
                            handleKey={handleKey}
                            value={Number.isNaN(df) ? '' : String(df)}
                            onChange={(value) =>
                              setDf(parseFloat(value.target.value))
                            }
                            error={errosImpostos[5] !== ''}
                            helperText={errosImpostos[5]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                          </Box>*/}
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[10].nome}
                            ref={refsImposto.current[10]}
                            handleEnter={() => handleNextInputImpostos(10)}
                            label={inputsImpostos[10].label}
                            handleKey={handleKey}
                            value={Number.isNaN(df) ? '' : String(df)}
                            onChange={(value) =>
                              setDf(parseFloat(value.target.value))
                            }
                            error={errosImpostos[10] !== ''}
                            helperText={errosImpostos[10]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[11].nome}
                            ref={refsImposto.current[11]}
                            handleEnter={() => handleNextInputImpostos(11)}
                            label={inputsImpostos[11].label}
                            handleKey={handleKey}
                            value={Number.isNaN(rbc) ? '' : String(rbc)}
                            onChange={(value) =>
                              setRbc(parseFloat(value.target.value))
                            }
                            error={errosImpostos[11] !== ''}
                            helperText={errosImpostos[11]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PrecoInput
                            name={inputsImpostos[20].nome}
                            ref={refsImposto.current[20]}
                            handleEnter={() => handleNextInputGeral(20)}
                            label={inputsImpostos[20].label}
                            handleKey={handleKey}
                            value={Number.isNaN(mva) ? '' : String(mva)}
                            onChange={(value) =>
                              setMva(parseFloat(value.target.value))
                            }
                            error={errosImpostos[20] !== ''}
                            helperText={errosImpostos[20]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Typography variant="h6" gutterBottom>
                        Códigos
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <NcmInput
                            name={inputsImpostos[12].nome}
                            ref={refsImposto.current[12]}
                            handleEnter={() => handleNextInputImpostos(12)}
                            label={inputsImpostos[12].label}
                            handleKey={handleKey}
                            value={ncm}
                            onChange={(value) => setNcm(value.target.value)}
                            error={errosImpostos[12] !== ''}
                            helperText={errosImpostos[12]}
                            fullWidth
                          />
                        </Box>
                        {/*  <Box flex={1} ml="0.5em" mr="0.5em">
                          <CstInput
                            name={inputsImpostos[13].nome}
                            ref={refsImposto.current[13]}
                            handleEnter={() => handleNextInputImpostos(13)}
                            label={inputsImpostos[13].label}
                            handleKey={handleKey}
                            value={cst}
                            onChange={(value) => setCst(value.target.value)}
                            error={errosImpostos[13] !== ''}
                            helperText={errosImpostos[13]}
                            fullWidth
                          />
                        </Box> */}
                        <Box flex={1} ml="0.5em">
                          <CfopInput
                            name={inputsImpostos[14].nome}
                            ref={refsImposto.current[14]}
                            handleEnter={() => handleNextInputImpostos(14)}
                            label={inputsImpostos[14].label}
                            handleKey={handleKey}
                            value={cfop}
                            //onChange={(value) => setCfop(value.target.value)}
                            error={errosImpostos[14] !== ''}
                            helperText={errosImpostos[14]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CstInput
                            name={inputsImpostos[15].nome}
                            ref={refsImposto.current[15]}
                            handleEnter={() => handleNextInputImpostos(15)}
                            label={inputsImpostos[15].label}
                            handleKey={handleKey}
                            value={cstPisEntrada}
                            onChange={(value) =>
                              setCstPisEntrada(value.target.value)
                            }
                            error={errosImpostos[15] !== ''}
                            helperText={errosImpostos[15]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" mr="0.5em">
                          <CstInput
                            name={inputsImpostos[16].nome}
                            ref={refsImposto.current[16]}
                            handleEnter={() => handleNextInputImpostos(16)}
                            label={inputsImpostos[16].label}
                            handleKey={handleKey}
                            value={cstPisSaida}
                            onChange={(value) =>
                              setCstPisSaida(value.target.value)
                            }
                            error={errosImpostos[16] !== ''}
                            helperText={errosImpostos[16]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <CstInput
                            name={inputsImpostos[17].nome}
                            ref={refsImposto.current[17]}
                            handleEnter={() => handleNextInputImpostos(17)}
                            label={inputsImpostos[17].label}
                            handleKey={handleKey}
                            value={cstCofinsEntrada}
                            onChange={(value) =>
                              setCstCofinsEntrada(value.target.value)
                            }
                            error={errosImpostos[17] !== ''}
                            helperText={errosImpostos[17]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CstInput
                            name={inputsImpostos[18].nome}
                            ref={refsImposto.current[18]}
                            handleEnter={() => handleNextInputImpostos(18)}
                            label={inputsImpostos[18].label}
                            handleKey={handleKey}
                            value={cstCofinsSaida}
                            onChange={(value) =>
                              setCstCofinsSaida(value.target.value)
                            }
                            error={errosImpostos[18] !== ''}
                            helperText={errosImpostos[18]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" mr="0.5em">
                          <CestInput
                            name={inputsImpostos[19].nome}
                            ref={refsImposto.current[19]}
                            handleEnter={() => handleNextInputImpostos(19)}
                            label={inputsImpostos[19].label}
                            handleKey={handleKey}
                            value={cest}
                            onChange={(value) => setCest(value.target.value)}
                            error={errosImpostos[19] !== ''}
                            helperText={errosImpostos[19]}
                            fullWidth
                          />
                        </Box>
                        {/* <Box flex={1} ml="0.5em">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-pis-entrada">
                              CST PIS ENTRADA
                            </InputLabel>
                            <SelectInput
                              labelId="cst-pis-entrada"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST PIS ENTRADA"
                              handleKey={() => {}}
                              value={cstPisEntrada}
                              onChange={(value) =>
                                setCstPisEntrada(value.target.value)
                              }
                              options={optionsCstPis}
                            />
                          </FormControl>
                        </Box> */}
                        {/*           <Box flex={1} ml="0.5em">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-pis-saida">
                              CST PIS SAIDA
                            </InputLabel>
                            <SelectInput
                              labelId="cst-pis-saida"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST PIS SAIDA"
                              handleKey={() => {}}
                              value={cstPisSaida}
                              onChange={(value) =>
                                setCstPisSaida(value.target.value)
                              }
                              options={optionsCstPis}
                            />
                          </FormControl>
                        </Box> */}

                        {/*            <Box flex={1} ml="0.5em">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-cofins-entrada">
                              CST COFINS ENTRADA
                            </InputLabel>
                            <SelectInput
                              labelId="cst-cofins-entrada"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST COFINS ENTRADA"
                              handleKey={() => {}}
                              value={cstCofinsEntrada}
                              onChange={(value) =>
                                setCstCofinsEntrada(value.target.value)
                              }
                              options={optionsCstCofins}
                            />
                          </FormControl>
                        </Box> */}

                        {/*                    <Box flex={1} ml="0.5em">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-cofins-saida">
                              CST COFINS SAIDA
                            </InputLabel>
                            <SelectInput
                              labelId="cst-cofins-saida"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST COFINS SAIDA"
                              handleKey={() => {}}
                              value={cstCofinsSaida}
                              onChange={(value) =>
                                setCstCofinsSaida(value.target.value)
                              }
                              options={optionsCstCofins}
                            />
                          </FormControl>
                        </Box> */}
                        {/* 
                        <Box flex={1} ml="0.5em">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                            }}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel shrink id="cst-icms-entrada">
                              CST ICMS ENTRADA
                            </InputLabel>
                            <SelectInput
                              labelId="cst-icms-entrada"
                              name="turno"
                              handleEnter={() => {}}
                              label="CST ICMS ENTRADA"
                              handleKey={() => {}}
                              value={cst}
                              onChange={(value) => setCst(value.target.value)}
                              options={optionsCstIcms}
                            />
                          </FormControl>
                        </Box> */}
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                {isIndustrializado && (
                  <TabPanel
                    value={value}
                    index={!isPorPesoFixo && !isPorUnidade ? 3 : 2}
                  >
                    <Box>
                      <Box>
                        <AutoCompleteRemoto
                          name="produtoBase"
                          handleEnter={() => {}}
                          label="Produto Base"
                          handleKey={handleKey}
                          value={produtoBase}
                          onChange={(value) => setProdutoBase(value)}
                          fullWidth
                          toString={false}
                          getOptionSelected={(option, value) =>
                            option.nome === value.nome
                          }
                          getOptionLabel={(option) => `${option.nome}`}
                          filters={{ ativo: true }}
                          resource="produtos/search/autocomplete"
                          nested={['unidade']}
                        />
                      </Box>
                      <Box mt="1em" />

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Custos
                        </Typography>
                        <Button
                          onClick={() => criarCusto()}
                          variant="contained"
                          color="primary"
                        >
                          Adicionar
                        </Button>
                      </div>
                      <Box mt="1em" />

                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Nome</StyledTableCell>
                              <StyledTableCell align="right">
                                Valor Unitário
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Qtde
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Total
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {custos.map((row) => {
                              const { nome, preco, peso } = row;
                              return (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell component="th" scope="row">
                                    {nome}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {formatMoeda(preco)}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {peso}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {formatMoeda(preco * peso)}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="edit"
                                        classes={{ root: classes.root }}
                                        size="small"
                                        onClick={() =>
                                          editarCusto(
                                            row.id,
                                            row.nome,
                                            row.preco,
                                            row.peso,
                                          )
                                        }
                                      >
                                        <EditOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Deletar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        classes={{ root: classes.root }}
                                        size="small"
                                        onClick={() =>
                                          deletarCusto(
                                            row.id,
                                            row.nome,
                                            row.preco,
                                            row.peso,
                                          )
                                        }
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </TabPanel>
                )}
                <TabPanel
                  value={value}
                  index={
                    !isPorPesoFixo && !isPorUnidade
                      ? isIndustrializado
                        ? 4
                        : 3
                      : isIndustrializado
                      ? 3
                      : 2
                  }
                >
                  <div>
                    <Typography variant="h6" gutterBottom>
                      Precificação
                    </Typography>

                    <Box display="flex" alignItems="center">
                      <Box flex={1} mr="0.5em">
                        <Box flex={1} mr="0.5em" mt="0px" mb="8px">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                              backgroundColor: 'white',
                            }}
                            fullWidth
                          >
                            <InputLabel
                              shrink
                              id="porcentagem_custo_operacional"
                            >
                              Porcentagem do Custo Operacional
                            </InputLabel>
                            <SelectInput
                              labelId="porcentagem_custo_operacional"
                              name="porcentagem_custo_operacional"
                              handleEnter={() => {}}
                              label="Porcentagem do Custo Operacional"
                              handleKey={() => {}}
                              value={porcentagemCustoOperacional}
                              onChange={(value) => {
                                setPorcentagemCustoOperacional(
                                  value.target.value,
                                );
                              }}
                              options={optionsPorcentagemCustoOperacional}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                      <Box flex={1} mr="0.5em" ml="0.5em" />
                      <Box flex={1} ml="0.5em" />
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Ofertas
                    </Typography>

                    <Box display="flex" alignItems="center">
                      <Box flex={1} mr="0.5em">
                        <SwitchInput
                          name="permitir_criacao_ofertas"
                          handleEnter={() => {}}
                          label="Permitir de Criação de Ofertas"
                          handleKey={() => {}}
                          checked={permitirCriacaoOfertas}
                          onChange={(value) =>
                            setPermitirCriacaoOfertas(value.target.checked)
                          }
                          fullWidth
                          disabled={!isComprador}
                        />
                      </Box>
                      <Box flex={1} mr="0.5em" ml="0.5em">
                        <SwitchInput
                          name="permitir_criacao_liberacoes"
                          handleEnter={() => {}}
                          label="Permitir de Criação de Liberações de Preços"
                          handleKey={() => {}}
                          checked={permitirCriacaoLiberacoes}
                          onChange={(value) =>
                            setPermitirCriacaoLiberacoes(value.target.checked)
                          }
                          fullWidth
                          disabled={!isComprador}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <PorcentagemInput
                          fullWidth
                          source="pis"
                          label="Desconto Máximo (%)"
                          error={errosComprador[0] !== ''}
                          helperText={errosComprador[0]}
                          handleEnter={() => {}}
                          handleKey={() => {}}
                          value={
                            Number.isNaN(descontoMaximoPrecoVenda)
                              ? ''
                              : String(descontoMaximoPrecoVenda)
                          }
                          onChange={(value) =>
                            setDescontoMaximoPrecoVenda(
                              parseFloat(value.target.value),
                            )
                          }
                          disabled={!isComprador}
                        />
                      </Box>
                    </Box>
                    <Box mt="1em" />
                    <Typography variant="h6" gutterBottom>
                      Pallets
                    </Typography>

                    <Box display="flex" alignItems="center">
                      <Box flex={1} mr="0.5em">
                        <IntegerInput
                          handleEnter={() => {}}
                          label="Mesa (lastro) do Pallet"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(basePallete) ? '' : String(basePallete)
                          }
                          onChange={(value) =>
                            setBasePallete(parseInt(value.target.value, 10))
                          }
                          error={errosComprador[3] !== ''}
                          helperText={errosComprador[3]}
                          fullWidth
                          disabled={!isComprador}
                        />
                      </Box>
                      <Box flex={1} mr="0.5em" ml="0.5em">
                        <IntegerInput
                          handleEnter={() => {}}
                          label="Altura do Pallet"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(alturaPallete)
                              ? ''
                              : String(alturaPallete)
                          }
                          onChange={(value) =>
                            setAlturaPallete(parseInt(value.target.value, 10))
                          }
                          error={errosComprador[4] !== ''}
                          helperText={errosComprador[4]}
                          fullWidth
                          disabled={!isComprador}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" display="flex">
                        <IntegerInput
                          handleEnter={() => {}}
                          label="Quantidade Total no Pallet"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(basePallete * alturaPallete)
                              ? ''
                              : String(basePallete * alturaPallete)
                          }
                          onChange={(value) => {}}
                          error={errosComprador[6] !== ''}
                          helperText={errosComprador[6]}
                          fullWidth
                          disabled
                        />
                      </Box>
                    </Box>
                    <Box mt="1em" />
                    <Typography variant="h6" gutterBottom>
                      Fornecedor
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          handleEnter={() => {}}
                          label="Referência do Fornecedor"
                          handleKey={() => {}}
                          value={nomenclaturaReferenciaFornecedor}
                          onChange={(value) =>
                            setNomenclaturaReferenciaFornecedor(
                              value.target.value,
                            )
                          }
                          error={errosComprador[2] !== ''}
                          helperText={errosComprador[2]}
                          fullWidth
                          autoFocus
                          disabled={!isComprador}
                        />
                      </Box>
                      <Box flex={1} mr="0.5em" ml="0.5em" />
                      <Box flex={1} ml="0.5em" />
                    </Box>
                    <Box mt="1em" />
                    <Typography variant="h6" gutterBottom>
                      Pré Compra
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Box flex={1} mr="0.5em">
                        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                          <FormControl
                            variant="outlined"
                            style={{
                              marginTop: '16px',
                              backgroundColor: 'white',
                              margin: 0,
                            }}
                            fullWidth
                          >
                            <InputLabel
                              shrink
                              id="unidade_preco_negociado_pre_compra"
                            >
                              Unidade do Preço negociodo na Pré Compra
                            </InputLabel>
                            <SelectInput
                              labelId="unidade_preco_negociado_pre_compra"
                              name="unidade_preco_negociado_pre_compra"
                              handleEnter={() => {}}
                              label="Unidade do Preço negociodo na Pré Compra"
                              handleKey={() => {}}
                              value={tipoUnidadePrecoPreCompra2}
                              onChange={(value) => {
                                setTipoUnidadePrecoPreCompra(
                                  value.target.value,
                                );
                              }}
                              options={optionsUnidadePrecoPreCompra}
                              disabled={
                                isPorPesoFixo ||
                                isPorPesoVariavel ||
                                !isComprador
                              }
                            />
                          </FormControl>
                        </Box>
                      </Box>
                      <Box flex={1} mr="0.5em" ml="0.5em">
                        <PesoInput
                          handleEnter={() => {}}
                          label="Peso Unitário (PU)"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(pesoUnitario2)
                              ? ''
                              : String(pesoUnitario2)
                          }
                          onChange={(value) => {
                            if (isPorUnidade) {
                              setPesoUnitario(
                                parseFloat(value.target.value, 10),
                              );
                            }
                          }}
                          error={errosComprador[1] !== ''}
                          helperText={errosComprador[1]}
                          fullWidth
                          disabled={!isPorUnidade || !isComprador}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <VolumeInput
                          handleEnter={() => {}}
                          label="Volume Unitário (VU)"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(volumeUnitario)
                              ? ''
                              : String(volumeUnitario)
                          }
                          onChange={(value) =>
                            setVolumeUnitario(
                              parseFloat(value.target.value, 10),
                            )
                          }
                          error={errosComprador[5] !== ''}
                          helperText={errosComprador[5]}
                          fullWidth
                          disabled={!isComprador}
                        />
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box flex={1} mr="0.5em">
                        <SwitchInput
                          name="pertencente_ao_mix"
                          handleEnter={() => {}}
                          label="Pertencente ao MIX"
                          handleKey={handleKey}
                          checked={pertencenteAoMix}
                          onChange={(value) =>
                            setPertencenteAoMix(value.target.checked)
                          }
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} mr="0.5em" ml="0.5em" />
                      <Box flex={1} ml="0.5em" />
                    </Box>
                  </div>
                </TabPanel>
              </Box>
            )}
          </Box>
        </div>
        <DialogoSenha
          ref={refDialogoSenha}
          handleCancelar={() => {}}
          handleSalvar={() => {
            setIsPodeEditarPreco(true);
          }}
          title="Autorização editar preços caixaria"
          enqueueSnackbar={enqueueSnackbar}
          mensagemSucesso="Edição do preço das caixarias!!"
        />
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => handleCloseDialog(-1)} color="primary">
          Cancelar (F4)
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              verificaCaixaria();
            }}
            disabled={erroExistente || carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Cadastrar (F8)
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
      <DialogoCreateEditCusto
        ref={refDialogoCusto}
        handleClose={() => {}}
        handleSave={handleSave}
      />
      <DialogoDeletarCusto
        ref={refDialogoDeletarCusto}
        handleClose={() => {}}
        handleDelete={handleDeletarCusto}
      />
    </Dialog>
  );
});

export default SidebarInputs;
