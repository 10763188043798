import {
  PAGO,
  PARCIALMENTE_PAGO,
  NAO_PAGO,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
  TIPO_COMPRA_INSUMOS_UTILIZACAO,
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  TIPO_COMPRA_SERVICOS_PRESTADO_A_EMPRESA,
} from '../../utils';

export const choicesPagamento = [
  { id: NAO_PAGO, name: 'Não Pago' },
  { id: PARCIALMENTE_PAGO, name: 'Parcialmente Pago' },
  { id: PAGO, name: 'Pago' },
];

export const choicesTipoCompra = [
  {
    id: TIPO_COMPRA_MERCADORIAS_REVENDA,
    name: 'Compra de mercadorias para Revenda',
  },
  {
    id: TIPO_COMPRA_INSUMOS_UTILIZACAO,
    name: 'Compra de Insumos para utilização',
  },
  {
    id: TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
    name: 'Bonificação de mercadorias para Revenda',
  },
  {
    id: TIPO_COMPRA_SERVICOS_PRESTADO_A_EMPRESA,
    name: 'Serviços prestados a nossa empresa',
  },
];
