import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

import {
  PeriodoInput,
  PrecoInput,
  IntegerInput,
  NfeInput,
  ChaveAcessoNfeInput,
} from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';
import { choicesPagamento, choicesTipoCompra } from '../Compras/choices';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Fornecedor"
      source="fornecedor_id"
      reference="fornecedores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={choicesPagamento}
      allowEmpty={false}
    />
    <PeriodoInput
      source="periodo"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <PrecoInput
      source="valor"
      label="Valor da compra"
      fixedDecimalScale={false}
    />
    <PrecoInput
      source="valorParcela"
      label="Valor Parcela"
      fixedDecimalScale={false}
    />
    <ChaveAcessoNfeInput
      source="chave_acesso_nota_fiscal"
      label="Chave de Acesso NFe"
    />
    <NfeInput source="numero_nota_fiscal" label="Nfe" />
    <IntegerInput source="numero" label="Número da compra" prefixo="#" />
    <BooleanInput
      label="Compra Por Importação XML"
      source="is_importacao_xml"
    />
    {/* <SelectInput source="tipo" choices={choicesTipoCompra} allowEmpty={false} /> */}
  </FilterAdmin>
);

export default Filter;
