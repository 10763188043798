import React, { useState, useRef, useEffect, useContext } from 'react';

import { Box } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  DialogoConfirmacao,
  DialogoNota,
  DialogoFornecedor,
  Table,
  AutoCompleteRemoto,
  Actions,
  DialogoDeletarItemTabela,
  LabelSubtotal,
  LabelTotalParcelas,
  AutoCompleteLocal,
} from '../../../..';
import logo from '../../../../../assets/logo.png';
import { getListAllAPI, getUsername } from '../../../../../services';
import {
  formatCPF,
  formatCNPJ,
  formatMoeda,
  STATUS_ATIVO,
  toStringParcelaCompra,
  getTotalCompra,
  round10,
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
} from '../../../../../utils';
import {
  LabelTeclasAtalho,
  //Tabela,
  DialogoDeletarComSenha,
} from '../../../../DialogoEditarVenda/components';
import { useParcelasCompra } from '../../hooks';
import { CompraContext } from '../contextCompra';
import AppBarNaturezaCompra from '../PreInicioFrenteCaixaCompra/AppBarNaturezaCompra';
import DialogoDetalhes from '../PreInicioFrenteCaixaCompra/DialogoDetalhes';
import AppBar from './AppBar';
import ContainerNumeroNotaFiscalInput from './ContainerNumeroNotaFiscalInput';
import DialogoParcelaCompra from './DialogoParcelaCompra';
import FormParcelaCompra from './FormParcelaCompra';
import Tabela from './Tabela2/indexCompra';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    /*  height: '17%', */
  },
  body: {
    /* height: '83%', */
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
}));

const VOLTAR_ACTION = 'F4';
const FINALIZAR_ACTION = 'F8';
const NOVO_FORNECEDOR_ACTION = 'F9';
const DELETAR_PARCELA_ACTION = 'F2';
const EDITAR_FORNECEDOR_ACTION = 'F7';
const IMPRIMIR_ACTION = 'F10';
const ADICIONAR_LOTE_ACTION = 'F3';
const DETALHES_ACTION = 'DETALHES';

const optionsLoja = [
  {
    nome: 'CARUARU FRIOS',
    value: 'CF',
  },
  {
    nome: 'CASA DA PIZZA',
    value: 'CP',
  },
];

const FinalizarCompra = ({
  editarModo = false,
  irParaTelaInit,
  irParaTelaFrentedeCaixa,
  showAtendente = true,
}) => {
  const classes = useStyles();
  const { compra, dispatch } = useContext(CompraContext);
  const {
    fornecedor,
    parcelas,
    idEdit,
    numeroNotaFiscal,
    loja,
    data_faturamento,
    data_criacao,
    //
    repasse_frete,
    repasse_ipi,
    repasse_outras_despesas,
    repasse_seguros,
    repasse_st,
    //
    subTotalProdutos,
    total_repasse_frete,
    total_repasse_ipi,
    total_repasse_outras_despesas,
    total_repasse_seguros,
    total_repasse_st,
    lotes,
    serieNotaFiscal,
    chaveAcessoNotaFiscal,
    isImportacaoXml,
    numeroCompra,
    tipo,
    uf,
    bonificacao_usada_id,
  } = compra;
  const [atendente, setAtendente] = useState('');
  const refSidebarInputs = useRef(null);
  const refFornecedor = useRef(null);
  const refNotaFiscal = useRef(null);
  const refLoja = useRef(null);

  const refDialogoFornecedor = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoConfirmacao = useRef(null);
  const refDialogoDetalhes = useRef(null);

  const refDialogoParcelaCompra = useRef(null);

  const {
    resto,
    totalParcelas,
    handleOpenNota,
    refDialogoNota,
    handleNewItem,
    handleOpenNotaLocal,
    enqueueSnackbar,
    podeFecharCompra,
  } = useParcelasCompra(
    parcelas,
    compra.lotes,
    fornecedor,
    compra.subTotal,
    updateParcela,
    editarModo,
    idEdit,
    numeroNotaFiscal,
    loja,
    data_faturamento,
    editarPrazosFornecedor,
    repasse_frete,
    repasse_ipi,
    repasse_outras_despesas,
    repasse_seguros,
    repasse_st,
    serieNotaFiscal,
    chaveAcessoNotaFiscal,
    isImportacaoXml,
    tipo,
    uf,
    bonificacao_usada_id,
  );

  function updateParcela(body) {
    dispatch({
      type: 'ADD_PARCELA',
      parcela: body,
    });
  }

  function atualizarParcela(body) {
    dispatch({
      type: 'UPDATE_PARCELA',
      parcela: body,
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (refFornecedor.current) {
        refFornecedor.current.focus();
        refFornecedor.current.select();
      }
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  /*   useEffect(() => {
    if (compra.lotes.length <= 0) irParaTelaFrentedeCaixa();
  }, [compra.lotes]); */

  async function getAtendente() {
    const username = getUsername();
    setAtendente(username || '');
  }

  useEffect(() => {
    getAtendente();
  }, []);

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_PARCELA', indices });
  }

  const handleClose = () => {
    irParaTelaFrentedeCaixa();
  };

  function closeDialogoNota(tipoNota) {
    if (tipoNota === 0) {
      irParaTelaInit();
    }
  }

  function getValorTotalDescontoBoletosItens() {
    let total = 0;
    for (let i = 0; i < lotes.length; i++) {
      const lote = lotes[i];
      total += getTotalCompra(
        lote.peso,
        lote.unidades,
        lote.dadosPrecificacao ? lote.dadosPrecificacao.desconto_em_boleto : 0,
        lote.produto,
      );
    }
    return round10(total, -2);
  }

  function getValorTotalDescontoBoletosParcelas() {
    let total = 0;
    for (let i = 0; i < parcelas.length; i++) {
      const parcela = parcelas[i];
      total += parcela.desconto;
    }
    return round10(total, -2);
  }

  const totalDescontoItens = getValorTotalDescontoBoletosItens();
  const totalDescontoParcelas = getValorTotalDescontoBoletosParcelas();

  let erroDesconto = '';

  if (
    tipo === TIPO_COMPRA_MERCADORIAS_REVENDA ||
    tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
  ) {
    if (Math.abs(totalDescontoItens - totalDescontoParcelas) > 0.01) {
      erroDesconto += `* O total de desconto de boleto deve ser igual nos itens(${formatMoeda(
        totalDescontoItens,
      )}) e nas parcelas(${formatMoeda(totalDescontoParcelas)})`;
    }
  }

  function handleActions(action) {
    switch (action) {
      case VOLTAR_ACTION:
        handleClose();
        break;
      case FINALIZAR_ACTION:
        if (erroDesconto === '') {
          if (podeFecharCompra) {
            openConfirmacao();
          }
        } else {
          enqueueSnackbar(erroDesconto, {
            variant: 'error',
          });
        }
        break;
      case NOVO_FORNECEDOR_ACTION:
        // if (refDialogoFornecedor.current) refDialogoFornecedor.current.handleOpen(0);
        if (refDialogoFornecedor.current)
          refDialogoFornecedor.current.handleCreate();
        break;
      case DELETAR_PARCELA_ACTION:
        if (parcelas.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case EDITAR_FORNECEDOR_ACTION:
        // if (refDialogoFornecedor.current) refDialogoFornecedor.current.handleOpen(1);
        if (refDialogoFornecedor.current) {
          const idFornecedor = fornecedor ? fornecedor.id : 0;
          if (idFornecedor > 0) {
            refDialogoFornecedor.current.handleEdit(idFornecedor, 0);
          }
        }
        break;
      case IMPRIMIR_ACTION:
        handleOpenNotaLocal();
        break;
      case ADICIONAR_LOTE_ACTION:
        if (tipo !== TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA) {
          if (refSidebarInputs.current) {
            refSidebarInputs.current.handleOpen();
          }
        }
        break;
      case DETALHES_ACTION:
        if (refDialogoDetalhes.current) refDialogoDetalhes.current.handleOpen();
        break;
      default:
        break;
    }
  }

  function editarPrazosFornecedor() {
    const idFornecedor = fornecedor ? fornecedor.id : 0;
    if (idFornecedor > 0) {
      refDialogoFornecedor.current.handleEdit(idFornecedor, 4);
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 0:
        handleOpenNota();
        break;
      default:
        break;
    }
  }

  function openConfirmacao() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        idEdit > 0 ? 'Fechamento da edição da compra' : 'Fechamento da compra',
        idEdit > 0
          ? 'Tem certeza que deseja finalizar a edição da compra!'
          : 'Tem certeza que deseja finalizar a compra!',
        0,
      );
    }
  }

  async function updateFornecedor(idUpdate) {
    try {
      const data = await getListAllAPI(
        'fornecedores',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['cidade.estado'],
      );
      if (data.data.length > 0) {
        dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: data.data[0] });
      } else {
        throw 'Este fornecedor não existe!';
      }
    } catch (erros) {
      // nada
    }
  }

  function gerLabelPrazosFornecedor(fornecedor) {
    let prazoLabel = 'Nenhum prazo cadastrado!';
    if (fornecedor) {
      if (fornecedor.prazosFornecedores) {
        if (fornecedor.prazosFornecedores.length > 0) {
          prazoLabel = fornecedor.prazosFornecedores
            .sort((a, b) => a.dias - b.dias)
            .map((item) => item.dias)
            .join(', ');
        }
      }
    }
    return prazoLabel;
  }

  const prazoLabel = gerLabelPrazosFornecedor(fornecedor);

  function getData(data) {
    return moment(data).format('YYYY-MM-DD');
  }

  function handleEditar(dados2, index) {
    if (tipo !== TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA) {
      if (refSidebarInputs.current) {
        refSidebarInputs.current.handleOpenEditar(
          getData(dados2.dadosOriginais.data_pagamento),
          dados2.dadosOriginais.valor,
          dados2.dadosOriginais.desconto,
          index,
          dados2.dadosOriginais.tarifa_boleto,
          //
          //
          dados2.dadosOriginais.conta, //
          dados2.dadosOriginais.status, //
          dados2.dadosOriginais.cartao, //
          dados2.dadosOriginais.isCartao, //
          dados2.dadosOriginais.fatura, //
          dados2.dadosOriginais.codigo_boleto, //
          dados2.dadosOriginais.problema, //
          dados2.dadosOriginais.descricao_problema, //
        );
      }
    }
  }

  return (
    <>
      <AppBar
        disabledBack={false}
        disabledForward={
          (resto > 10 || resto < -10) &&
          tipo !== TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
        }
        disabledDeletarParcela={!(parcelas.length > 0)}
        onClick={handleActions}
        VOLTAR_ACTION={VOLTAR_ACTION}
        FINALIZAR_ACTION={FINALIZAR_ACTION}
        DELETAR_PARCELA_ACTION={DELETAR_PARCELA_ACTION}
        ADICIONAR_LOTE_ACTION={ADICIONAR_LOTE_ACTION}
        idEdit={idEdit}
        subtotal={compra.subTotal}
        resto={resto}
        totalParcelas={totalParcelas}
        DETALHES_ACTION={DETALHES_ACTION}
        numeroCompra={numeroCompra}
        disabledAdicionarParcela={
          tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
        }
      />
      <AppBarNaturezaCompra
        tipo={tipo}
        subTotalPresumido={0}
        handleShowValoresReais={() => {}}
        showValoresReais={false}
        visibleInput={false}
        visibleSubTotalPresumido={
          tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
        }
      />
      <Box padding="10px" className={classes.header}>
        {/*<Box
          display="flex"
          css={{
            backgroundColor: 'white',
            opacity: '0.75',
            height: '100%',
            padding: '0px 10px',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" alignItems="center" flex={1}>
            <AutoCompleteRemoto
              id="fornecedor"
              name="fornecedor"
              label="Responsável"
              value={fornecedor}
              onChange={(value) =>
                dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: value })
              }
              handleKey={(keyCode, keyName) => {
                handleActions(keyName);
              }}
              handleEnter={() => {
                if (refNotaFiscal.current) {
                  refNotaFiscal.current.focus();
                  refNotaFiscal.current.select();
                }
              }}
              resource="fornecedores"
              nested={['cidade.estado', 'prazosFornecedores']}
              toString={false}
              style={{ width: '200px' }}
              fullwidth={false}
              autoFocus
              filters={{ status: STATUS_ATIVO }}
              ref={refFornecedor}
              getOptionSelected={(option, value) => option.nome === value.nome}
              getOptionLabel={(option) =>
                `${option.nome}${
                  option.nome_fantasia ? ` - (${option.nome_fantasia})` : ''
                }`
              }
            />
            <Box marginLeft="10px">
              <AutoCompleteRemoto
                id="fornecedor"
                name="fornecedor"
                label="Nome Fantasia"
                value={fornecedor}
                onChange={(value) =>
                  dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: value })
                }
                handleKey={(keyCode, keyName) => {
                  handleActions(keyName);
                }}
                handleEnter={() => {
                  if (refNotaFiscal.current) {
                    refNotaFiscal.current.focus();
                    refNotaFiscal.current.select();
                  }
                }}
                resource="fornecedores"
                campo="nome_fantasia"
                nested={['cidade.estado', 'prazosFornecedores']}
                toString={false}
                style={{ width: '200px' }}
                fullwidth={false}
                filters={{ status: STATUS_ATIVO }}
                getOptionSelected={(option, value) =>
                  option.nome_fantasia === value.nome_fantasia
                }
                getOptionLabel={(option) =>
                  `${option.nome_fantasia ? `(${option.nome_fantasia})` : ''}`
                }
              />
            </Box>
            <Box marginLeft="10px">
              <ContainerNumeroNotaFiscalInput
                ref={refNotaFiscal}
                handleKey={(keyCode, keyName) => {
                  handleActions(keyName);
                }}
                handleEnter={() => {
                  if (refLoja.current) {
                    refLoja.current.focus();
                    refLoja.current.select();
                  }
                }}
              />
            </Box>
            <Box marginLeft="10px">
              <AutoCompleteLocal
                id="loja"
                name="loja"
                label="Loja"
                options={optionsLoja}
                value={loja}
                onChangeLocal={(value) =>
                  dispatch({ type: 'UPDATE_LOJA', loja: value })
                }
                handleKey={(keyCode, keyName) => {
                  handleActions(keyName);
                }}
                handleEnter={() => {
                  if (refSidebarInputs.current)
                    refSidebarInputs.current.focus();
                }}
                style={{ width: '200px' }}
                fullwidth={false}
                ref={refLoja}
              />
            </Box>
            {fornecedor && (
              <Box display="flex" justifyContent="space-between" flex={1}>
                <Box
                  marginLeft="10px"
                  display="flex"
                  flexDirection="column"
                  flex={1}
                >
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      style={{
                        marginBottom: '0px',
                        color: 'red',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      Razão Social:
                    </p>
                    {fornecedor.razao_social}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      style={{
                        marginBottom: '0px',
                        color: 'red',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      CPF:
                    </p>
                    {formatCPF(fornecedor.cpf)}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      style={{
                        marginBottom: '0px',
                        color: 'red',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      CNPJ:
                    </p>
                    {formatCNPJ(fornecedor.cnpj)}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      style={{
                        marginBottom: '0px',
                        color: 'red',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      Prazos:
                    </p>
                    {prazoLabel}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>*/}
      </Box>
      {erroDesconto ? (
        <div
          style={{
            color: 'red',
            margin: '10px',
          }}
        >
          {erroDesconto}
        </div>
      ) : null}
      <Box
        display="flex"
        justifyContent="space-between"
        //padding="10px"
        className={classes.body}
      >
        {/*<Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <div style={{ marginBottom: '15px' }}>
            <Actions
              opcoes={[
                {
                  text: `Voltar (${VOLTAR_ACTION})`,
                  value: VOLTAR_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Finalizar (${FINALIZAR_ACTION})`,
                  value: FINALIZAR_ACTION,
                  hide: false,
                  disabled: resto > 10 || resto < -10,
                  className: resto > 10 || resto < -10 ? '' : 'btnImprimir',
                },
                {
                  text: `Novo Forn. (${NOVO_FORNECEDOR_ACTION})`,
                  value: NOVO_FORNECEDOR_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Editar Forn. (${EDITAR_FORNECEDOR_ACTION})`,
                  value: EDITAR_FORNECEDOR_ACTION,
                  hide: false,
                  disabled:
                    fornecedor === null
                      ? true
                      : fornecedor.id === 1 || fornecedor.id === 2,
                },
                {
                  text: `Del. Parcela (${DELETAR_PARCELA_ACTION})`,
                  value: DELETAR_PARCELA_ACTION,
                  hide: false,
                  disabled: !(parcelas.length > 0),
                },
              ]}
              onClick={handleActions}
            />
          </div>
          <LabelSubtotal valor={compra.subTotal} isOpaco />
          <LabelTotalParcelas total={totalParcelas} totalRestante={resto} />
            </Box>*/}

        {tipo !== TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA ? (
          <Box padding="0 10px" flex={4}>
            <Tabela
              rows={toStringParcelaCompra(parcelas, fornecedor, data_criacao)}
              handleEditar={handleEditar}
              headCells={[
                {
                  field: 'numeroParcela',
                  label: 'Cód.',
                },
                {
                  field: 'data_pagamento',
                  label: 'Data Original',
                  right: true,
                },
                {
                  field: 'data_provavel',
                  label: 'Data Provável',
                  right: true,
                },
                {
                  field: 'diferenca_dias',
                  label: 'Diferença Provável Original',
                  right: true,
                },
                {
                  field: 'conta',
                  label: 'Conta/Cartão',
                },
                {
                  field: 'fatura',
                  label: 'Fatura',
                  right: true,
                },
                {
                  field: 'valor',
                  label: 'Valor',
                  right: true,
                },
                {
                  field: 'valor_liquido',
                  label: 'Valor Líquido',
                  right: true,
                },
                {
                  field: 'status',
                  label: 'Status',
                  right: true,
                },
                {
                  field: 'codigo_boleto',
                  label: 'Boleto',
                  right: true,
                },
                {
                  field: 'problema',
                  label: 'Problema',
                  right: true,
                },
                {
                  field: 'agendamento',
                  label: 'Agendamento',
                  right: true,
                },
                {
                  field: 'desconto',
                  label: 'Desconto',
                  right: true,
                },
              ]}
              tipo={tipo}
            />
          </Box>
        ) : null}
        {/*<Box flex={1.5} display="flex" flexDirection="column">
          <FormParcelaCompra
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            ref={refSidebarInputs}
            handleNewItem={handleNewItem}
            resto={resto}
          />
          </Box>*/}
      </Box>
      <DialogoNota
        logo={logo}
        ref={refDialogoNota}
        handleClose={closeDialogoNota}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoFornecedor
        // logo={logo}
        ref={refDialogoFornecedor}
        /* handleCriarCliente={(clienteAtualizado) => {
          dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: clienteAtualizado });
        }}
        handleCloseDialogo={() => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
        }}
        cliente={fornecedor}
        isCliente={false} */
        handleClose={(idUpdate) => {
          //if (refSidebarInputs.current) refSidebarInputs.current.focus();
          if (idUpdate > 0) {
            updateFornecedor(idUpdate);
          }
        }}
      />
      {/* <DialogoDeletarItemTabela
        ref={refDialogoDeletar}
        itens={parcelas}
        labelResource="Parcelas"
        handleDelete={(indices) => removeItens(indices)}
        headerDialogo={(
          <div style={{
            display: 'flex', justifyContent: 'space-between', paddingLeft: '56px', marginBottom: '20px', color: 'red',
          }}
          >
            <div style={{ flex: 4 }}>
              Conta / Cartão
            </div>
            <div style={{ flex: 4 }}>
              Fatura
            </div>
            <div style={{ flex: 1 }}>
              valor
            </div>
            <div style={{ flex: 1 }}>
              Data de pagamento
            </div>
            <div style={{ flex: 1 }}>
              Status
            </div>
          </div>
          )}
        getListItemContent={(value, labelId) => (
          <ListItemText
            id={labelId}
            primary={(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 4 }}>
                  {value.isCartao ? value.cartao.nome : value.conta.nome}
                </div>
                <div style={{ flex: 1 }}>
                  {value.isCartao ? value.fatura.nome : '-'}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.valor)}
                </div>
                <div style={{ flex: 1 }}>
                  {moment(value.data_pagamento).format('DD/MM/YYYY')}
                </div>
                <div style={{ flex: 1 }}>
                  {value.isCartao ? '-' : (value.status ? 'Pago' : 'Não Pago')}
                </div>
              </div>
            )}
          />
        )}
      /> */}
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={parcelas}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          const parcelaRemove = parcelas[numeroItem - 1];
          if (
            parcelaRemove.conta.id === 1 &&
            parcelaRemove.status &&
            editarModo
          ) {
            ///
          } else {
            removeItens([parcelaRemove.uidd]);
          }
        }}
        title="Autorização para remover parcela!"
        usarSenha={false}
      />
      <DialogoParcelaCompra
        ref={refSidebarInputs}
        handleNewItem={(dados, index) => {
          if (index === -1) {
            handleNewItem(dados);
          } else {
            const {
              valor,
              conta,
              data_pagamento,
              status,
              cartao,
              isCartao,
              fatura,
              codigo_boleto,
              problema,
              descricao_problema,
              agendamento_user_id,
              desconto,
              tarifa_boleto,
            } = dados;

            atualizarParcela({
              data_pagamento,
              conta,
              valor,
              idIndicadorChange: -1,
              status: isCartao ? false : conta.id === 1 ? false : status,
              cartao,
              isCartao,
              fatura,
              codigo_boleto,
              problema,
              descricao_problema,
              agendamento_user_id,
              desconto,
              tarifa_boleto,
              index,
            });
          }
        }}
        resto={resto}
      />
      <DialogoDetalhes
        ref={refDialogoDetalhes}
        subTotalProdutos={subTotalProdutos}
        //total_repasse_frete={total_repasse_frete}
        //total_repasse_ipi={total_repasse_ipi}
        //total_repasse_outras_despesas={total_repasse_outras_despesas}
        //total_repasse_seguros={total_repasse_seguros}
        //total_repasse_st={total_repasse_st}
        repasse_frete={repasse_frete}
        repasse_ipi={repasse_ipi}
        repasse_outras_despesas={repasse_outras_despesas}
        repasse_seguros={repasse_seguros}
        repasse_st={repasse_st}
        subTotal={compra.subTotal}
        lotes={lotes}
      />
    </>
  );
};

export default FinalizarCompra;
