import React, { forwardRef, useState, useImperativeHandle } from 'react';

import { Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { IntegerInput } from '../../../../Inputs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Grafico = forwardRef(({ handleClose, handleSalvar }, ref) => {
  const [open, setOpen] = useState(false);
  const [numeroCompra, setNumeroCompra] = useState(0);

  const classes = useStyles({ cor: '#009FD4' });

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setNumeroCompra(0);
    },
  }));

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSalvarLocal = (numeroCompra) => {
    handleSalvar(numeroCompra);
    handleCloseDialog();
  };

  function validateNumeroCompra() {
    let error = '';
    if (numeroCompra < 0 || Number.isNaN(numeroCompra)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const erros = [''];
    erros[0] = validateNumeroCompra();

    return erros;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }

    return false;
  }

  const erroExistente = hasError();

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.rootDialogo }}
      className={classes.rootDialogo}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleCloseDialog}
        style={{ backgroundColor: '#009FD4', color: 'white' }}
      >
        Usar Bonificação
      </DialogTitle>
      <DialogContent>
        <IntegerInput
          name="numero-compra"
          handleEnter={() => {}}
          label="Número da Bonificação"
          handleKey={() => {}}
          value={Number.isNaN(numeroCompra) ? '' : String(numeroCompra)}
          onChange={(value) =>
            setNumeroCompra(parseInt(value.target.value, 10))
          }
          error={erros[0] !== ''}
          helperText={erros[0]}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
        <Button
          onClick={() => handleSalvarLocal(numeroCompra)}
          color="primary"
          disabled={erroExistente}
        >
          Importar
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default Grafico;
