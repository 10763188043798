import React from 'react';

import {
  withStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import DetailsIcon from '@material-ui/icons/Details';
import EditIcon from '@material-ui/icons/Edit';

import {
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
} from '../../../../../../utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
  root: {
    borderBottom: 'none',
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
  root: {
    borderBottom: '2px solid black',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
}));

export default function Tabela({
  headCells,
  rows,
  handleGrafico = () => {},
  handleDetalhes = () => {},
  handleEditar = () => {},
  tipo,
}) {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      style={{
        opacity: '0.75',
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <StyledTableCell align={headCell.right ? 'right' : 'inherit'}>
                {headCell.label}
              </StyledTableCell>
            ))}
            <StyledTableCell align="right">Ações</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <>
              <StyledTableRow key={row.uidd}>
                {headCells.map((column) => (
                  <>
                    {column.right ? (
                      <StyledTableCell align="right">
                        {row[column.field]}
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell component="th" scope="row">
                        {row[column.field]}
                      </StyledTableCell>
                    )}
                  </>
                ))}
                <StyledTableCell align="right">
                  <Tooltip title="Detalhes">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDetalhes(row)}
                      size="small"
                    >
                      <DetailsIcon />
                    </IconButton>
                  </Tooltip>
                  {tipo === TIPO_COMPRA_MERCADORIAS_REVENDA ? (
                    <Tooltip title="Gráfico de pizza">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleGrafico(row)}
                        size="small"
                      >
                        <DataUsageIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {tipo === TIPO_COMPRA_MERCADORIAS_REVENDA ||
                  tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA ? (
                    <Tooltip title="Editar">
                      <IconButton
                        aria-label="editar"
                        onClick={() => handleEditar(row, row.uidd)}
                        size="small"
                        //disabled={row.idIndicadorChange !== -1}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key={`${row.uidd}lalsasalksla`}>
                <StyledTableCell2
                  component="th"
                  scope="row"
                  colSpan={10}
                  style={{ fontWeight: 'bold', fontSize: '15px' }}
                >
                  {row.produto}
                </StyledTableCell2>
              </StyledTableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
